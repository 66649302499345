// MDM Breakdown Configuration
export const mdmBreakdown = {
    problems: {
        name: 'Complexity of Problems Addressed',
        levels: [
            { name: 'Minimal', value: 1, description: 'Self-limited or minor' },
            { name: 'Low', value: 2, description: 'Stable, uncomplicated' },
            { name: 'Moderate', value: 3, description: 'Multiple problems or unstable chronic illness' },
            { name: 'High', value: 4, description: 'Severe exacerbation or severe illness' }
        ],
        trends: {
            overall: 4.1,
            change: 0.2,
            distribution: {
                minimal: 5,
                low: 15,
                moderate: 45,
                high: 35
            }
        }
    },
    data: {
        name: 'Complexity of Data',
        levels: [
            { name: 'Minimal/None', value: 1, description: 'Minimal or no data reviewed' },
            { name: 'Limited', value: 2, description: 'Limited data review' },
            { name: 'Moderate', value: 3, description: 'Moderate complexity review' },
            { name: 'Extensive', value: 4, description: 'Extensive data review' }
        ],
        trends: {
            overall: 4.2,
            change: 0.1,
            distribution: {
                minimal: 5,
                limited: 10,
                moderate: 45,
                extensive: 40
            }
        }
    },
    risk: {
        name: 'Risk of Complications',
        levels: [
            { name: 'Minimal', value: 1, description: 'Minimal risk of morbidity' },
            { name: 'Low', value: 2, description: 'Low risk of morbidity' },
            { name: 'Moderate', value: 3, description: 'Moderate risk of morbidity' },
            { name: 'High', value: 4, description: 'High risk of morbidity' }
        ],
        trends: {
            overall: 4.0,
            change: 0.3,
            distribution: {
                minimal: 5,
                low: 15,
                moderate: 45,
                high: 35
            }
        }
    }
};

export const mdmColors = {
    minimal: '#94A3B8',    // Slate 400
    low: '#60A5FA',       // Blue 400
    moderate: '#2563EB',  // Blue 600
    high: '#1E40AF',      // Blue 800
    extensive: '#1E40AF'  // Blue 800 (same as high)
};

// E/M Code configurations
export const emCodes = {
    '99281': 'Self-limited/minor problem',
    '99282': 'Low to moderate severity',
    '99283': 'Moderate severity',
    '99284': 'High severity',
    '99285': 'High severity with urgent evaluation',
    '99291': 'Critical care, first 30-74 minutes',
    '99292': 'Critical care, each additional 30 minutes',
};

export const emCodeColors = {
    '99281': '#4299E1', // Lighter blue
    '99282': '#3182CE',
    '99283': '#2B6CB0',
    '99284': '#2C5282',
    '99285': '#1A365D', // Darker blue
    '99291': '#E53E3E', // Critical care - red
    '99292': '#C53030',
};

// MDM Metrics Configuration
export const mdmMetrics = {
    // Data Review Criteria
    historian: { name: 'Historian Review', target: 35 },
    labs: { name: 'Labs Review', target: 45 },
    imaging: { name: 'Imaging Review', target: 40 },
    interpretation: { name: 'Test Interpretation', target: 50 },
    consults: { name: 'Consults Review', target: 35 },
    sdoh: { name: 'SDOH Documentation', target: 60 },

    // Problem Complexity Criteria
    selfLimited: { name: 'Self-Limited Problems', target: 35 },
    lowRisk: { name: 'Low Risk Problems', target: 40 },
    moderateRisk: { name: 'Moderate Risk Problems', target: 45 },
    highRisk: { name: 'High Risk Problems', target: 50 },
    severeRisk: { name: 'Severe Risk Problems', target: 55 },

    // Risk Complexity Criteria
    minimalRisk: { name: 'Minimal Risk', target: 35 },
    criticalRisk: { name: 'Critical Risk', target: 55 },
}; 