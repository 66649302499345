import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Chip,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
} from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import { getPerformanceColor, generateRealisticCompliance, COMPLIANCE_COLORS } from '../utils/facilityData';

// Diagnostic measures configuration
const diagnosticCategories = {
    'Cardiac': {
        name: 'Cardiac Diagnostics',
        measures: [
            {
                id: 'ekg_chest_pain',
                name: 'EKG for Chest Pain',
                description: 'EKG ordered within 10 minutes of arrival for chest pain patients',
                target: 95,
                timeRequirement: '10 minutes'
            }
        ]
    },
    'Imaging': {
        name: 'Imaging Studies',
        measures: [
            {
                id: 'cxr_sob',
                name: 'Chest X-ray for SOB/Pneumonia',
                description: 'Chest X-ray ordered for patients with shortness of breath or suspected pneumonia',
                target: 90,
                timeRequirement: '30 minutes'
            },
            {
                id: 'ct_head',
                name: 'CT Head for Severe Headache/AMS',
                description: 'CT Head ordered for patients with severe headache or altered mental status',
                target: 90,
                timeRequirement: '15 minutes'
            }
        ]
    },
    'Point of Care': {
        name: 'Point of Care Testing',
        measures: [
            {
                id: 'pregnancy_test',
                name: 'Pregnancy Test for Abd Pain',
                description: 'Pregnancy test ordered for female patients of childbearing age with abdominal pain',
                target: 92,
                timeRequirement: 'On arrival'
            },
            {
                id: 'fast_exam',
                name: 'FAST Exam in Trauma',
                description: 'FAST exam performed for trauma patients meeting criteria',
                target: 95,
                timeRequirement: 'Within trauma assessment'
            }
        ]
    }
};

const DiagnosticsDashboard = () => {
    const [selectedCategory, setSelectedCategory] = useState('Cardiac');
    const [selectedFacility, setSelectedFacility] = useState('All Facilities');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [selectedMetricView, setSelectedMetricView] = useState('usage'); // 'usage' or 'trends'

    // Mock data generators
    const generateUsageData = (target) => {
        return Math.floor(Math.random() * (target + 5 - (target - 10)) + (target - 10));
    };

    const generateTrendData = () => {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map(month => ({
            month,
            usage: Math.floor(Math.random() * (100 - 70) + 70),
            timeTarget: Math.floor(Math.random() * (100 - 70) + 70),
        }));
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Diagnostic Measures Dashboard
            </Typography>

            {/* Filters */}
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                label="Category"
                            >
                                {Object.keys(diagnosticCategories).map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {diagnosticCategories[category].name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Facility</InputLabel>
                            <Select
                                value={selectedFacility}
                                onChange={(e) => setSelectedFacility(e.target.value)}
                                label="Facility"
                            >
                                <MenuItem value="All Facilities">All Facilities</MenuItem>
                                <MenuItem value="Main ED">Main ED</MenuItem>
                                <MenuItem value="Pediatric ED">Pediatric ED</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>Time Period</InputLabel>
                            <Select
                                value={selectedTimePeriod}
                                onChange={(e) => setSelectedTimePeriod(e.target.value)}
                                label="Time Period"
                            >
                                <MenuItem value="last30">Last 30 Days</MenuItem>
                                <MenuItem value="last90">Last 90 Days</MenuItem>
                                <MenuItem value="lastYear">Last Year</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>

            {/* View Toggle */}
            <Tabs
                value={selectedMetricView}
                onChange={(e, newValue) => setSelectedMetricView(newValue)}
                sx={{ mb: 3 }}
            >
                <Tab value="usage" label="Usage Compliance" />
                <Tab value="trends" label="Time Trends" />
            </Tabs>

            {selectedMetricView === 'usage' ? (
                // Usage Compliance View
                <Grid container spacing={3}>
                    {diagnosticCategories[selectedCategory].measures.map((measure) => {
                        const usage = generateRealisticCompliance();
                        const timeTargetMet = generateRealisticCompliance();

                        return (
                            <Grid item xs={12} md={6} key={measure.id}>
                                <Card sx={{ p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">
                                            {measure.name}
                                            <Tooltip title={measure.description}>
                                                <IconButton size="small">
                                                    <InfoIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Usage Compliance
                                            </Typography>
                                            <Typography variant="h3"
                                                sx={{
                                                    color: getPerformanceColor(usage)
                                                }}
                                            >
                                                {usage}%
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Target: {measure.target}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Time Target Met
                                            </Typography>
                                            <Typography variant="h3"
                                                sx={{
                                                    color: getPerformanceColor(timeTargetMet)
                                                }}
                                            >
                                                {timeTargetMet}%
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Target: {measure.timeRequirement}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                // Trends View
                <Grid container spacing={3}>
                    {diagnosticCategories[selectedCategory].measures.map((measure) => (
                        <Grid item xs={12} key={measure.id}>
                            <Card sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    {measure.name} - Performance Trends
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={generateTrendData()}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis domain={[0, 100]} />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey="usage"
                                            stroke={COMPLIANCE_COLORS.success.hex}
                                            name="Usage Compliance"
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="timeTarget"
                                            stroke={COMPLIANCE_COLORS.warning.hex}
                                            name="Time Target Met"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default DiagnosticsDashboard; 