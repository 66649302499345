import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { templatesApi } from '../api/templatesApi';
import { PlusIcon, TrashIcon, PencilIcon, DocumentTextIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

export default function TemplatesSettings({ onSuccessfulSave }) {
    const { instance, accounts } = useMsal();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [allTemplates, setAllTemplates] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [customTemplates, setCustomTemplates] = useState([]);

    // Form states
    const [templateName, setTemplateName] = useState('');
    const [promptTemplate, setPromptTemplate] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        setIsLoading(true);
        try {
            const response = await templatesApi.getTemplates(instance, accounts);
            const templatesArray = Object.entries(response.templates)
                .map(([id, data]) => ({
                    template_id: parseInt(id),
                    ...data
                }));
            setAllTemplates(templatesArray);
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const filtered = allTemplates.filter(t =>
            t.template_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            t.description?.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setDefaultTemplates(filtered.filter(t => t.who_can_view === 'GLOBAL'));
        setCustomTemplates(filtered.filter(t => t.who_can_view === 'ACTOR'));
    }, [searchQuery, allTemplates]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const templateData = {
                template_id: editingTemplate?.template_id,
                template_name: templateName,
                prompt_template: promptTemplate,
                description: description,
                who_can_view: editingTemplate?.who_can_view || "ACTOR"
            };

            await templatesApi.upsertTemplate(instance, accounts, templateData);
            await fetchTemplates();
            closeModal();
            onSuccessfulSave('Template saved successfully');
        } catch (error) {
            console.error('Error saving template:', error);
        }
    };

    const handleDelete = async (templateId) => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            try {
                await templatesApi.deleteTemplate(instance, accounts, templateId);
                await fetchTemplates();
                onSuccessfulSave('Template deleted successfully');
            } catch (error) {
                console.error('Error deleting template:', error);
            }
        }
    };

    const openModal = (template = null) => {
        if (template) {
            setEditingTemplate(template);
            setTemplateName(template.template_name);
            setPromptTemplate(template.prompt_template);
            setDescription(template.description || '');
        } else {
            setEditingTemplate(null);
            setTemplateName('');
            setPromptTemplate('');
            setDescription('');
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingTemplate(null);
        setTemplateName('');
        setPromptTemplate('');
        setDescription('');
    };

    const TemplateList = ({ templates, title, emptyMessage }) => (
        <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
            {templates.length === 0 ? (
                <div className="text-center py-6 bg-gray-50 rounded-lg">
                    <DocumentDuplicateIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No templates</h3>
                    <p className="mt-1 text-sm text-gray-500">{emptyMessage}</p>
                </div>
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {templates.map((template) => (
                        <div
                            key={template.template_id}
                            className="relative flex flex-col bg-white p-4 rounded-lg shadow-sm ring-1 ring-gray-900/5 hover:shadow-md transition-shadow"
                        >
                            <div className="flex items-center gap-x-2 mb-3">
                                <DocumentTextIcon className="h-5 w-5 text-indigo-600 flex-shrink-0" />
                                <h3 className="text-sm font-semibold leading-5 text-gray-900 truncate">
                                    {template.template_name}
                                </h3>
                            </div>

                            <div className="mt-auto pt-3 flex flex-col gap-2">
                                {template.who_can_view === 'ACTOR' ? (
                                    <>
                                        <button
                                            onClick={() => openModal(template)}
                                            className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <PencilIcon className="h-4 w-4 mr-1" />
                                            Edit/View
                                        </button>
                                        <button
                                            onClick={() => handleDelete(template.template_id)}
                                            className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <TrashIcon className="h-4 w-4 mr-1" />
                                            Delete
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => openModal(template)}
                                        className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <DocumentTextIcon className="h-4 w-4 mr-1" />
                                        View
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900">Document Templates</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Create and manage templates for generating supplemental documents from your notes.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => openModal()}
                            className="block rounded-md bg-indigo-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-800"
                        >
                            Add Template
                        </button>
                    </div>
                </div>

                <div className="mt-8">
                    <input
                        type="text"
                        placeholder="Search templates..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-6"
                    />

                    <div className="space-y-8">
                        <TemplateList
                            templates={customTemplates}
                            title="Custom Templates"
                            emptyMessage="Start by creating your first custom template"
                        />
                        <TemplateList
                            templates={defaultTemplates}
                            title="Default Templates"
                            emptyMessage="No default templates available"
                        />
                    </div>
                </div>
            </div>

            <Dialog open={isModalOpen} onClose={closeModal} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-4xl w-full rounded-xl bg-white p-6">
                        <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                            {editingTemplate ? 'Edit Template' : 'Create New Template'}
                        </Dialog.Title>

                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Template Name
                                </label>
                                <input
                                    type="text"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    readOnly={editingTemplate?.who_can_view === 'GLOBAL'}
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Prompt Template
                                </label>
                                <textarea
                                    value={promptTemplate}
                                    onChange={(e) => setPromptTemplate(e.target.value)}
                                    rows={15}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm font-mono"
                                    required
                                    readOnly={editingTemplate?.who_can_view === 'GLOBAL'}
                                    placeholder="Enter your prompt template here..."
                                />
                            </div>

                            <div className="mt-6 flex justify-end gap-x-3">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    {editingTemplate?.who_can_view === 'GLOBAL' ? 'Close' : 'Cancel'}
                                </button>
                                {editingTemplate?.who_can_view !== 'GLOBAL' && (
                                    <button
                                        type="submit"
                                        className="rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800"
                                    >
                                        {editingTemplate ? 'Save Changes' : 'Create Template'}
                                    </button>
                                )}
                            </div>
                        </form>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    );
} 