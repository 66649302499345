import React, { useState, useEffect } from 'react';
import Map, { Source, Layer, Marker, Popup, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
    Box,
    Card,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Chip,
    IconButton,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    ListSubheader
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PublicIcon from '@mui/icons-material/Public';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import RoomIcon from '@mui/icons-material/Room';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from '@mui/icons-material/Error';
import { generateRealisticCompliance, getPerformanceColor } from '../utils/facilityData';

const MAPBOX_TOKEN = 'pk.eyJ1IjoianB1bGxtYW45IiwiYSI6ImNtNXJjdXI0ZzA5MnAyam9hYWl6MGExN3kifQ.CZfafuoaIxmovruwJc1wfg';

// Hierarchical mock data
const organizationData = {
    regions: [
        {
            id: 'west',
            name: 'West Region',
            coordinates: { lng: -96.7970, lat: 32.7767 },
            heartScoreCompliance: generateRealisticCompliance(),
            divisions: [
                {
                    id: 'west-dallas',
                    name: 'Dallas Division',
                    coordinates: { lng: -96.7970, lat: 32.7767 },
                    heartScoreCompliance: generateRealisticCompliance(),
                    facilities: [
                        {
                            id: 'dallas-ed',
                            name: "Dallas ED",
                            coordinates: { lng: -96.7970, lat: 32.7767 },
                            heartScoreCompliance: generateRealisticCompliance(),
                            providers: [
                                { name: "Dr. Smith", compliance: generateRealisticCompliance(), cases: 120 },
                                { name: "Dr. Johnson", compliance: generateRealisticCompliance(), cases: 85 },
                            ]
                        },
                        {
                            id: 'plano-ed',
                            name: "Plano ED",
                            coordinates: { lng: -96.6899, lat: 33.0198 },
                            heartScoreCompliance: generateRealisticCompliance(),
                            providers: [
                                { name: "Dr. Wilson", compliance: generateRealisticCompliance(), cases: 110 },
                                { name: "Dr. Brown", compliance: generateRealisticCompliance(), cases: 95 },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: 'northeast',
            name: 'Northeast Region',
            coordinates: { lng: -71.4128, lat: 41.8240 },
            heartScoreCompliance: generateRealisticCompliance(),
            divisions: [
                {
                    id: 'ne-providence',
                    name: 'Providence Division',
                    coordinates: { lng: -71.4128, lat: 41.8240 },
                    heartScoreCompliance: generateRealisticCompliance(),
                    facilities: [
                        {
                            id: 'providence-ed',
                            name: "Providence ED",
                            coordinates: { lng: -71.4128, lat: 41.8240 },
                            heartScoreCompliance: generateRealisticCompliance(),
                            providers: [
                                { name: "Dr. Davis", compliance: generateRealisticCompliance(), cases: 130 },
                                { name: "Dr. Miller", compliance: generateRealisticCompliance(), cases: 95 },
                            ]
                        },
                        {
                            id: 'newhaven-ed',
                            name: "New Haven ED",
                            coordinates: { lng: -72.9279, lat: 41.3083 },
                            heartScoreCompliance: generateRealisticCompliance(),
                            providers: [
                                { name: "Dr. Taylor", compliance: generateRealisticCompliance(), cases: 105 },
                                { name: "Dr. Anderson", compliance: generateRealisticCompliance(), cases: 90 },
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

// Define measure categories and their measures
const measureTypes = {
    'Risk Scores': {
        'HEART Score': { description: 'Risk stratification for chest pain patients', target: 85 },
        'NIHSS': { description: 'NIH Stroke Scale for stroke severity', target: 90 },
        'PERC Rule': { description: 'PE Rule-out Criteria', target: 80 },
        'PECARN': { description: 'Pediatric Head Injury/Trauma Algorithm', target: 95 },
        'Wells Score': { description: 'DVT/PE probability assessment', target: 85 },
    },
    'MIPS Measures': {
        'Antibiotic Stewardship': {
            measures: [
                'Appropriate Testing for Pharyngitis',
                'AOE: Antimicrobial Therapy Avoidance',
                'Bronchitis Antibiotic Avoidance',
            ],
            target: 85,
        },
        'Emergency Care': {
            measures: [
                'Stroke: Thrombolytic Therapy',
                'Pregnancy Ultrasound for Pain',
                'CT Usage for Head Trauma',
            ],
            target: 90,
        },
        'Screening & Prevention': {
            measures: [
                'MDD: Suicide Risk Assessment',
                'Blood Pressure Screening',
            ],
            target: 88,
        },
    },
    'Diagnostics': {
        'Cardiac': {
            measures: [
                'EKG for Chest Pain',
            ],
            description: 'EKG ordered within 10 minutes of arrival for chest pain patients',
            target: 95,
        },
        'Imaging': {
            measures: [
                'Chest X-ray for SOB/Pneumonia',
                'CT Head for Severe Headache/AMS',
            ],
            description: 'Appropriate imaging studies ordered for specific complaints',
            target: 90,
        },
        'Point of Care': {
            measures: [
                'Pregnancy Test for Abd Pain',
                'FAST Exam in Trauma',
            ],
            description: 'Point of care testing and bedside ultrasound utilization',
            target: 92,
        },
    },
};

const CRITICAL_THRESHOLD = 60; // Define what constitutes "dangerously low"

// Add this function to generate mock critical alerts
const generateCriticalAlerts = () => {
    return [
        {
            id: 1,
            measure: 'EKG for Chest Pain',
            category: 'Diagnostics',
            facilities: [
                { name: 'Houston Medical Center ED', compliance: 45, trend: 'declining' },
                { name: 'West Houston Emergency', compliance: 52, trend: 'stable' },
            ],
            impact: 'High',
            timeFrame: 'Last 30 days'
        },
        {
            id: 2,
            measure: 'HEART Score',
            category: 'Risk Scores',
            facilities: [
                { name: 'Dallas Downtown ED', compliance: 55, trend: 'declining' },
            ],
            impact: 'Medium',
            timeFrame: 'Last 7 days'
        },
        // Add more mock alerts as needed
    ];
};

const GeographicPerformanceMap = () => {
    const [viewState, setViewState] = useState({
        latitude: 31.9686,
        longitude: -99.9018,
        zoom: 4,
    });
    const [selectedLevel, setSelectedLevel] = useState('region');
    const [selectedItem, setSelectedItem] = useState(null);
    const [popupInfo, setPopupInfo] = useState(null);
    const [measureCategory, setMeasureCategory] = useState('Risk Scores');
    const [selectedMeasure, setSelectedMeasure] = useState('HEART Score');
    const [criticalAlerts, setCriticalAlerts] = useState(generateCriticalAlerts());
    const [alertDrawerOpen, setAlertDrawerOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);

    // Function to get all visible points based on selected level
    const getVisiblePoints = () => {
        let points = [];

        switch (selectedLevel) {
            case 'region':
                points = organizationData.regions.map(region => ({
                    ...region,
                    level: 'region'
                }));
                break;
            case 'division':
                organizationData.regions.forEach(region => {
                    points.push(...region.divisions.map(division => ({
                        ...division,
                        level: 'division',
                        parentName: region.name
                    })));
                });
                break;
            case 'facility':
                organizationData.regions.forEach(region => {
                    region.divisions.forEach(division => {
                        points.push(...division.facilities.map(facility => ({
                            ...facility,
                            level: 'facility',
                            parentName: division.name,
                            regionName: region.name
                        })));
                    });
                });
                break;
            default:
                break;
        }

        // Add measure-specific compliance data
        return points.map(point => ({
            ...point,
            compliance: getMeasureCompliance(point, measureCategory, selectedMeasure),
        }));
    };

    // Mock function to generate measure-specific compliance
    const getMeasureCompliance = (point, category, measure) => {
        // In real implementation, this would come from your data
        return Math.floor(Math.random() * (100 - 60) + 60);
    };

    const handleLevelChange = (event, newLevel) => {
        if (newLevel !== null) {
            setSelectedLevel(newLevel);
            setSelectedItem(null);
            setPopupInfo(null);

            // Reset view to show all points
            setViewState({
                latitude: 31.9686,
                longitude: -99.9018,
                zoom: 4,
                transitionDuration: 1000,
            });
        }
    };

    const getLevelIcon = (level) => {
        switch (level) {
            case 'region':
                return <PublicIcon sx={{ mr: 1 }} />;
            case 'division':
                return <AccountTreeIcon sx={{ mr: 1 }} />;
            case 'facility':
                return <BusinessIcon sx={{ mr: 1 }} />;
            default:
                return null;
        }
    };

    // Modify the pin size based on the level
    const getPinSize = (level) => {
        switch (level) {
            case 'region':
                return 40;
            case 'division':
                return 35;
            case 'facility':
                return 30;
            default:
                return 30;
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Stack spacing={2} sx={{ mb: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">
                        Geographic Performance Map
                    </Typography>
                    <ToggleButtonGroup
                        value={selectedLevel}
                        exclusive
                        onChange={handleLevelChange}
                        aria-label="organization level"
                    >
                        <ToggleButton value="region" aria-label="region level">
                            <PublicIcon sx={{ mr: 1 }} />
                            Region
                        </ToggleButton>
                        <ToggleButton value="division" aria-label="division level">
                            <AccountTreeIcon sx={{ mr: 1 }} />
                            Division
                        </ToggleButton>
                        <ToggleButton value="facility" aria-label="facility level">
                            <BusinessIcon sx={{ mr: 1 }} />
                            Facility
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Measure Type</InputLabel>
                            <Select
                                value={measureCategory}
                                onChange={(e) => {
                                    setMeasureCategory(e.target.value);
                                    // Reset selected measure when category changes
                                    if (e.target.value === 'Risk Scores') {
                                        setSelectedMeasure(Object.keys(measureTypes['Risk Scores'])[0]);
                                    } else if (e.target.value === 'MIPS Measures') {
                                        setSelectedMeasure(measureTypes['MIPS Measures']['Antibiotic Stewardship'].measures[0]);
                                    } else {
                                        setSelectedMeasure(measureTypes['Diagnostics']['Cardiac'].measures[0]);
                                    }
                                }}
                                label="Measure Type"
                            >
                                {Object.keys(measureTypes).map((type) => (
                                    <MenuItem key={type} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 250 }}>
                            <InputLabel>Measure</InputLabel>
                            <Select
                                value={selectedMeasure}
                                onChange={(e) => setSelectedMeasure(e.target.value)}
                                label="Measure"
                            >
                                {measureCategory === 'Risk Scores' ? (
                                    Object.keys(measureTypes['Risk Scores']).map((measure) => (
                                        <MenuItem key={measure} value={measure}>
                                            {measure}
                                        </MenuItem>
                                    ))
                                ) : measureCategory === 'MIPS Measures' ? (
                                    Object.entries(measureTypes['MIPS Measures']).map(([category, { measures }]) => [
                                        <ListSubheader key={category}>{category}</ListSubheader>,
                                        ...measures.map((measure) => (
                                            <MenuItem key={measure} value={measure}>
                                                {measure}
                                            </MenuItem>
                                        ))
                                    ]).flat()
                                ) : (
                                    // Handle Diagnostics category
                                    Object.entries(measureTypes['Diagnostics']).map(([category, { measures }]) => [
                                        <ListSubheader key={category}>{category}</ListSubheader>,
                                        ...measures.map((measure) => (
                                            <MenuItem key={measure} value={measure}>
                                                {measure}
                                            </MenuItem>
                                        ))
                                    ]).flat()
                                )}
                            </Select>
                        </FormControl>
                    </Box>

                    <Badge badgeContent={criticalAlerts.length} color="error">
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{
                                cursor: 'pointer',
                                minWidth: '300px',
                                '&:hover': {
                                    opacity: 0.9,
                                },
                            }}
                            icon={<WarningAmberIcon />}
                            onClick={() => setAlertDrawerOpen(true)}
                        >
                            <AlertTitle>Critical Performance Alerts</AlertTitle>
                            {criticalAlerts.length} measures below critical threshold
                        </Alert>
                    </Badge>
                </Stack>
            </Stack>

            <Card sx={{ p: 2, height: '600px' }}>
                <Map
                    {...viewState}
                    onMove={evt => setViewState(evt.viewState)}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    mapStyle="mapbox://styles/mapbox/light-v11"
                    style={{ width: '100%', height: '100%' }}
                >
                    <NavigationControl position="top-right" />

                    {getVisiblePoints().map((point) => (
                        <Marker
                            key={point.id}
                            longitude={point.coordinates.lng}
                            latitude={point.coordinates.lat}
                            anchor="bottom"
                            onClick={e => {
                                e.originalEvent.stopPropagation();
                                setPopupInfo(point);
                            }}
                        >
                            <RoomIcon
                                sx={{
                                    fontSize: getPinSize(point.level),
                                    color: getPerformanceColor(point.heartScoreCompliance, true),
                                    cursor: 'pointer',
                                    '&:hover': {
                                        transform: 'scale(1.2)',
                                    },
                                    transition: 'transform 0.2s',
                                    filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.3))',
                                }}
                            />
                        </Marker>
                    ))}

                    {popupInfo && (
                        <Popup
                            anchor="top"
                            longitude={popupInfo.coordinates.lng}
                            latitude={popupInfo.coordinates.lat}
                            onClose={() => setPopupInfo(null)}
                        >
                            <Paper sx={{ p: 1, maxWidth: 300 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {popupInfo.name}
                                </Typography>
                                {popupInfo.parentName && (
                                    <Typography variant="body2" color="text.secondary">
                                        {popupInfo.regionName && `${popupInfo.regionName} > `}
                                        {popupInfo.parentName}
                                    </Typography>
                                )}
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    {selectedMeasure}: {popupInfo.compliance}%
                                </Typography>
                                {popupInfo.level === 'facility' && (
                                    <Box sx={{ mt: 1 }}>
                                        <Typography
                                            variant="button"
                                            sx={{
                                                cursor: 'pointer',
                                                color: 'primary.main',
                                                '&:hover': { textDecoration: 'underline' }
                                            }}
                                            onClick={() => {
                                                setSelectedItem(popupInfo);
                                                setPopupInfo(null);
                                            }}
                                        >
                                            View Provider Details
                                        </Typography>
                                    </Box>
                                )}
                            </Paper>
                        </Popup>
                    )}
                </Map>
            </Card>

            {/* Facility Detail Dialog */}
            <Dialog
                open={Boolean(selectedItem)}
                onClose={() => setSelectedItem(null)}
                maxWidth="md"
                fullWidth
            >
                {selectedItem && (
                    <>
                        <DialogTitle>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">
                                    {selectedItem.name}
                                </Typography>
                                <IconButton
                                    onClick={() => setSelectedItem(null)}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Box mb={3}>
                                <Typography variant="h4" color={
                                    selectedItem.compliance >= 85 ? "success.main" :
                                        selectedItem.compliance >= 70 ? "warning.main" : "error.main"
                                }>
                                    {selectedItem.compliance}%
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {selectedMeasure} Compliance Rate
                                </Typography>
                            </Box>

                            {selectedItem.providers && (
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        Provider Performance
                                    </Typography>
                                    <List>
                                        {selectedItem.providers
                                            .sort((a, b) => b.compliance - a.compliance)
                                            .map((provider) => (
                                                <ListItem
                                                    key={provider.name}
                                                    divider
                                                    secondaryAction={
                                                        <Chip
                                                            label={`${provider.compliance}%`}
                                                            color={
                                                                provider.compliance >= 85 ? "success" :
                                                                    provider.compliance >= 70 ? "warning" : "error"
                                                            }
                                                        />
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={provider.name}
                                                        secondary={`${provider.cases} cases`}
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                </>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog>

            {/* Critical Alerts System */}
            <Drawer
                anchor="right"
                open={alertDrawerOpen}
                onClose={() => setAlertDrawerOpen(false)}
                PaperProps={{
                    sx: { width: '400px' }
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                        <Typography variant="h6">
                            Critical Performance Alerts
                        </Typography>
                        <IconButton onClick={() => setAlertDrawerOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    <Stack spacing={2}>
                        {criticalAlerts.map((alert) => (
                            <Card
                                key={alert.id}
                                sx={{
                                    p: 2,
                                    border: '1px solid #ff4444',
                                    bgcolor: 'error.main',
                                    color: 'white',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    },
                                }}
                                onClick={() => {
                                    setSelectedAlert(alert);
                                    setAlertDrawerOpen(false);
                                }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <ErrorIcon />
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {alert.measure}
                                        </Typography>
                                        <Typography variant="body2">
                                            {alert.facilities.length} facilities below threshold
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Card>
                        ))}
                    </Stack>
                </Box>
            </Drawer>

            {/* Alert Detail Modal */}
            <Dialog
                open={Boolean(selectedAlert)}
                onClose={() => setSelectedAlert(null)}
                maxWidth="md"
                fullWidth
            >
                {selectedAlert && (
                    <>
                        <DialogTitle>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">
                                    Critical Alert: {selectedAlert.measure}
                                </Typography>
                                <IconButton
                                    onClick={() => setSelectedAlert(null)}
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" color="error" gutterBottom>
                                    Category: {selectedAlert.category}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Time Frame: {selectedAlert.timeFrame}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Impact Level: {selectedAlert.impact}
                                </Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom>
                                Affected Facilities
                            </Typography>
                            <List>
                                {selectedAlert.facilities.map((facility) => (
                                    <ListItem
                                        key={facility.name}
                                        divider
                                        secondaryAction={
                                            <Chip
                                                label={`${facility.compliance}%`}
                                                color="error"
                                                size="small"
                                            />
                                        }
                                    >
                                        <ListItemText
                                            primary={facility.name}
                                            secondary={`Trend: ${facility.trend}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>

                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Recommended Actions
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Review documentation procedures with affected facilities" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Schedule performance review meetings" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Implement additional training sessions" />
                                    </ListItem>
                                </List>
                            </Box>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </Box>
    );
};

export default GeographicPerformanceMap; 