import React, { useState } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Paper,
} from '@mui/material';
import MIPSDashboard from './MIPSDashboard';
import RiskDashboard from './RiskDashboard';
import DiagnosticsDashboard from './DiagnosticsDashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import GeographicPerformanceMap from './GeographicPerformanceMap';
import MapIcon from '@mui/icons-material/Map';
import BiotechIcon from '@mui/icons-material/Biotech';
import PatientExperienceDashboard from './PatientExperienceDashboard';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import MDMDashboard from './MDMDashboard';
import FactCheckIcon from '@mui/icons-material/FactCheck';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`dashboard-tabpanel-${index}`}
            aria-labelledby={`dashboard-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const QualityDashboard = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="dashboard tabs"
                    sx={{
                        '& .MuiTab-root': {
                            minHeight: '72px',
                            fontSize: '1rem',
                        }
                    }}
                >
                    <Tab
                        icon={<FactCheckIcon />}
                        iconPosition="start"
                        label="MDM"
                    />
                    <Tab
                        icon={<AssessmentIcon />}
                        iconPosition="start"
                        label="MIPS Quality Measures"
                    />
                    <Tab
                        icon={<MonitorHeartIcon />}
                        iconPosition="start"
                        label="Risk Assessment"
                    />
                    <Tab
                        icon={<BiotechIcon />}
                        iconPosition="start"
                        label="Diagnostics"
                    />
                    <Tab
                        icon={<MapIcon />}
                        iconPosition="start"
                        label="Geographic View"
                    />
                    <Tab
                        icon={<SentimentSatisfiedAltIcon />}
                        iconPosition="start"
                        label="Patient Experience"
                    />
                </Tabs>
            </Paper>

            <TabPanel value={selectedTab} index={0}>
                <MDMDashboard />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <MIPSDashboard />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <RiskDashboard />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                <DiagnosticsDashboard />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
                <GeographicPerformanceMap />
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
                <PatientExperienceDashboard />
            </TabPanel>
        </Box>
    );
};

export default QualityDashboard; 