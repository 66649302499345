import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Collapse,
    IconButton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import InfoIcon from '@mui/icons-material/Info';
import { mdmBreakdown, mdmColors } from '../config/mdmConfig';

const MDMBreakdown = ({ onSpotlightOpen }) => {
    const [expandedCategory, setExpandedCategory] = useState(null);

    const handleCategoryClick = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };

    const handleSpotlightClick = (e, category, level) => {
        e.stopPropagation();
        const categoryData = mdmBreakdown[category];
        const levelData = categoryData.levels.find(l => l.name === level);
        
        // Convert the category name to the correct metric key format
        let metricKey = category.toLowerCase();
        if (metricKey === 'complexity of problems addressed') {
            metricKey = `problems_${level.toLowerCase()}`;
        } else if (metricKey === 'complexity of data') {
            metricKey = `data_${level.toLowerCase()}`;
        } else if (metricKey === 'risk of complications') {
            metricKey = `risk_${level.toLowerCase()}`;
        }

        // Use the level's actual percentage for the performance
        const performance = Math.round(levelData.percentage * 100);
        const trend = levelData.trend;
        
        onSpotlightOpen(metricKey, level, performance, trend);
    };

    return (
        <Paper 
            sx={{ 
                p: 3,
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1))',
                backdropFilter: 'blur(8px)',
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                Medical Decision-Making Breakdown
            </Typography>
            <Box sx={{ mt: 2 }}>
                {Object.entries(mdmBreakdown).map(([key, category]) => (
                    <Box
                        key={key}
                        sx={{
                            mb: 2,
                            p: 2,
                            borderRadius: 2,
                            bgcolor: expandedCategory === key 
                                ? 'rgba(25, 118, 210, 0.08)'
                                : 'transparent',
                            transition: 'all 0.2s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: 'rgba(25, 118, 210, 0.05)'
                            }
                        }}
                        onClick={() => handleCategoryClick(key)}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                    {category.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                    <Typography 
                                        variant="h5" 
                                        sx={{ 
                                            color: 'primary.main',
                                            fontWeight: 600
                                        }}
                                    >
                                        {category.trends.overall.toFixed(1)}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: category.trends.change >= 0 ? 'success.main' : 'error.main',
                                            typography: 'body2'
                                        }}
                                    >
                                        {category.trends.change >= 0 ? (
                                            <TrendingUpIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                        ) : (
                                            <TrendingDownIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                        )}
                                        {Math.abs(category.trends.change).toFixed(1)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {Object.entries(category.trends.distribution).map(([level, value]) => (
                                    <Box
                                        key={level}
                                        sx={{
                                            height: 40,
                                            width: 8,
                                            borderRadius: 4,
                                            bgcolor: `rgba(${level === 'minimal' ? '148, 163, 184' : 
                                                level === 'low' ? '96, 165, 250' :
                                                level === 'moderate' ? '37, 99, 235' :
                                                '30, 64, 175'}, 0.2)`,
                                            position: 'relative',
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                height: `${value}%`,
                                                bgcolor: mdmColors[level],
                                                borderRadius: 'inherit',
                                                transition: 'height 0.3s ease'
                                            }
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Collapse in={expandedCategory === key}>
                            <Box sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    {category.levels.map((level) => (
                                        <Grid item xs={12} sm={6} md={3} key={level.name}>
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    borderRadius: 2,
                                                    bgcolor: `rgba(${level.name.toLowerCase() === 'minimal' ? '148, 163, 184' : 
                                                        level.name.toLowerCase() === 'low' ? '96, 165, 250' :
                                                        level.name.toLowerCase() === 'moderate' ? '37, 99, 235' :
                                                        '30, 64, 175'}, 0.1)`,
                                                    height: '100%',
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                    transition: 'all 0.2s ease',
                                                    '&:hover': {
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: (theme) => `0 4px 12px ${alpha(theme.palette.primary.main, 0.08)}`,
                                                    }
                                                }}
                                                onClick={(e) => handleSpotlightClick(e, key, level.name)}
                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        {level.name}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', fontSize: '0.8rem' }}>
                                                    {level.description}
                                                </Typography>
                                                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Typography 
                                                        variant="h6" 
                                                        sx={{ 
                                                            color: mdmColors[level.name.toLowerCase()],
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        {category.trends.distribution[level.name.toLowerCase()]}%
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Collapse>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default MDMBreakdown; 