import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import { emCodes, emCodeColors } from '../../config/mdmConfig';

// Generate realistic E/M distribution data
const generateEMDistribution = () => ({
    '99281': 5,    // 5% of visits
    '99282': 10,   // 10% of visits
    '99283': 35,   // 35% of visits
    '99284': 30,   // 30% of visits
    '99285': 15,   // 15% of visits
    '99291': 4,    // 4% of visits
    '99292': 1,    // 1% of visits
});

export const EMDistributionCard = () => {
    const data = Object.entries(generateEMDistribution()).map(([code, value]) => ({
        code,
        value,
        name: emCodes[code],
    }));

    return (
        <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6">
                    E/M Code Distribution
                </Typography>
                <Tooltip title="Distribution of E/M codes across all encounters">
                    <IconButton size="small">
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="code"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label={({ code, value }) => `${code}: ${value}%`}
                    >
                        {data.map((entry) => (
                            <Cell key={entry.code} fill={emCodeColors[entry.code]} />
                        ))}
                    </Pie>
                    <RechartsTooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                const data = payload[0].payload;
                                return (
                                    <Box sx={{ 
                                        bgcolor: '#fff', 
                                        p: 1.5, 
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                    }}>
                                        <Typography variant="subtitle2">
                                            {data.code}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {data.name}
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 0.5 }}>
                                            {data.value}% of encounters
                                        </Typography>
                                    </Box>
                                );
                            }
                            return null;
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export const KeyMetricsCard = () => {
    const miniTrendData = Array(7).fill().map((_, i) => ({
        day: i,
        level: 3.2 + Math.random() * 0.4,
        revenue: 280 + Math.random() * 40
    }));

    return (
        <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
                Key Performance Indicators
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                        p: 2, 
                        bgcolor: 'rgba(66, 153, 225, 0.1)', 
                        borderRadius: 2,
                        height: '100%'
                    }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Average Level
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                            <Typography variant="h4" color="primary">
                                3.4
                            </Typography>
                            <Typography 
                                variant="body2" 
                                color="success.main"
                                sx={{ mb: 1 }}
                            >
                                +0.2
                            </Typography>
                        </Box>
                        <ResponsiveContainer width="100%" height={50}>
                            <LineChart data={miniTrendData}>
                                <Line
                                    type="monotone"
                                    dataKey="level"
                                    stroke="#4299E1"
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                        p: 2, 
                        bgcolor: 'rgba(229, 62, 62, 0.1)', 
                        borderRadius: 2,
                        height: '100%'
                    }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Critical Care Rate
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                            <Typography variant="h4" color="error">
                                5%
                            </Typography>
                            <Typography 
                                variant="body2" 
                                color="error.main"
                                sx={{ mb: 1 }}
                            >
                                +1.2%
                            </Typography>
                        </Box>
                        <ResponsiveContainer width="100%" height={50}>
                            <LineChart data={miniTrendData}>
                                <Line
                                    type="monotone"
                                    dataKey="revenue"
                                    stroke="#E53E3E"
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ 
                        p: 2, 
                        bgcolor: 'rgba(45, 55, 72, 0.05)', 
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Projected Monthly Revenue
                            </Typography>
                            <Typography variant="h5">
                                $1.2M
                            </Typography>
                        </Box>
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'flex-end'
                        }}>
                            <Typography variant="body2" color="success.main">
                                +$85K
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                vs. Last Month
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export const EMTrendsCard = () => {
    const generateTrendData = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        return months.map(month => ({
            month,
            ...Object.keys(emCodes).reduce((acc, code) => ({
                ...acc,
                [code]: Math.floor(Math.random() * 50),
            }), {}),
        }));
    };

    return (
        <Paper sx={{ 
            p: 3,
            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1))',
            backdropFilter: 'blur(8px)',
        }}>
            <Typography variant="h6" gutterBottom>
                Evaluation & Management Code Trends
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart 
                    data={generateTrendData()}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                    <CartesianGrid 
                        strokeDasharray="3 3" 
                        vertical={false}
                        stroke="rgba(0,0,0,0.1)"
                    />
                    <XAxis 
                        dataKey="month" 
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: '#666', fontSize: 12 }}
                    />
                    <YAxis 
                        domain={[0, 50]}
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: '#666', fontSize: 12 }}
                        width={40}
                    />
                    <RechartsTooltip
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <Box sx={{
                                        bgcolor: '#fff',
                                        p: 2,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        boxShadow: 2,
                                    }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {label}
                                        </Typography>
                                        {payload.map((entry) => (
                                            <Box key={entry.dataKey} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                my: 0.5,
                                            }}>
                                                <Box sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    bgcolor: entry.color,
                                                }} />
                                                <Typography variant="body2">
                                                    {entry.dataKey}: {entry.value.toFixed(1)}%
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                );
                            }
                            return null;
                        }}
                    />
                    <Legend 
                        verticalAlign="top"
                        height={36}
                        content={({ payload }) => (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                gap: 2,
                                flexWrap: 'wrap',
                                mb: 2
                            }}>
                                {payload.map((entry) => (
                                    <Box key={entry.value} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}>
                                        <Box sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            bgcolor: entry.color,
                                        }} />
                                        <Typography variant="body2" sx={{ 
                                            color: 'text.secondary',
                                            fontSize: '0.875rem',
                                        }}>
                                            {entry.value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    />
                    {Object.keys(emCodes).map(code => (
                        <Line
                            key={code}
                            type="monotone"
                            dataKey={code}
                            name={code}
                            stroke={emCodeColors[code]}
                            strokeWidth={2}
                            dot={false}
                            activeDot={{ 
                                r: 4, 
                                strokeWidth: 2,
                                stroke: '#fff'
                            }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
}; 