import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Chip,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
} from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    BarChart,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardFilters from './DashboardFilters';
import { getPerformanceColor, generateRealisticCompliance } from '../utils/facilityData';

// Clinical Risk Scores configuration
const riskScores = {
    'HEART': {
        name: 'HEART Score',
        description: 'Risk stratification for chest pain patients',
        applicableTo: 'Chest Pain',
        scoreRange: '0-10',
        thresholds: {
            low: '0-3',
            medium: '4-6',
            high: '7-10'
        },
        expectedUsage: 85, // Expected usage percentage for applicable patients
    },
    'NIHSS': {
        name: 'NIH Stroke Scale',
        description: 'Quantitative measure of stroke-related neurologic deficit',
        applicableTo: 'Stroke Symptoms',
        scoreRange: '0-42',
        thresholds: {
            minor: '0-4',
            moderate: '5-15',
            severe: '16-42'
        },
        expectedUsage: 90,
    },
    'PERC': {
        name: 'PERC Rule',
        description: 'Pulmonary Embolism Rule-out Criteria',
        applicableTo: 'Suspected PE',
        scoreRange: '0-8',
        thresholds: {
            negative: '0',
            positive: '≥1'
        },
        expectedUsage: 80,
    },
    'PECARN': {
        name: 'PECARN',
        description: 'Pediatric Head Injury/Trauma Algorithm',
        applicableTo: 'Pediatric Head Trauma',
        scoreRange: 'Algorithm-based',
        thresholds: {
            veryLow: 'No criteria',
            intermediate: '1-2 criteria',
            high: '≥3 criteria'
        },
        expectedUsage: 95,
    },
    'Wells': {
        name: 'Wells Score',
        description: 'DVT/PE probability assessment',
        applicableTo: 'Suspected DVT/PE',
        scoreRange: '0-12.5',
        thresholds: {
            low: '0-1',
            moderate: '2-6',
            high: '≥6'
        },
        expectedUsage: 85,
    },
};

const RiskDashboard = () => {
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedFacility, setSelectedFacility] = useState('all');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [selectedMetricView, setSelectedMetricView] = useState('usage'); // 'usage' or 'trends'

    // Mock data generators
    const generateUsageData = (expectedUsage) => {
        return Math.floor(Math.random() * (expectedUsage + 5 - (expectedUsage - 10)) + (expectedUsage - 10));
    };

    const generateScoreTrends = () => {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map(month => ({
            month,
            average: Math.floor(Math.random() * 10),
            compliance: Math.floor(Math.random() * (100 - 70) + 70),
        }));
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Clinical Risk Scores Dashboard
            </Typography>

            {/* Filters */}
            <DashboardFilters
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
            />

            {/* View Toggle */}
            <Tabs
                value={selectedMetricView}
                onChange={(e, newValue) => setSelectedMetricView(newValue)}
                sx={{ mb: 3 }}
            >
                <Tab value="usage" label="Usage Compliance" />
                <Tab value="trends" label="Score Trends" />
            </Tabs>

            {selectedMetricView === 'usage' ? (
                // Usage Compliance View
                <Grid container spacing={3}>
                    {Object.entries(riskScores).map(([key, score]) => {
                        const usage = generateUsageData(score.expectedUsage);
                        return (
                            <Grid item xs={12} md={6} lg={4} key={key}>
                                <Card sx={{ p: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">
                                            {score.name}
                                            <Tooltip title={score.description}>
                                                <IconButton size="small">
                                                    <InfoIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Applicable to: {score.applicableTo}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', mt: 2 }}>
                                        <Typography variant="h3"
                                            sx={{
                                                color: getPerformanceColor(usage)
                                            }}
                                        >
                                            {usage}%
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                            of applicable cases
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Target: {score.expectedUsage}%
                                    </Typography>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                // Score Trends View
                <Grid container spacing={3}>
                    {Object.entries(riskScores).map(([key, score]) => (
                        <Grid item xs={12} md={6} key={key}>
                            <Card sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    {score.name} Trends
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={generateScoreTrends()}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis yAxisId="left" />
                                        <YAxis yAxisId="right" orientation="right" />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="average"
                                            stroke="#8884d8"
                                            name="Avg Score"
                                        />
                                        <Line
                                            yAxisId="right"
                                            type="monotone"
                                            dataKey="compliance"
                                            stroke="#82ca9d"
                                            name="Usage %"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default RiskDashboard; 