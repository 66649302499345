import React from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper
} from '@mui/material';
import { organizationStructure } from '../utils/facilityData';

const DashboardFilters = ({ 
    selectedRegion, 
    setSelectedRegion,
    selectedFacility, 
    setSelectedFacility,
    selectedTimePeriod, 
    setSelectedTimePeriod,
    additionalFilters
}) => {
    const handleRegionChange = (event) => {
        setSelectedRegion(event.target.value);
        setSelectedFacility('all'); // Reset facility when region changes
    };

    return (
        <Paper sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Region</InputLabel>
                        <Select
                            value={selectedRegion}
                            onChange={handleRegionChange}
                            label="Region"
                        >
                            <MenuItem value="all">All Regions</MenuItem>
                            {organizationStructure.regions.map(region => (
                                <MenuItem key={region.id} value={region.id}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Facility</InputLabel>
                        <Select
                            value={selectedFacility}
                            onChange={(e) => setSelectedFacility(e.target.value)}
                            label="Facility"
                        >
                            <MenuItem value="all">All Facilities</MenuItem>
                            {selectedRegion === 'all' 
                                ? organizationStructure.regions.flatMap(region => 
                                    region.facilities.map(facility => (
                                        <MenuItem key={facility.id} value={facility.id}>
                                            {facility.name}
                                        </MenuItem>
                                    ))
                                )
                                : organizationStructure.regions
                                    .find(r => r.id === selectedRegion)
                                    ?.facilities.map(facility => (
                                        <MenuItem key={facility.id} value={facility.id}>
                                            {facility.name}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Time Period</InputLabel>
                        <Select
                            value={selectedTimePeriod}
                            onChange={(e) => setSelectedTimePeriod(e.target.value)}
                            label="Time Period"
                        >
                            <MenuItem value="last30">Last 30 Days</MenuItem>
                            <MenuItem value="last90">Last 90 Days</MenuItem>
                            <MenuItem value="lastYear">Last Year</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {additionalFilters}
            </Grid>
        </Paper>
    );
};

export default DashboardFilters; 