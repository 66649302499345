import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/NoteDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faComments, faClipboardList, faCalendarAlt, faClock, faLightbulb, faUser,
    faHospital, faPlusCircle, faExchangeAlt, faSignOutAlt,
    faUserPlus, faXmark, faTrash, faPlus, faFileAlt, faTimes, faCheckCircle, faSync, faSearch, faSort, faCheck, faChevronDown, faCalculator, faUsers
} from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';

import { Fieldset, Legend } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';
import { Button } from '../components/catalyst/button';
import TransferPatientModal from '../components/TransferPatientModal';
import ProgressBar from '../components/PatientViewer/ProgressBars';
import PatientPoolModal from '../components/PatientPoolModal';

function HospitalistNoteDashboard() {
    const { instance, accounts, inProgress } = useMsal();
    const [inpatientStays, setInpatientStays] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [loadingProgressNote, setLoadingProgressNote] = useState({});
    const [loadingDischargeSummary, setLoadingDischargeSummary] = useState({});
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedStay, setSelectedStay] = useState(null);
    const [groupActors, setGroupActors] = useState([]);
    const [loadingActors, setLoadingActors] = useState(false);
    const [showDischargeAlert, setShowDischargeAlert] = useState(false);
    const [selectedStayForDischarge, setSelectedStayForDischarge] = useState(null);
    const [dischargingStays, setDischargingStays] = useState({});
    const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
    const [patients, setPatients] = useState([{ name: '', complaint: '', age: '', gender: '', externalNote: '', nameError: false, complaintError: false }]);
    const MAX_PATIENTS = 3;
    const [formError, setFormError] = useState(false);
    const [loadingExternalNote, setLoadingExternalNote] = useState({});
    const [selectedSummary, setSelectedSummary] = useState(null);
    const [isCodingAssistantVisible, setIsCodingAssistantVisible] = useState(false);
    const [codingAssistantNoteContent, setCodingAssistantNoteContent] = useState('');
    const [isCodingAssistantSubmitting, setIsCodingAssistantSubmitting] = useState(false);
    const [codingAssistantResult, setCodingAssistantResult] = useState(null);
    const [codingAssistantError, setCodingAssistantError] = useState(null);
    const codingAssistantRef = useRef(null);
    const [codingAssistantNoteType, setCodingAssistantNoteType] = useState('');
    const [sortKey, setSortKey] = useState(location.state?.sortKey || 'created_at');
    const [showSortMenu, setShowSortMenu] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const sortMenuRef = useRef(null);
    const [isPoolModalOpen, setIsPoolModalOpen] = useState(false);
    const [poolPatients, setPoolPatients] = useState([]);
    const [isLoadingPool, setIsLoadingPool] = useState(false);

    const handleSortChange = (key) => {
        setSortKey(key);
        setShowSortMenu(false);
    };

    // Click outside to close sort menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortMenuRef.current && !sortMenuRef.current.contains(event.target)) {
                setShowSortMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const handleSubmitCodingAssistantNote = useCallback(async () => {
        setIsCodingAssistantSubmitting(true);
        setCodingAssistantError(null);
        setCodingAssistantResult(null);
        const noteContentWithType = `${codingAssistantNoteType}\n\n${codingAssistantNoteContent}`;

        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCodingAssistant.scopes,
                    account: accounts[0],
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        note_content: noteContentWithType,
                    }),
                };

                const fetchResponse = await fetch(protectedResources.apiCodingAssistant.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    const codingEstimation = {
                        complexityOfProblemAddressed: data.complexity_of_problem_addressed,
                        copaScore: data.copa_score,
                        cdaScore: data.cda_score,
                        riskScore: data.risk_score,
                        overallMDMLevel: data.overall_mdm_level,
                        billingCode: data.billing_code,
                    };

                    setCodingAssistantResult({ codingEstimation });
                } else {
                    console.error('Failed to calculate coding estimation:', data);
                    setCodingAssistantError('An error occurred while calculating the coding estimation.');
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error calculating coding estimation:', error);
                setCodingAssistantError('An error occurred while calculating the coding estimation.');
            } finally {
                setIsCodingAssistantSubmitting(false);
            }
        } else {
            setIsCodingAssistantSubmitting(false);
            setCodingAssistantError('No user account available.');
        }
    }, [instance, accounts, codingAssistantNoteContent, handleTokenExpiration]);

    useEffect(() => {
        if (isCodingAssistantVisible) {
            codingAssistantRef.current?.focus();
        }
    }, [isCodingAssistantVisible]);



    const addNewProgressNote = useCallback(async (stay) => {
        setLoadingProgressNote(prev => ({ ...prev, [stay.inpatient_id]: true }));
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCreateProgressNote.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        is_android: false
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiCreateProgressNote.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays =>
                        prevStays.map(s =>
                            s.inpatient_id === stay.inpatient_id
                                ? { ...s, documents: [data, ...s.documents].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) }
                                : s
                        )
                    );
                } else {
                    console.error('Failed to create progress note:', data);
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error creating progress note:', error);
            }
        }
        setLoadingProgressNote(prev => ({ ...prev, [stay.inpatient_id]: false }));
    }, [instance, accounts, handleTokenExpiration]);

    const addNewExternalNote = useCallback(async (stay) => {
        setLoadingExternalNote(prev => ({ ...prev, [stay.inpatient_id]: true }));
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCreateExternalNote.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        is_android: false
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiCreateExternalNote.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays =>
                        prevStays.map(s =>
                            s.inpatient_id === stay.inpatient_id
                                ? { ...s, documents: [data, ...s.documents].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) }
                                : s
                        )
                    );
                } else {
                    console.error('Failed to create external note:', data);
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error creating external note:', error);
            }
        }
        setLoadingExternalNote(prev => ({ ...prev, [stay.inpatient_id]: false }));
    }, [instance, accounts, handleTokenExpiration]);

    const addNewDischargeSummary = useCallback(async (stay) => {
        setLoadingDischargeSummary(prev => ({ ...prev, [stay.inpatient_id]: true }));
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiCreateDischargeSummary.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        is_android: false
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiCreateDischargeSummary.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays =>
                        prevStays.map(s =>
                            s.inpatient_id === stay.inpatient_id
                                ? { ...s, documents: [data, ...s.documents].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) }
                                : s
                        )
                    );
                } else {
                    console.error('Failed to create discharge summary:', data);
                }
            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error creating discharge summary:', error);
            }
        }
        setLoadingDischargeSummary(prev => ({ ...prev, [stay.inpatient_id]: false }));
    }, [instance, accounts, handleTokenExpiration]);

    const addPatientField = () => {
        if (patients.length < MAX_PATIENTS) {
            setPatients([...patients, { name: '', complaint: '', age: '', gender: '', nameError: false, complaintError: false }]);
        }
    };

    const handleAddPatients = async () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        setIsAddPatientModalOpen(false);

        try {
            const request = {
                scopes: protectedResources.apiCreateInpatientStay.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            for (const patient of patients) {
                // Prepare the payload  
                const payload = {
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    patient_name: patient.name,
                    original_chief_complaint: patient.complaint,
                };

                if (patient.gender) {
                    payload.patient_sex = patient.gender.toUpperCase();
                }

                if (patient.age) {
                    const age = parseInt(patient.age);
                    if (age >= 0 && age <= 120) {
                        payload.patient_age = patient.age;
                    }
                }

                if (patient.externalNote && patient.externalNote.trim() !== '') {
                    // If external note is provided, add it to the payload  
                    payload.external_document = patient.externalNote;

                    // Call the endpoint for creating inpatient stay with external note  
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(payload)
                    };

                    const fetchResponse = await fetch(protectedResources.apiCreateInpatientStayWithExternalDoc.endpoint, requestOptions);
                    const data = await fetchResponse.json();

                    if (data.inpatient_stay_id) {
                        // Optionally, handle the created inpatient stay ID  
                    } else {
                        throw new Error('Failed to create inpatient stay with external note');
                    }

                } else {
                    // If no external note, call the original endpoint  
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(payload)
                    };

                    const fetchResponse = await fetch(protectedResources.apiCreateInpatientStay.endpoint, requestOptions);
                    const data = await fetchResponse.json();

                    if (data.inpatient_stay_id) {
                        // Optionally, handle the created inpatient stay ID  
                    } else {
                        throw new Error('Failed to create inpatient stay');
                    }
                }
            }

            setPatients([{ name: '', complaint: '', age: '', gender: '', externalNote: '', nameError: false, complaintError: false }]);
            // Refresh the inpatient stays list  
            fetchInpatientStays();

        } catch (error) {
            console.error('Error creating patients:', error);
            // TODO: Handle error (e.g., show error message to user)  
        } finally {
            setIsLoading(false);
        }
    };

    const updatePatient = (index, field, value) => {
        const updatedPatients = [...patients];
        updatedPatients[index] = { ...updatedPatients[index], [field]: value, [`${field}Error`]: false };
        setPatients(updatedPatients);
        setFormError(false);
    };

    const removePatient = (index) => {
        const updatedPatients = patients.filter((_, i) => i !== index);
        setPatients(updatedPatients);
    };

    const validateForm = () => {
        let isValid = true;
        const updatedPatients = patients.map(patient => ({
            ...patient,
            nameError: patient.name.trim() === '',
            complaintError: patient.complaint.trim() === ''
        }));

        setPatients(updatedPatients);

        if (updatedPatients.some(patient => patient.nameError || patient.complaintError)) {
            isValid = false;
            setFormError(true);
        }

        return isValid;
    };

    const sortedInpatientStays = useMemo(() => {
        const sortableStays = [...inpatientStays];

        sortableStays.sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            // Check if the sort key is a date field
            if (sortKey === 'created_at' || sortKey === 'last_updated_at') {
                // Convert values to Date objects
                aValue = new Date(aValue);
                bValue = new Date(bValue);

                // Sort dates in descending order
                if (aValue > bValue) {
                    return -1;
                }
                if (aValue < bValue) {
                    return 1;
                }
                return 0;
            } else {
                // For strings, ensure case-insensitive comparison
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();

                // Sort strings in ascending order
                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            }
        });

        return sortableStays;
    }, [inpatientStays, sortKey]);



    const filteredInpatientStays = useMemo(() => {
        return sortedInpatientStays.filter(stay =>
            stay.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, sortedInpatientStays]);




    const fetchInpatientStays = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetInpatientStays.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };

                const fetchResponse = await fetch(protectedResources.apiGetInpatientStays.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data.inpatient_stays && data.inpatient_stays.length > 0) {
                    const sortedStays = data.inpatient_stays.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });
                    setInpatientStays(data.inpatient_stays);
                } else {
                    setInpatientStays([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching inpatient stays:', error);
            }
        }
        setIsLoading(false);
    }, [instance, accounts, handleTokenExpiration]);

    useEffect(() => {
        const initializeAndFetch = async () => {
            if (inProgress === InteractionStatus.None) {
                await fetchInpatientStays();
            }
        };

        initializeAndFetch();
    }, [inProgress, fetchInpatientStays]);

    const handleDocumentClick = (stay, document) => {
        navigate('/hospitalist-chart', { state: { inpatientStay: stay, document: document, sortKey: sortKey } });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        try {
            // Attempt to parse the date string
            const date = parseISO(dateString);
            // Check if the parsed date is valid
            if (isNaN(date.getTime())) {
                return 'Invalid Date';
            }
            return format(date, 'MMM d, yyyy h:mm a');
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid Date';
        }
    };

    const sortDocuments = (documents) => {
        return [...documents].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateB - dateA; // Sort in descending order (most recent first)
        });
    };

    const formatDocumentType = (type) => {
        return type.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ');
    };

    const openTransferPatientModal = useCallback((stay) => {
        setSelectedStay(stay);
        setShowTransferModal(true);
        fetchGroupActors();
    }, []);

    const fetchGroupActors = useCallback(async () => {
        setLoadingActors(true);
        if (accounts.length > 0) {
            try {
                const request = {
                    scopes: protectedResources.apiGetGroupHospitalists.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };

                const fetchResponse = await fetch(protectedResources.apiGetGroupHospitalists.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (data && data.length > 0) {
                    setGroupActors(data);
                } else {
                    setGroupActors([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching group actors:', error);
            }
        }
        setLoadingActors(false);
    }, [instance, accounts, handleTokenExpiration]);

    const openDischargeConfirmation = useCallback((stay) => {
        setSelectedStayForDischarge(stay);
        setShowDischargeAlert(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setShowTransferModal(false);
    }, []);

    const dischargeAndRemovePatient = useCallback(async (stay) => {
        if (accounts.length > 0 && stay) {
            setDischargingStays(prev => ({ ...prev, [stay.inpatient_id]: true }));
            try {
                const request = {
                    scopes: protectedResources.apiDischargePatient.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiDischargePatient.endpoint, requestOptions);
                const data = await fetchResponse.json();

                if (fetchResponse.ok) {
                    setInpatientStays(prevStays => prevStays.filter(s => s.inpatient_id !== stay.inpatient_id));
                } else {
                    console.error('Failed to discharge patient:', data);
                    alert('Failed to discharge patient.');
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error discharging patient:', error);
                alert('Error discharging patient. Please try again.');
            }
            setDischargingStays(prev => ({ ...prev, [stay.inpatient_id]: false }));
        }
    }, [instance, accounts, handleTokenExpiration]);

    const toggleExternalNoteSection = (index) => {
        const updatedPatients = [...patients];
        updatedPatients[index].isExternalNoteExpanded = !updatedPatients[index].isExternalNoteExpanded;
        setPatients(updatedPatients);
    };

    const handleExternalNoteChange = (index, value) => {
        const updatedPatients = [...patients];
        updatedPatients[index].externalNote = value;
        updatedPatients[index].hasUnsavedExternalNote = true;
        setPatients(updatedPatients);
    };

    const saveExternalNoteForPatient = (index) => {
        const updatedPatients = [...patients];
        updatedPatients[index].hasUnsavedExternalNote = false;
        setPatients(updatedPatients);
    };

    const handlePatientClaimed = useCallback((claimedPatient) => {
        // Add the claimed patient to inpatientStays
        setInpatientStays(prevStays => [...prevStays, {
            ...claimedPatient,
            responsible_provider_id: accounts[0]?.id // Current user becomes responsible
        }]);
    }, [accounts]);

    const handlePatientTransferred = useCallback((transferredPatientId, newProviderId) => {
        setInpatientStays(prevStays =>
            prevStays.filter(stay => stay.inpatient_id !== transferredPatientId)
        );
    }, []);

    const handlePatientReleasedToPool = useCallback((releasedPatientId) => {
        setInpatientStays(prevStays =>
            prevStays.filter(stay => stay.inpatient_id !== releasedPatientId)
        );
    }, []);

    return (
        <div className="dashboard-container">
            <div className="w-full py-4 mb-1 rounded-md">
                <Fieldset className="flex flex-wrap items-center justify-between">
                    {/* Left Section */}
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faClipboardList} className="text-indigo-900 text-3xl" />
                        <Fieldset>
                            <h1 className="text-2xl font-bold text-indigo-900">Inpatient Dashboard</h1>
                            <p className="text-md text-indigo-900">Manage your active inpatient stays</p>
                        </Fieldset>
                        <button
                            onClick={fetchInpatientStays}
                            className="bg-indigo-900 text-white p-2 rounded-full hover:bg-indigo-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                            aria-label="Refresh Inpatient Stays"
                        >
                            <FontAwesomeIcon icon={faSync} className="text-xl" />
                        </button>
                    </Fieldset>

                    {/* Right Section */}
                    <Fieldset className="flex items-center space-x-4 mt-4 sm:mt-0">
                        {/* Search Bar */}
                        <div className="relative">
                            <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                            <input
                                type="text"
                                placeholder="Search by Patient Name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="border rounded-md pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full sm:w-64"
                            />
                        </div>

                        {/* Sort Menu */}
                        <div className="relative" ref={sortMenuRef}>
                            <button
                                onClick={() => setShowSortMenu(!showSortMenu)}
                                className="flex items-center bg-white border border-gray-300 rounded-md px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <FontAwesomeIcon icon={faSort} className="mr-2" />
                                Sort By
                            </button>
                            {showSortMenu && (
                                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                                    <button
                                        onClick={() => handleSortChange('patient_name')}
                                        className="flex justify-between w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    >
                                        Patient Name
                                        {sortKey === 'patient_name' && (
                                            <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                        )}
                                    </button>
                                    <button
                                        onClick={() => handleSortChange('created_at')}
                                        className="flex justify-between w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    >
                                        Admitted Date
                                        {sortKey === 'created_at' && (
                                            <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                        )}
                                    </button>
                                    <button
                                        onClick={() => handleSortChange('last_updated_at')}
                                        className="flex justify-between w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                    >
                                        Last Updated
                                        {sortKey === 'last_updated_at' && (
                                            <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Patient Pool Button */}
                        <button
                            onClick={() => setIsPoolModalOpen(true)}
                            className="bg-indigo-900 text-white px-4 py-2 rounded-md flex items-center hover:bg-indigo-800 transition duration-200"
                        >
                            <FontAwesomeIcon icon={faUsers} className="mr-2" />
                            Patient Pool
                        </button>

                        {/* Coding Assistant Button */}
                        <button
                            onClick={() => setIsCodingAssistantVisible(true)}
                            className="bg-indigo-900 text-white px-4 py-2 rounded-md flex items-center"
                        >
                            <FontAwesomeIcon icon={faCalculator} className="mr-2" />
                            Coding Assistant
                        </button>

                        {/* Add Patients Button */}
                        <button
                            onClick={() => setIsAddPatientModalOpen(true)}
                            className="bg-indigo-900 text-white px-4 py-2 rounded-md flex items-center hover:bg-indigo-800 transition duration-200"
                        >
                            <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                            Add Patients
                        </button>
                    </Fieldset>
                </Fieldset>
            </div>


            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading inpatient stays...</div>
                </div>
            ) : (
                inpatientStays.length > 0 ? (
                    <Fieldset className="w-full bg-white rounded-lg">
                        {filteredInpatientStays.map((stay, index) => (
                            <Fieldset key={index} className="bg-white rounded-lg shadow-lg mb-4 p-4">
                                <Fieldset className="flex flex-col">
                                    <Fieldset className="flex justify-between items-center mb-4">
                                        <Fieldset className="flex items-center">
                                            <FontAwesomeIcon icon={faUser} className="mr-2 text-indigo-900 text-xl" />
                                            <Text className="font-bold text-xl text-indigo-800">{stay.patient_name}</Text>
                                        </Fieldset>
                                        <Fieldset className="flex items-center space-x-4">
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faComments} className="mr-1 text-indigo-900" />
                                                <span className="font-semibold">Chief Complaint:</span> {stay.original_chief_complaint}
                                            </Text>
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faClock} className="mr-1 text-indigo-900" />
                                                <span className="font-semibold">Last Updated:</span> {formatDate(stay.last_updated_at)}
                                            </Text>
                                            <Text className="text-sm text-gray-600">
                                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 text-indigo-900" />
                                                <span className="font-semibold">Admitted:</span> {formatDate(stay.created_at)}
                                            </Text>
                                            {stay.transfer_summary && (
                                                <button
                                                    onClick={() => setSelectedSummary(stay.transfer_summary)}
                                                    className="flex items-center text-indigo-900 hover:text-indigo-700 focus:outline-none"
                                                    aria-label="View Transfer Summary"
                                                >
                                                    <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
                                                    <span className="text-sm">Transfer Summary</span>
                                                </button>
                                            )}
                                        </Fieldset>
                                    </Fieldset>
                                    <Fieldset className="mt-2">
                                        <div className="flex justify-between items-center mb-2">
                                            <Text className="font-semibold text-indigo-700">Charts:</Text>
                                            <div className="flex space-x-2">
                                                {!stay.documents.some(doc => doc.hospitalist_document_type === 'DISCHARGE_SUMMARY') && (
                                                    <>
                                                        <button
                                                            onClick={() => addNewExternalNote(stay)}
                                                            disabled={loadingProgressNote[stay.inpatient_id] || loadingDischargeSummary[stay.inpatient_id] || loadingExternalNote[stay.inpatient_id]}
                                                            className="bg-indigo-900 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 disabled:opacity-50 disabled:cursor-not-allowed"
                                                        >
                                                            {loadingExternalNote[stay.inpatient_id] ? (
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                                                            )}
                                                            External Note
                                                        </button>
                                                        <button
                                                            onClick={() => addNewProgressNote(stay)}
                                                            disabled={loadingProgressNote[stay.inpatient_id] || loadingDischargeSummary[stay.inpatient_id]}
                                                            className="bg-indigo-900 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 disabled:opacity-50 disabled:cursor-not-allowed"
                                                        >
                                                            {loadingProgressNote[stay.inpatient_id] ? (
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                                                            )}
                                                            Progress Note
                                                        </button>
                                                        <button
                                                            onClick={() => addNewDischargeSummary(stay)}
                                                            disabled={loadingProgressNote[stay.inpatient_id] || loadingDischargeSummary[stay.inpatient_id]}
                                                            className="bg-indigo-900 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 disabled:opacity-50 disabled:cursor-not-allowed"
                                                        >
                                                            {loadingDischargeSummary[stay.inpatient_id] ? (
                                                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                                                            )}
                                                            Discharge Summary
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    onClick={() => openTransferPatientModal(stay)}
                                                    className="bg-indigo-900 text-white px-3 py-1 text-sm rounded-md hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900"
                                                >
                                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-1" />
                                                    Generate Summary / Transfer Patient
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (window.confirm(`Are you sure you want to discharge and remove ${stay.patient_name}?`)) {
                                                            dischargeAndRemovePatient(stay);
                                                        }
                                                    }}
                                                    className="bg-red-600 text-white px-3 py-1 text-sm rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    disabled={dischargingStays[stay.inpatient_id]}
                                                >
                                                    {dischargingStays[stay.inpatient_id] ? (
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                                                    )}
                                                    Discharge and Remove Patient
                                                </button>
                                            </div>
                                        </div>
                                        <Fieldset className="border-t border-gray-200 pt-2">
                                            {sortDocuments(stay.documents).map((doc, docIndex) => (
                                                <Fieldset
                                                    key={docIndex}
                                                    className="flex justify-between items-center py-2 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                                                    onClick={() => handleDocumentClick(stay, doc)}
                                                >
                                                    <Text className="text-sm font-medium text-gray-700">
                                                        {formatDocumentType(doc.hospitalist_document_type)}
                                                    </Text>
                                                    <Text className="text-xs text-gray-500">
                                                        {formatDate(doc.created_at)}
                                                    </Text>
                                                </Fieldset>
                                            ))}
                                        </Fieldset>
                                    </Fieldset>
                                </Fieldset>
                            </Fieldset>
                        ))}
                    </Fieldset>
                ) : (
                    <Fieldset className="w-full flex flex-col items-center justify-center h-full">
                        <Legend>No Active Inpatient Stays</Legend>
                        <Text className="mt-4 text-center">
                            There are no active inpatient stays at the moment.
                        </Text>
                    </Fieldset>
                )
            )}

            <div className="feedback-link">
                <a
                    data-canny-link
                    href="https://cleo-health.canny.io"
                    target="_blank"
                    className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                >
                    <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                    Request a Feature
                </a>
            </div>

            <TransferPatientModal
                isOpen={showTransferModal}
                onClose={handleModalClose}
                stay={selectedStay}
                groupActors={groupActors}
                isLoading={loadingActors}
                instance={instance}
                accounts={accounts}
                protectedResources={protectedResources}
                onPatientTransferred={handlePatientTransferred}
                onPatientReleased={handlePatientReleasedToPool}
            />

            {/* Transfer Summary Modal */}
            {selectedSummary && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold text-indigo-700">Transfer Summary</h3>
                            <button onClick={() => setSelectedSummary(null)} className="text-gray-500 hover:text-gray-700 focus:outline-none">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        {/* Scrollable Container for Summary */}
                        <div className="mb-4 overflow-y-auto max-h-96">
                            <p className="text-gray-700 whitespace-pre-wrap">{selectedSummary}</p>
                        </div>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setSelectedSummary(null)}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}



            {isAddPatientModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-xl w-[32rem] relative">
                        <button
                            onClick={() => setIsAddPatientModalOpen(false)}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            <FontAwesomeIcon icon={faXmark} className="text-xl" />
                        </button>
                        <h2 className="text-2xl font-bold mb-6 text-indigo-900">Add New Patients</h2>
                        {patients.map((patient, index) => (
                            <div key={index} className="mb-4 relative">
                                <input
                                    type="text"
                                    placeholder="Name or Room Number"
                                    className={`w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${patient.nameError ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    value={patient.name}
                                    onChange={(e) => updatePatient(index, 'name', e.target.value)}
                                />
                                {patient.nameError && (
                                    <p className="text-red-500 text-xs italic mb-2">Name or Room Number is required</p>
                                )}
                                <input
                                    type="text"
                                    placeholder="Chief Complaint"
                                    className={`w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${patient.complaintError ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    value={patient.complaint}
                                    onChange={(e) => updatePatient(index, 'complaint', e.target.value)}
                                />
                                {patient.complaintError && (
                                    <p className="text-red-500 text-xs italic mb-2">Chief complaint is required</p>
                                )}
                                <div className="flex space-x-2 mb-2">
                                    <input
                                        type="number"
                                        placeholder="Age (optional)"
                                        className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={patient.age}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || (value.length <= 3 && /^\d+$/.test(value))) {
                                                updatePatient(index, 'age', value);
                                            }
                                        }}
                                        min="0"
                                        max="999"
                                    />
                                    <select
                                        className="w-1/2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={patient.gender}
                                        onChange={(e) => updatePatient(index, 'gender', e.target.value)}
                                    >
                                        <option value="">Gender (optional)</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {/* Add External Note Button */}
                                <div className="flex items-center justify-between mt-2">
                                    <button
                                        onClick={() => toggleExternalNoteSection(index)}
                                        className="text-indigo-900 hover:text-indigo-700 focus:outline-none flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                                        {patient.isExternalNoteExpanded ? 'Hide External Note' : (patient.externalNote ? 'Edit External Note' : 'Add External Note')}
                                        {patient.externalNote && !patient.hasUnsavedExternalNote && (
                                            <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-green-500" />
                                        )}
                                    </button>
                                </div>

                                {/* External Note Section */}
                                {patient.isExternalNoteExpanded && (
                                    <div className="mt-2">
                                        <textarea
                                            placeholder="Paste External Note Here..."
                                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 max-h-[200px] overflow-y-auto"
                                            value={patient.externalNote}
                                            onChange={(e) => handleExternalNoteChange(index, e.target.value)}
                                            rows={6}
                                        ></textarea>
                                        <div className="flex justify-between items-center mt-2">
                                            {patient.hasUnsavedExternalNote && (
                                                <span className="text-yellow-600 text-sm">Unsaved Changes</span>
                                            )}
                                            <button
                                                onClick={() => saveExternalNoteForPatient(index)}
                                                className="px-4 py-1 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition duration-200"
                                            >
                                                Save Note
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {patients.length > 1 && (
                                    <button
                                        onClick={() => removePatient(index)}
                                        className="absolute top-0 right-0 mt-2 mr-2 text-red-500 hover:text-red-700"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                )}
                            </div>
                        ))}
                        {patients.length < MAX_PATIENTS && (
                            <button
                                onClick={addPatientField}
                                className="w-full mb-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200 flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                Add Another Patient
                            </button>
                        )}
                        <div className="flex justify-between space-x-4">
                            <button
                                onClick={() => setIsAddPatientModalOpen(false)}
                                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddPatients}
                                className="px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition duration-200"
                            >
                                Add Patients
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isCodingAssistantVisible && (
                <div
                    className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
                    role="dialog"
                    aria-modal="true"
                >
                    <div
                        className="bg-white w-full max-w-2xl rounded-lg shadow-xl p-6 relative"
                        tabIndex="-1"
                        ref={codingAssistantRef}
                    >
                        {/* Close Button */}
                        <button
                            onClick={() => setIsCodingAssistantVisible(false)}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                            disabled={isCodingAssistantSubmitting}
                            aria-label="Close Coding Assistant"
                        >
                            <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                        </button>

                        {/* Coding Assistant Content */}
                        <h2 className="text-2xl font-bold mb-4 text-indigo-900">Cleo Coding Assistant</h2>
                        <p className="text-gray-700 mb-6">Select the note type and paste your note below to generate a coding estimation.</p>

                        {/* Note Type Selector */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">
                                Note Type
                                {/* <span className="text-red-500">*</span> */}
                            </label>
                            <div className="grid grid-cols-2 gap-4">
                                {[
                                    { label: 'Admission Note', value: 'Admission Note' },
                                    { label: 'Progress Note', value: 'Progress Note' },
                                    { label: 'Discharge Summary', value: 'Discharge Summary' },
                                    { label: 'Consultation Note', value: 'Consultation Note' },
                                ].map((noteType) => (
                                    <label key={noteType.value} className="flex items-center">
                                        <input
                                            type="radio"
                                            name="noteType"
                                            value={noteType.value}
                                            checked={codingAssistantNoteType === noteType.value}
                                            onChange={(e) => setCodingAssistantNoteType(e.target.value)}
                                            disabled={isCodingAssistantSubmitting}
                                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="ml-2 text-gray-700">{noteType.label}</span>
                                    </label>
                                ))}
                            </div>
                            {/* {!codingAssistantNoteType && (
                                <p className="text-red-500 text-sm mt-1">Please select a note type.</p>
                            )} */}
                        </div>

                        {/* Note Content Textarea */}
                        <textarea
                            className="w-full h-48 p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none"
                            placeholder="Paste your note here..."
                            value={codingAssistantNoteContent}
                            onChange={(e) => setCodingAssistantNoteContent(e.target.value)}
                            disabled={isCodingAssistantSubmitting}
                        />
                        {/* {!codingAssistantNoteContent.trim() && (
                            <p className="text-red-500 text-sm mt-1">Note content cannot be empty.</p>
                        )} */}

                        {/* Error Message */}
                        {codingAssistantError && (
                            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
                                {codingAssistantError}
                            </div>
                        )}

                        {/* Submit Button */}
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleSubmitCodingAssistantNote}
                                className={`px-6 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 transition duration-200 disabled:opacity-50 flex items-center ${(!codingAssistantNoteContent.trim() || !codingAssistantNoteType || isCodingAssistantSubmitting) && 'cursor-not-allowed'
                                    }`}
                                disabled={!codingAssistantNoteContent.trim() || !codingAssistantNoteType || isCodingAssistantSubmitting}
                            >
                                {isCodingAssistantSubmitting && (
                                    <svg
                                        className="animate-spin h-5 w-5 mr-2 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373   
                0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                )}
                                {isCodingAssistantSubmitting ? 'Calculating...' : 'Calculate Coding'}
                            </button>
                        </div>

                        {/* Display Coding Results */}
                        {codingAssistantResult && (
                            <div className="mt-6">
                                {/* Display Coding Estimation */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {/* Complexity of Problems Addressed */}
                                    <div className="p-4 bg-white border border-gray-200 rounded-md shadow">
                                        <h4 className="text-lg font-semibold text-gray-700 mb-2">
                                            Complexity of Problems Addressed
                                        </h4>
                                        <ProgressBar value={codingAssistantResult.codingEstimation.copaScore} denominator={3} />
                                    </div>

                                    {/* Amount and/or Complexity of Data */}
                                    <div className="p-4 bg-white border border-gray-200 rounded-md shadow">
                                        <h4 className="text-lg font-semibold text-gray-700 mb-2">
                                            Amount and/or Complexity of Data
                                        </h4>
                                        <ProgressBar value={codingAssistantResult.codingEstimation.cdaScore} denominator={3} />
                                    </div>

                                    {/* Risk of Complications */}
                                    <div className="p-4 bg-white border border-gray-200 rounded-md shadow">
                                        <h4 className="text-lg font-semibold text-gray-700 mb-2">Risk of Complications</h4>
                                        <ProgressBar value={codingAssistantResult.codingEstimation.riskScore} denominator={3} />
                                    </div>

                                    {/* Overall MDM Level */}
                                    {/* <div className="p-4 bg-white border border-gray-200 rounded-md shadow">
                                        <h4 className="text-lg font-semibold text-gray-700 mb-2">Overall MDM Level</h4>
                                        <div className="text-2xl font-bold text-indigo-900">
                                            {codingAssistantResult.codingEstimation.overallMDMLevel}
                                        </div>
                                    </div> */}

                                    {/* Billing Code */}
                                    <div className="p-4 bg-white border border-gray-200 rounded-md shadow flex items-center justify-between">
                                        <h4 className="text-lg font-semibold text-gray-700">Billing Code</h4>
                                        <div className="text-3xl font-bold text-indigo-900">
                                            {codingAssistantResult.codingEstimation.billingCode}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* {showDischargeAlert && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <p className="mb-4">Are you sure you want to discharge and remove {selectedStayForDischarge?.patient_name}?</p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setShowDischargeAlert(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded"
                                disabled={isDischarging}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={dischargeAndRemovePatient}
                                className="px-4 py-2 bg-red-600 text-white rounded"
                                disabled={isDischarging}
                            >
                                {isDischarging ? 'Discharging...' : 'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

            {/* Patient Pool Modal */}
            <PatientPoolModal
                isOpen={isPoolModalOpen}
                onClose={() => setIsPoolModalOpen(false)}
                instance={instance}
                accounts={accounts}
                protectedResources={protectedResources}
                navigate={navigate}
                onPatientClaimed={handlePatientClaimed}
            />
        </div>
    );
}

export default HospitalistNoteDashboard;



