import React, { useState, useMemo } from 'react';
import {
    Box,
    Grid,
    Typography,
    Paper,
    Modal,
    IconButton,
    LinearProgress,
    Tooltip,
    Chip,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Legend,
    Tooltip as RechartsTooltip,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WarningIcon from '@mui/icons-material/Warning';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PerformanceMetricsList from './PerformanceMetricsList';
import TrendsChart from './TrendsChart';
import { mdmMetrics, mdmColors } from '../../config/mdmConfig';
import { alpha } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import ProviderSpotlight from '../ProviderSpotlight';

const riskCategories = [
    {
        id: 'admissionDecisions',
        label: 'Admission Decisions',
        description: 'Decisions regarding patient admission and level of care',
        metrics: {
            total: 425,
            distribution: {
                directAdmit: 45,
                icu: 25,
                observation: 20,
                transfer: 10
            },
            trend: '+6%'
        }
    },
    {
        id: 'drugTherapy',
        label: 'Drug Therapy Monitoring',
        description: 'Cases requiring intensive monitoring for toxicity',
        metrics: {
            total: 245,
            distribution: {
                anticoagulation: 35,
                chemotherapy: 25,
                immunosuppression: 20,
                antipsychotics: 20
            },
            trend: '+8%'
        }
    },
    {
        id: 'highRiskProcedures',
        label: 'High-Risk Procedures',
        description: 'Invasive procedures with significant risk factors',
        metrics: {
            total: 320,
            distribution: {
                arterialCath: 30,
                endotrachealTube: 25,
                biPAP: 20,
                centralLine: 25
            },
            trend: '+4%'
        }
    },
    {
        id: 'patientRiskFactors',
        label: 'Patient Risk Factors',
        description: 'Significant comorbidities affecting care decisions',
        metrics: {
            total: 380,
            distribution: {
                aids: 15,
                alcoholism: 25,
                chronicRenalFailure: 35,
                cirrhosis: 25
            },
            trend: '+10%'
        }
    },
    {
        id: 'procedureRiskFactors',
        label: 'Procedure Risk Factors',
        description: 'Factors increasing procedure complexity',
        metrics: {
            total: 290,
            distribution: {
                coagulopathy: 30,
                anatomicComplexity: 25,
                multipleComorbidities: 25,
                emergentTiming: 20
            },
            trend: '+7%'
        }
    },
    {
        id: 'surgicalDecisions',
        label: 'Surgical Decision Making',
        description: 'Major surgery decisions with risk factors',
        metrics: {
            total: 180,
            distribution: {
                elective: 45,
                emergency: 35,
                complicationRisk: 20
            },
            trend: '-3%'
        }
    }
];

const COLORS = [
    mdmColors.minimal,    // Slate 400
    mdmColors.low,       // Blue 400
    mdmColors.moderate,  // Blue 600
    mdmColors.high,      // Blue 800
    mdmColors.extensive  // Blue 800 (same as high)
];

const generatePerformanceData = (mean) => {
    // Generate a random value that deviates from the mean by up to ±15%
    const deviation = (Math.random() * 30 - 15);
    return Math.min(Math.max(mean + deviation, 0), 100); // Clamp between 0 and 100
};

const RiskComplexityView = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [spotlightOpen, setSpotlightOpen] = useState(false);
    const [spotlightMetric, setSpotlightMetric] = useState(null);
    const [spotlightSubMetric, setSpotlightSubMetric] = useState(null);
    const [performanceValue, setPerformanceValue] = useState(null);

    const handleSpotlightOpen = (category, subMetric = null) => {
        // If no subMetric is provided, use the category's total value
        const meanValue = subMetric 
            ? category.metrics.distribution[subMetric]
            : Object.values(category.metrics.distribution).reduce((a, b) => a + b, 0) / Object.keys(category.metrics.distribution).length;
            
        const randomPerformance = generatePerformanceData(meanValue);
        setSpotlightMetric({
            ...category,
            id: category.id
        });
        setSpotlightSubMetric(subMetric);
        setPerformanceValue(Math.round(randomPerformance));
        setSpotlightOpen(true);
    };

    const aggregateMetrics = useMemo(() => {
        return {
            totalCases: riskCategories.reduce((acc, cat) => acc + cat.metrics.total, 0),
            averageTrend: riskCategories.reduce((acc, cat) => {
                const trend = parseFloat(cat.metrics.trend);
                return acc + trend;
            }, 0) / riskCategories.length,
            categoryDistribution: riskCategories.map(cat => ({
                name: cat.label,
                value: cat.metrics.total
            }))
        };
    }, []);

    return (
        <Box>
            <Grid container spacing={3}>
                {/* Summary Statistics */}
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            background: (theme) => `linear-gradient(to right, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.main, 0.06)})`,
                            borderRadius: 2,
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h3" sx={{ color: 'primary.main', fontWeight: 600 }}>
                                        {aggregateMetrics.totalCases}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        High-Risk Cases Reviewed
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h3" sx={{ 
                                        color: aggregateMetrics.averageTrend > 0 ? 'success.main' : 'error.main',
                                        fontWeight: 600 
                                    }}>
                                        {aggregateMetrics.averageTrend.toFixed(1)}%
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        Average Risk Trend
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h3" sx={{ color: 'primary.main', fontWeight: 600 }}>
                                        {riskCategories.length}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        Risk Categories Monitored
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Risk Categories Distribution */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            background: 'white',
                            borderRadius: 2,
                            height: '400px'
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Risk Category Distribution
                        </Typography>
                        <ResponsiveContainer width="100%" height="90%">
                            <PieChart>
                                <Pie
                                    data={aggregateMetrics.categoryDistribution}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {aggregateMetrics.categoryDistribution.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <RechartsTooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* Category Details */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            background: 'white',
                            borderRadius: 2,
                            height: '400px',
                            overflow: 'auto'
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Risk Category Details
                        </Typography>
                        {riskCategories.map((category) => (
                            <Box
                                key={category.id}
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    borderRadius: 2,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05),
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                            {category.label}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleSpotlightOpen(category)}
                                            sx={{
                                                p: 0.5,
                                                color: 'primary.main',
                                                '&:hover': {
                                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                }
                                            }}
                                        >
                                            <GroupsIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    <Chip
                                        label={category.metrics.trend}
                                        color={parseFloat(category.metrics.trend) > 0 ? 'success' : 'error'}
                                        size="small"
                                    />
                                </Box>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                    {category.description}
                                </Typography>
                                <Typography variant="body2">
                                    Total Cases: {category.metrics.total}
                                </Typography>
                            </Box>
                        ))}
                    </Paper>
                </Grid>

                {/* Detailed Metrics */}
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            background: 'white',
                            borderRadius: 2,
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Risk Metrics Breakdown
                        </Typography>
                        <Grid container spacing={2}>
                            {riskCategories.map((category) => (
                                <Grid item xs={12} md={6} lg={4} key={category.id}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            border: '1px solid',
                                            borderColor: 'divider',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                            <Typography variant="subtitle1">
                                                {category.label}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleSpotlightOpen(category)}
                                                sx={{
                                                    p: 0.5,
                                                    color: 'primary.main',
                                                    '&:hover': {
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                    }
                                                }}
                                            >
                                                <GroupsIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        {Object.entries(category.metrics.distribution).map(([key, value]) => (
                                            <Box key={key} sx={{ mb: 1 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Typography variant="body2">
                                                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                                                        </Typography>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleSpotlightOpen(category, key)}
                                                            sx={{
                                                                p: 0.5,
                                                                color: 'primary.main',
                                                                '&:hover': {
                                                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                                }
                                                            }}
                                                        >
                                                            <GroupsIcon fontSize="small" />
                                                        </IconButton>
                                                    </Box>
                                                    <Typography variant="body2" color="primary">
                                                        {value}%
                                                    </Typography>
                                                </Box>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={value}
                                                    sx={{
                                                        height: 4,
                                                        borderRadius: 2,
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                        '& .MuiLinearProgress-bar': {
                                                            background: (theme) => `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.8)})`,
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {/* Provider Spotlight Modal */}
            <ProviderSpotlight
                open={spotlightOpen}
                onClose={() => {
                    setSpotlightOpen(false);
                    setSpotlightMetric(null);
                    setSpotlightSubMetric(null);
                    setPerformanceValue(null);
                }}
                criteriaType={spotlightMetric?.label}
                criteriaName={spotlightSubMetric 
                    ? spotlightSubMetric.charAt(0).toUpperCase() + spotlightSubMetric.slice(1).replace(/([A-Z])/g, ' $1') 
                    : spotlightMetric?.label}
                groupPerformance={performanceValue}
                groupTrend={spotlightMetric ? spotlightMetric.metrics.trend : null}
            />
        </Box>
    );
};

export default RiskComplexityView; 