import { callApiWithToken } from './apiUtils';
import { protectedResources } from '../authConfig';

export const documentApi = {
    deleteDocument: async (instance, accounts, documentId) => {
        return callApiWithToken(
            instance,
            accounts,
            `${protectedResources.apiDeleteDocument.endpoint}`,
            'DELETE',
            { document_id: documentId },
            protectedResources.apiDeleteDocument.scopes
        );
    }
}; 