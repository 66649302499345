// apiUtils.jsx

export const callApiWithToken = async (instance, accounts, endpoint, method, body = null, scopes = []) => {
    try {
        if (!accounts || accounts.length === 0) {
            throw new Error('No accounts found');
        }

        const request = {
            scopes: scopes,
            account: accounts[0]
        };

        const response = await instance.acquireTokenSilent(request);
        const token = response.accessToken;

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const options = {
            method: method,
            headers: headers
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const fetchResponse = await fetch(endpoint, options);

        if (!fetchResponse.ok) {
            const errorText = await fetchResponse.text();
            throw new Error(`HTTP error! status: ${fetchResponse.status}, message: ${errorText}`);
        }

        const data = await fetchResponse.json();
        return data;
    } catch (error) {
        console.error('callApiWithToken error:', error);
        throw error;
    }
};  