import React, { useState } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
} from '@mui/material';
import DataComplexityView from './mdm-criteria/DataComplexityView';
import ProblemsComplexityView from './mdm-criteria/ProblemsComplexityView';
import RiskComplexityView from './mdm-criteria/RiskComplexityView';

const MDMCriteriaView = () => {
    const [selectedTab, setSelectedTab] = useState(1);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    sx={{
                        '& .MuiTab-root': {
                            fontSize: '0.95rem',
                            fontWeight: 500,
                            textTransform: 'none',
                            minHeight: 48,
                        },
                        '& .Mui-selected': {
                            color: 'primary.main',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'primary.main',
                            height: 3,
                        },
                    }}
                >
                    <Tab label="Complexity of Problems" />
                    <Tab label="Complexity of Data" />
                    <Tab label="Risk of Complications" />
                </Tabs>
            </Box>

            {selectedTab === 0 && <ProblemsComplexityView />}
            {selectedTab === 1 && <DataComplexityView />}
            {selectedTab === 2 && <RiskComplexityView />}
        </Box>
    );
};

export default MDMCriteriaView; 