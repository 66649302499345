import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Switch } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../../authConfig';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { UserGroupIcon, BuildingOfficeIcon, UserIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export default function PatientExperienceContent({ orgId }) {

    const [actorsData, setActorsData] = useState([]);
    const [selectedSpecialties, setSelectedSpecialties] = useState([
        'EMERGENCY_PHYSICIAN',
        'ADVANCED_PRACTICE_PROVIDER',
    ]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredActors, setFilteredActors] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedActor, setSelectedActor] = useState(null);

    const { instance, accounts } = useMsal();

    // Function to fetch patient experience data from the backend  
    const fetchPatientExperienceData = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiGetPatientExperienceData.scopes,
                account: accounts[0],
            };

            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                const token = tokenResponse.accessToken;

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ org_id: orgId }),
                };

                const endpoint = protectedResources.apiGetPatientExperienceData.endpoint;

                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                setActorsData(data.actors);

                // Extract unique divisions from actorsData  
                const uniqueDivisions = Array.from(
                    new Set(data.actors.map((actor) => actor.division))
                )
                    .filter((division) => division !== null && division !== undefined)
                    .map((division) => ({ id: division, name: division }));

                setDivisions(uniqueDivisions);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching patient experience data:', error);
                setIsLoading(false);
            }
        }
    }, [accounts, instance, orgId]);

    useEffect(() => {
        fetchPatientExperienceData();
    }, [fetchPatientExperienceData]);

    // Filter actors based on specialties, divisions, and searchTerm  
    useEffect(() => {
        let filtered = actorsData;

        if (selectedSpecialties.length > 0) {
            filtered = filtered.filter((actor) =>
                selectedSpecialties.includes(actor.actor_specialty)
            );
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (actor) =>
                    actor.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (actor.email &&
                        actor.email.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setSelectedDivisions([]); // Clear selected divisions when searching  
        } else if (selectedDivisions.length > 0) {
            filtered = filtered.filter((actor) =>
                selectedDivisions.includes(actor.division)
            );
        }

        setFilteredActors(filtered);
    }, [actorsData, selectedSpecialties, selectedDivisions, searchTerm]);

    // Handlers for toggling specialties and divisions  
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            setSelectedDivisions([]);
        }
    };

    const handleDivisionToggle = (division) => {
        setSelectedDivisions((prevSelected) => {
            if (prevSelected.includes(division.id)) {
                // Deselect the division if it's already selected  
                return [];
            } else {
                // Select only the clicked division  
                return [division.id];
            }
        });
        setSearchTerm('');
        setSelectedActor(null);
    };

    const handleSpecialtyToggle = (specialty) => {
        setSelectedSpecialties((prevSelected) => {
            if (prevSelected.includes(specialty.id)) {
                return prevSelected.filter((s) => s !== specialty.id);
            } else {
                return [...prevSelected, specialty.id];
            }
        });
        setSearchTerm('');
        setSelectedActor(null);
    };

    const specialties = [
        { id: 'EMERGENCY_PHYSICIAN', name: 'Physician' },
        { id: 'ADVANCED_PRACTICE_PROVIDER', name: 'APP' },
    ];

    // Function to handle CSV export  
    const handleExportCSV = () => {
        const csvRows = [];
        const headers = [
            'Provider Name',
            'Number of Documents',
            'AIDET Score',
            'Patient Experience Score',
            'Average Acknowledged Patient',
            'Average Introduced Themselves',
            'Average Provided Duration',
            'Average Provided Explanation',
            'Average Thanked Patient',
            'Average Asked Open Ended Questions',
            'Average Asked Patient If Questions',
            'Average Asked How Else Can Be Helped',
            'Average Asked Patient Opinion',
            'Average Managed Up Care Team',
            'Average Used Whiteboard',
            'Average Reflectively Listened',
        ];
        csvRows.push(headers.join(','));

        const escapeCSV = (field) => {
            if (field === null || field === undefined) return '""';
            const stringField = String(field);
            // If the field contains commas, quotes, or newlines, wrap it in quotes and escape any existing quotes
            if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
                return `"${stringField.replace(/"/g, '""')}"`;
            }
            return stringField;
        };

        filteredActors.forEach((actor) => {
            const values = [
                escapeCSV(actor.full_name),
                actor.document_count,
                actor.aidet_score.toFixed(2),
                actor.patient_experience_score.toFixed(2),
                actor.average_acknowledged_patient.toFixed(2),
                actor.average_introduced_themselves.toFixed(2),
                actor.average_provided_duration.toFixed(2),
                actor.average_provided_explanation.toFixed(2),
                actor.average_thanked_patient.toFixed(2),
                actor.average_asked_open_ended_questions.toFixed(2),
                actor.average_asked_patient_if_questions.toFixed(2),
                actor.average_asked_how_else_can_be_helped.toFixed(2),
                actor.average_asked_patient_opinion.toFixed(2),
                actor.average_managed_up_care_team.toFixed(2),
                actor.average_used_whiteboard.toFixed(2),
                actor.average_reflectively_listened.toFixed(2),
            ];
            csvRows.push(values.join(','));
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'provider_patient_experience.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex flex-col w-full bg-gray-100">
            <div className="bg-white">
                <div className="flex justify-between items-center px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                        Patient Experience Dashboard
                    </h1>
                    <button
                        className="px-4 py-2 text-white bg-indigo-600 rounded-md"
                        onClick={handleExportCSV}
                    >
                        Export CSV
                    </button>
                </div>
            </div>
            <div className="px-4 pb-2 bg-white border-b border-gray-200">
                <div className="w-full max-w-md lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <MagnifyingGlassIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            id="search"
                            name="search"
                            className="block py-2 pr-3 pl-10 w-full leading-5 placeholder-gray-500 bg-white rounded-md border border-gray-300 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Search for providers"
                            type="search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="flex overflow-hidden flex-1">
                {/* Specialties column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Specialties
                    </h2>
                    <ul className="divide-y divide-gray-200">
                        {specialties.map((specialty) => (
                            <li
                                key={specialty.id}
                                className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                onClick={() => handleSpecialtyToggle(specialty)}
                            >
                                <div className="flex items-center">
                                    <Switch
                                        checked={selectedSpecialties.includes(specialty.id)}
                                        onChange={() => handleSpecialtyToggle(specialty)}
                                        onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li  
                                        className={`${selectedSpecialties.includes(specialty.id)
                                            ? 'bg-indigo-600'
                                            : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedSpecialties.includes(specialty.id)
                                                ? 'translate-x-5'
                                                : 'translate-x-0'
                                                } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <span className="ml-2">{specialty.name}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Divisions column */}
                <div className="overflow-y-auto w-64 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Divisions
                    </h2>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : divisions.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {divisions.map((division) => (
                                <li
                                    key={division.id}
                                    className={`p-4 text-gray-700 cursor-pointer hover:bg-gray-50`}
                                    onClick={() => handleDivisionToggle(division)}
                                >
                                    <div className="flex items-center">
                                        <Switch
                                            checked={selectedDivisions.includes(division.id)}
                                            onChange={() => handleDivisionToggle(division)}
                                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the li  
                                            className={`${selectedDivisions.includes(division.id)
                                                ? 'bg-indigo-600'
                                                : 'bg-gray-200'
                                                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${selectedDivisions.includes(division.id)
                                                    ? 'translate-x-5'
                                                    : 'translate-x-0'
                                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                            />
                                        </Switch>
                                        <BuildingOfficeIcon
                                            className="ml-2 w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-2">{division.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <BuildingOfficeIcon
                                className="mb-4 w-12 h-12 text-gray-400"
                                aria-hidden="true"
                            />
                            <p>No divisions found</p>
                        </div>
                    )}
                </div>

                {/* Providers column */}
                <div className="overflow-y-auto w-80 bg-white border-r border-gray-200">
                    <h2 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                        Providers
                    </h2>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
                        </div>
                    ) : filteredActors.length > 0 ? (
                        <ul className="divide-y divide-gray-200">
                            {filteredActors.map((actor) => (
                                <li
                                    key={actor.actor_id}
                                    className={`flex items-center justify-between gap-x-6 p-4 cursor-pointer hover:bg-gray-50 ${selectedActor?.actor_id === actor.actor_id
                                        ? 'bg-indigo-50 text-indigo-600'
                                        : 'text-gray-700'
                                        }`}
                                    onClick={() => setSelectedActor(actor)}
                                >
                                    <div className="flex-grow min-w-0">
                                        <div className="flex gap-x-3 items-start">
                                            <UserIcon
                                                className="w-5 h-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <p className="text-sm font-semibold leading-6">
                                                {actor.full_name}
                                            </p>
                                        </div>
                                        <div className="flex gap-x-2 items-center mt-1 text-xs leading-5 text-gray-500">
                                            <p className="truncate">{actor.email}</p>
                                        </div>
                                    </div>
                                    <ChevronRightIcon
                                        className={'w-5 h-5 text-gray-400'}
                                        aria-hidden="true"
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col justify-center items-center h-64 text-gray-500">
                            <UserGroupIcon
                                className="mb-4 w-12 h-12 text-gray-400"
                                aria-hidden="true"
                            />
                            <p>No providers found</p>
                        </div>
                    )}
                </div>

                {/* Details column */}
                {selectedActor ? (
                    <PatientExperienceDetailsColumn
                        selectedEntity={selectedActor}
                        entityType={'user'}
                    />
                ) : (
                    <div className="overflow-y-auto flex-1 bg-white">
                        <div className="flex justify-center items-center h-full">
                            <p className="text-gray-500">Select a provider to view details</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function PatientExperienceDetailsColumn({ selectedEntity, entityType }) {
    if (!selectedEntity) {
        return (
            <div className="overflow-y-auto flex-1 bg-white">
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">Select a provider to view details</p>
                </div>
            </div>
        );
    }

    const {
        full_name,
        document_count,
        aidet_score,
        patient_experience_score,
        average_acknowledged_patient,
        average_introduced_themselves,
        average_provided_duration,
        average_provided_explanation,
        average_thanked_patient,
        average_asked_open_ended_questions,
        average_asked_patient_if_questions,
        average_asked_how_else_can_be_helped,
        average_asked_patient_opinion,
        average_managed_up_care_team,
        average_used_whiteboard,
        average_reflectively_listened,
    } = selectedEntity;

    return (
        <div className="overflow-y-auto flex-1 bg-white">
            <h1 className="p-4 text-lg font-semibold text-gray-700 border-b border-gray-200">
                {full_name} Patient Experience Analysis
            </h1>
            <div className="p-4">
                <div className="my-8 grid grid-cols-2 gap-8">
                    {/* AIDET Score */}
                    <div className="text-center">
                        <h2 className="mb-2 text-2xl font-semibold text-gray-700">AIDET Score</h2>
                        <div className="text-6xl font-bold text-indigo-600">
                            {aidet_score.toFixed(2)}
                        </div>
                        <p className="mt-2 text-gray-500">Communication Framework</p>
                    </div>

                    {/* Patient Satisfaction Score */}
                    <div className="text-center">
                        <h2 className="mb-2 text-2xl font-semibold text-gray-700">Patient Satisfaction Score</h2>
                        <div className="text-6xl font-bold text-green-500">
                            {patient_experience_score.toFixed(2)}
                        </div>
                        <p className="mt-2 text-gray-500">Based on {document_count} documents</p>
                    </div>
                </div>

                <h3 className="py-4 mt-8 mb-2 text-xl font-semibold text-gray-700">
                    Patient Experience Score Breakdown
                </h3>

                <dl className="grid grid-cols-2 gap-x-4 gap-y-8 pt-8 mb-8 border-t border-gray-300 sm:grid-cols-3 md:grid-cols-4">
                    {[
                        {
                            label: "Acknowledged Patient",
                            value: `${(average_acknowledged_patient * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Introduced Themselves",
                            value: `${(average_introduced_themselves * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Provided Duration",
                            value: `${(average_provided_duration * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Provided Explanation",
                            value: `${(average_provided_explanation * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Thanked Patient",
                            value: `${(average_thanked_patient * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked Open Ended Questions",
                            value: `${(average_asked_open_ended_questions * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked If Questions",
                            value: `${(average_asked_patient_if_questions * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked How Else Can Be Helped",
                            value: `${(average_asked_how_else_can_be_helped * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Asked Patient Opinion",
                            value: `${(average_asked_patient_opinion * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Managed Up Care Team",
                            value: `${(average_managed_up_care_team * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Used Whiteboard",
                            value: `${(average_used_whiteboard * 100).toFixed(0)}%`,
                        },
                        {
                            label: "Reflectively Listened",
                            value: `${(average_reflectively_listened * 100).toFixed(0)}%`,
                        },
                    ].map((item) => (
                        <div
                            key={item.label}
                            className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm transition-shadow duration-200 hover:shadow-md"
                        >
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.label}</dt>
                            <dd className="mt-1 text-2xl font-semibold text-indigo-600">{item.value}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
}
