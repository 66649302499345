import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const generateTrendData = (selectedMetrics, metricsData) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
    return months.map(month => {
        const dataPoint = { month };
        selectedMetrics.forEach(metricId => {
            const target = metricsData[metricId].target;
            // Generate a value that fluctuates around the target
            dataPoint[metricId] = Math.floor(Math.random() * 20 + (target - 10));
        });
        return dataPoint;
    });
};

const TrendsChart = ({ selectedMetrics, metricsData, dataCriteria, height = 400, showLegend = false }) => {
    const data = generateTrendData(selectedMetrics, metricsData);
    const colors = [
        '#2563EB', // Blue
        '#10B981', // Green
        '#6366F1', // Indigo
        '#F59E0B', // Amber
        '#EC4899', // Pink
        '#8B5CF6', // Purple
    ];

    return (
        <Box sx={{ width: '100%', height }}>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
                    <XAxis 
                        dataKey="month" 
                        tick={{ fill: '#666' }}
                        axisLine={{ stroke: '#ccc' }}
                        tickLine={{ stroke: '#ccc' }}
                    />
                    <YAxis 
                        domain={[0, 100]}
                        tick={{ fill: '#666' }}
                        axisLine={{ stroke: '#ccc' }}
                        tickLine={{ stroke: '#ccc' }}
                        width={40}
                    />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        }}
                        labelStyle={{ fontWeight: 'bold', marginBottom: '4px' }}
                    />
                    {showLegend && (
                        <Legend
                            verticalAlign="top"
                            height={36}
                            content={({ payload }) => (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    gap: 3,
                                    flexWrap: 'wrap',
                                    mb: 2
                                }}>
                                    {payload.map((entry, index) => (
                                        <Box 
                                            key={entry.value}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: '50%',
                                                    bgcolor: entry.color,
                                                }}
                                            />
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    color: 'text.secondary',
                                                    fontSize: '0.875rem',
                                                }}
                                            >
                                                {dataCriteria.find(c => c.id === entry.value)?.label}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        />
                    )}
                    {selectedMetrics.map((metricId, index) => (
                        <Line
                            key={metricId}
                            type="monotone"
                            dataKey={metricId}
                            stroke={colors[index % colors.length]}
                            strokeWidth={2}
                            dot={{ r: 4, strokeWidth: 2 }}
                            activeDot={{ r: 6, strokeWidth: 2 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default TrendsChart; 