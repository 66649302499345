import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import CodeMirrorEditor from '../components/CodeMirrorEditor';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave, faEdit, faChartLine, faCopy, faCheck,
    faArrowCircleLeft, faQuestionCircle, faFileCode,
    faCalculator, faChevronLeft, faChevronRight,
    faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faSearch, faShield, faSync, faArchive, faUndo, faChevronUp, faChevronDown, faSpinner, faMicrophone, faThumbsUp,
    faMeh, faXmark, faCheckCircle,
    faThumbsDown, faUserCheck, faListCheck, faRotate, faTrash, faEllipsisVertical
} from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text, Strong } from '../components/catalyst/text';
//import { Textarea } from '../components/catalyst/textarea';
import { Button } from '../components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import '../styles/ViewReports.css';

import {
    CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationCircleIcon,
    ShieldCheckIcon, InformationCircleIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon,
    CalculatorIcon
} from '@heroicons/react/20/solid';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import TextareaAutosize from 'react-textarea-autosize';
import { PlusIcon } from '@heroicons/react/20/solid';

import ProgressBar from '../components/PatientViewer/ProgressBars';

import recordingManager from '../contexts/RecordingManager';
import TipTapEditor from '../components/TipTap';
import '../styles/Editor.css';

import { htmlToText } from 'html-to-text';
import PlainTextEditor from '../components/PlainTextEditor';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import PatientExperienceModal from '../components/PatientExperienceModal';
import SupplementalDocumentsModal from '../components/SupplementalDocumentsModal';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { documentApi } from '../api/documentApi';




function PatientInfoCarepoint() {
    const [hpi, setHPI] = useState('');
    const [ros, setROS] = useState('');
    const [exam, setExam] = useState('');
    const [reeval, setReeval] = useState('');
    const [procedures, setProcedures] = useState('');


    const [mdm, setMDM] = useState('');
    const [riskAnalysis, setRiskAnalysis] = useState('');
    const [copa, setCopa] = useState(-1);
    const [complexityOfData, setComplexityOfData] = useState(-1);
    const [riskOfComplications, setRiskOfComplications] = useState(-1);
    const [riskScoreLabel, setRiskScoreLabel] = useState('');
    // const riskScores = ["HEART", "Wells", "CURB-65", "GCS", "PERC Rule"];
    const [currentRiskScoreKey, setCurrentRiskScoreKey] = useState("");
    const [showRiskScoreDropdown, setShowRiskScoreDropdown] = useState(false);
    const [showMacroDropdown, setShowMacroDropdown] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Chart...');
    const [patientName, setPatientName] = useState('');

    const [procedureDescription, setProcedureDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMDM, setIsLoadingMDM] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const [copiedHPI, setCopiedHPI] = useState(false);
    const [copiedROS, setCopiedROS] = useState(false);
    const [copiedExam, setCopiedExam] = useState(false);
    const [copiedReeval, setCopiedReeval] = useState(false);
    const [copiedProcedures, setCopiedProcedures] = useState(false);
    const [copiedMDM, setCopiedMDM] = useState(false);
    const [copiedAll, setCopiedAll] = useState(false);
    const [isEditingPatientName, setIsEditingPatientName] = useState(false);
    const [isEditingProcedureDescription, setIsEditingProcedureDescription] = useState(false);
    const [riskScoreNotificationVisible, setRiskScoreNotificationVisible] = useState(false);
    const [mdmCodingNotificationVisible, setMDMCodingNotificationVisible] = useState(false);
    const location = useLocation();
    const prevPathname = useRef(location.pathname);
    const chart = location.state?.document;
    const providedDate = location.state?.providedDate;
    const showMDM = location.state?.showMDM;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(chart);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [riskScore, setRiskScore] = useState(false);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [macro_list, setMacroList] = useState({});
    const riskScoreDropdownRef = useRef(null);
    const macroDropdownRef = useRef(null);
    const [isShowingMDM, setIsShowingMDM] = useState(showMDM || false);
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(chart?.note_rating === null ? true : false);
    const [noteRating, setNoteRating] = useState(null);
    const [noteFeedback, setNoteFeedback] = useState('');
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [isCPTFeedbackSubmitted, setIsCPTFeedbackSubmitted] = useState(false);
    const [isCPTFeedbackVisible, setIsCPTFeedbackVisible] = useState(true);
    const [CPTFeedback, setCPTFeedback] = useState('');
    const [hasProvidedRating, setHasProvidedRating] = useState(false);
    const [macroSearchQuery, setMacroSearchQuery] = useState('');
    const [originalMDM, setOriginalMDM] = useState(mdm);
    const [cleanedMDM, setCleanedMDM] = useState(mdm);
    const [isRemoved, setIsRemoved] = useState(false);
    const [auditChartMessages, setAuditChartMessages] = useState([]);
    const [hasAuditChartMessages, setHasAuditChartMessages] = useState(false);
    const [isShowingAuditChartMessages, setIsShowingAuditChartMessages] = useState(false);
    const [currentAuditChartMessageIndex, setCurrentAuditChartMessageIndex] = useState(0);
    const [documentStatus, setDocumentStatus] = useState(chart?.document_status === undefined ? 'PRE_ENCOUNTER' : chart.document_status);
    const [mdmStatus, setMDMStatus] = useState(chart?.mdm_status === undefined ? 'NOT_GENERATED' : chart.mdm_status);

    const [acknowledgedPatient, setAcknowledgedPatient] = useState(null);
    const [introducedThemselves, setIntroducedThemselves] = useState(null);
    const [providedDuration, setProvidedDuration] = useState(null);
    const [providedExplanation, setProvidedExplanation] = useState(null);
    const [managedUpCareTeam, setManagedUpCareTeam] = useState(null);

    const criteria = [
        acknowledgedPatient,
        introducedThemselves,
        providedDuration,
        providedExplanation,
        managedUpCareTeam
    ];

    const hasNullCriteria = criteria.some(criterion => criterion === null);
    const totalCriteria = criteria.length;
    const completedCriteria = criteria.filter(Boolean).length;
    const completionPercentage = (completedCriteria / totalCriteria) * 100;
    const [isPatientExperienceModalOpen, setIsPatientExperienceModalOpen] = useState(false);

    const [riskMessages, setRiskMessages] = useState([]);
    const [hasRiskMessages, setHasRiskMessages] = useState(false);
    const [isShowingRiskMessages, setIsShowingRiskMessages] = useState(false);
    const [currentRiskMessageIndex, setCurrentRiskMessageIndex] = useState(0);
    const [resolvingRiskItem, setResolvingRiskItem] = useState(null);
    const [resolvedRiskItem, setResolvedRiskItem] = useState(null);

    const [riskScores, setRiskScores] = useState([]);
    const [currentRiskScoreId, setCurrentRiskScoreId] = useState(null);
    const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);

    const [isCPTCodesVisible, setIsCPTCodesVisible] = useState(false);
    const [cptCodes, setCptCodes] = useState('');
    const [isGeneratingCPTCodes, setIsGeneratingCPTCodes] = useState(false);
    const [parsedCPTProceduralCodes, setParsedCPTProceduralCodes] = useState([]);
    const [parsedCPTEMCodes, setParsedCPTEMCodes] = useState([]);

    const isMDMRecodeEnabled = useFeatureFlagEnabled('mdm_recode');
    const isBetaPosthog = useFeatureFlagEnabled('beta_user');
    const isCarepointPosthog = useFeatureFlagEnabled('carepoint');
    const isCustomMDMExempt = useFeatureFlagEnabled('custom_mdm_exempt');
    const isMIPSModeEnabled = useFeatureFlagEnabled('mips_enabled');
    const isPatientExperienceEnabled = useFeatureFlagEnabled('patient_experience');
    const isPatientExperienceEnabledWebApp = useFeatureFlagEnabled('patient_experience_web_app');
    const isProceduresEnabled = useFeatureFlagEnabled('procedures');
    const isCPTCodesEnabled = useFeatureFlagEnabled('cpt_codes');
    const isSupplementalDocsEnabled = useFeatureFlagEnabled('supplemental_documents');
    // State variables for macro dropdown visibility
    const [showHpiMacroDropdown, setShowHpiMacroDropdown] = useState(false);
    const [showRosMacroDropdown, setShowRosMacroDropdown] = useState(false);
    const [showExamMacroDropdown, setShowExamMacroDropdown] = useState(false);
    const [showReevalMacroDropdown, setShowReevalMacroDropdown] = useState(false);
    const [showProceduresMacroDropdown, setShowProceduresMacroDropdown] = useState(false);
    const [showMDMMacrosDropdown, setShowMDMMacrosDropdown] = useState(false);

    const hpiMacroDropdownRef = useRef(null);
    const rosMacroDropdownRef = useRef(null);
    const examMacroDropdownRef = useRef(null);
    const reevalMacroDropdownRef = useRef(null);
    const proceduresMacroDropdownRef = useRef(null);
    const mdmMacrosDropdownRef = useRef(null);

    const [showForceResolveModal, setShowForceResolveModal] = useState(false);

    const actorInfo = location.state?.actorInfo || {};

    const [isRecording, setIsRecording] = useState({
        hpi: false,
        ros: false,
        mdm: false,
        physical_exam: false,
        reevaluation: false,
        procedures: false
    });

    const [currentRecordingSection, setCurrentRecordingSection] = useState(null);

    const [sectionLoadingStates, setSectionLoadingStates] = useState({
        hpi: false,
        ros: false,
        physical_exam: false,
        reevaluation: false,
        mdm: false,
        procedures: false
    });

    const [isSupplementalDocsModalOpen, setIsSupplementalDocsModalOpen] = useState(false);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    // Add a new state for delete loading
    const [isDeleting, setIsDeleting] = useState(false);

    const [showActionsMenu, setShowActionsMenu] = useState(false);

    const handleDeleteClick = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsDeleting(true);  // Start loading
        try {
            await documentApi.deleteDocument(instance, accounts, chart.document_id);
            setIsDeleteDialogOpen(false);
            navigate('/er-dashboard', { state: { providedDate: providedDate } });
        } catch (error) {
            console.error('Error deleting document:', error);
            handleTokenExpiration(error);
        } finally {
            setIsDeleting(false);  // Stop loading regardless of outcome
        }
    };

    const handleDeleteCancel = () => {
        setIsDeleteDialogOpen(false);
    };

    const startRecording = (section) => {

        const startTime = Date.now();
        const onTranscriptionComplete = async (section, transcription) => {

            const endTime = Date.now();
            const transcriptionTime = Math.floor((endTime - startTime) / 1000);
            await sendSmartChartTranscription(chart.document_id, section, transcription, transcriptionTime);
        };

        recordingManager.startRecording(
            section,
            process.env.REACT_APP_AZURE_SPEECH_KEY,
            process.env.REACT_APP_AZURE_SPEECH_REGION,
            onTranscriptionComplete
        );

        // Update local state to reflect recording status if needed
        setIsRecording((prevState) => ({ ...prevState, [section]: true }));
        setCurrentRecordingSection(section);
    };

    const stopRecording = (section) => {
        recordingManager.stopRecording(section);

        // Update local state to reflect recording status if needed
        setIsRecording((prevState) => ({ ...prevState, [section]: false }));
        setCurrentRecordingSection(null);
    };

    const sendSmartChartTranscription = async (documentId, section, transcriptionToSend, transcriptionTime) => {
        if (!transcriptionToSend) {
            console.warn('No transcription to send.');
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
            return;
        }

        setSectionLoadingStates((prevState) => ({ ...prevState, [section]: true }));

        const timeZoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const payload = {
            document_id: documentId,
            section_name: section,
            transcript: transcriptionToSend,
            transcription_time: transcriptionTime,
            time_zone: timeZoneIdentifier
        };

        try {
            const request = {
                scopes: protectedResources.apiSendSmartEditTranscription.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            };

            const fetchResponse = await fetch(protectedResources.apiSendSmartEditTranscription.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updated_section) {
                updateSection(section, data.updated_section);
            } else {
                console.error('No updated section found in the response');
            }
        } catch (error) {
            console.error('Error sending transcription:', error);
            // Optionally, inform the user of the error
        } finally {
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
        }
    };




    // RISK SCORE NOTIFICATION
    const RenderRiskScoreNotification = ({ riskScoreId }) => {
        const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);
        const componentRefs = useRef([]);

        // Get the selected risk score based on the ID
        const currentRiskScore = riskScores.find(score => score.risk_score_id === riskScoreId);

        useEffect(() => {
            if (currentRiskScore) {
                // Initialize the risk score state with component names and initial null values
                const initialState = currentRiskScore.components.map(component => ({
                    component_name: component.component_name,
                    component_value: null // Set to null initially to indicate no selection
                }));
                setCurrentRiskScoreState(initialState);
            }
        }, [riskScoreId, currentRiskScore]);

        // Handle changes in risk score options
        const handleRiskScoreChange = (componentName, value) => {
            setCurrentRiskScoreState(prevState =>
                prevState.map(item =>
                    item.component_name === componentName ? { ...item, component_value: value } : item
                )
            );

            // Auto-scroll to the next component
            const currentIndex = currentRiskScore.components.findIndex(
                component => component.component_name === componentName
            );

            if (currentIndex !== -1 && currentIndex + 1 < currentRiskScore.components.length) {
                const nextRef = componentRefs.current[currentIndex + 1];
                if (nextRef && nextRef.current) {
                    nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        };

        // Evaluate whether the score is complete and calculate the total score
        const evaluateRiskScore = () => {
            const scoreValues = currentRiskScoreState.map(item => item.component_value);
            const totalScore = scoreValues.reduce((total, current) => (current !== null ? total + current : total), 0);
            const isComplete = currentRiskScoreState.every(item => item.component_value !== null);

            return { totalScore, isComplete };
        };

        // Update the reevaluation summary and close the notification
        const updateReevaluationWithSummary = () => {
            // Calculate total score and completion status
            let totalScore = 0;

            // Generate the inputs summary
            const inputsSummary = currentRiskScore.components.map(component => {
                const selectedValue = currentRiskScoreState.find(
                    item => item.component_name === component.component_name
                )?.component_value;

                const selectedOption = component.options.find(
                    option => option.risk_score_component_option_id === selectedValue
                );
                const optionValue = selectedOption ? selectedOption.option_value : 'Not selected';
                totalScore += optionValue;

                return `${component.component_label} (${optionValue}): ${selectedOption ? selectedOption.option_name : 'Not selected'}`;
            }).join('\n');

            // Include the total score at the top of the summary
            const summary = `${currentRiskScore.score_name} Calculation (Total Score = ${totalScore})\n\n${inputsSummary}`;

            // Update the reevaluation with the new summary
            const newReeval = `${reeval}\n\n${summary}`;

            updateReeval(newReeval);
            setSaveTriggered(true);
            setRiskScoreNotificationVisible(false);
        };




        return (
            currentRiskScore && (
                <Fieldset className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="border border-gray-300 shadow-lg rounded-lg overflow-hidden h-[85vh] w-[95vw] md:h-[75vh] md:w-[900px] flex flex-col bg-white">
                        <div className="flex justify-between items-center bg-indigo-900 text-white py-4 px-6">
                            <div className="font-bold text-xl text-center flex-grow">
                                {currentRiskScore.score_name}
                            </div>
                            <button onClick={() => setRiskScoreNotificationVisible(false)} className="ml-auto">
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="p-4 flex-grow overflow-auto max-h-[calc(100%-8rem)] space-y-4">
                            {currentRiskScore.components.map((component, index) => {
                                // Initialize ref for this component if it doesn't exist
                                if (!componentRefs.current[index]) {
                                    componentRefs.current[index] = React.createRef();
                                }

                                return (
                                    <div
                                        key={component.component_name}
                                        ref={componentRefs.current[index]}
                                        className="w-full bg-gray-100 p-4 rounded-lg flex flex-col"
                                    >
                                        <RenderRiskScoreOptions
                                            label={component.component_label}
                                            options={component.options}
                                            selectedValue={
                                                currentRiskScoreState.find(
                                                    item => item.component_name === component.component_name
                                                )?.component_value
                                            }
                                            onChange={value => handleRiskScoreChange(component.component_name, value)}
                                            className="break-words whitespace-normal w-full"
                                        />
                                    </div>
                                );
                            })}
                            <div className="bg-white p-4 flex flex-col">
                                {evaluateRiskScore().isComplete ? (
                                    <p className="mt-2">Score complete.</p>
                                ) : (
                                    <p className="flex items-center text-yellow-500 mt-2">
                                        <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                                        Complete items above to calculate {currentRiskScore.score_name} score
                                    </p>
                                )}
                                <div className="flex justify-center items-center mt-4">
                                    <button
                                        onClick={updateReevaluationWithSummary}
                                        className={`bg-indigo-900 hover:bg-indigo-900 text-white font-bold py-3 px-4 rounded ${!evaluateRiskScore().isComplete ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={!evaluateRiskScore().isComplete}
                                    >
                                        Copy and Save to Evaluations
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            )
        );


    };


    const RenderRiskScoreOptions = ({ label, options, selectedValue, onChange }) => {
        return (
            <div className="flex justify-between items-center w-full my-4 border-b border-gray-200 pb-3 px-3">
                <div className="text-sm font-semibold flex items-center">
                    {selectedValue === null && (
                        <span className="mr-2 h-3 w-3 bg-red-500 rounded-full inline-block"></span>
                    )}
                    {label}:
                </div>
                <div className="flex flex-col items-end w-[75%] space-y-2">
                    {options.map((option, index) => {
                        let borderRadiusClass = '';
                        if (index === 0) {
                            borderRadiusClass = 'rounded-t-lg';
                        } else if (index === options.length - 1) {
                            borderRadiusClass = 'rounded-b-lg';
                        }

                        return (
                            <button
                                key={option.risk_score_component_option_id}
                                type="button"
                                className={`relative inline-flex justify-between items-center w-full px-5 py-3 text-sm font-medium ${selectedValue === option.risk_score_component_option_id ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800 hover:bg-indigo-50'} shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none transition-colors duration-150 ease-in-out ${borderRadiusClass}`}
                                onClick={() => onChange(option.risk_score_component_option_id)}
                            >
                                <span>{option.option_name}</span>
                                <span>{option.option_value}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    const handleMDMNotificationClose = () => {
        setMDMCodingNotificationVisible(false);
    };

    useEffect(() => {
        if (auditChartMessages.length > 0) {
            setHasAuditChartMessages(true);
            setCurrentAuditChartMessageIndex(0);
        }
    }, [auditChartMessages]);

    useEffect(() => {
        if (riskMessages.length > 0 && riskMessages.some(item => !item.resolved)) {
            setHasRiskMessages(true);
            setCurrentRiskMessageIndex(0); // Reset to 0 when messages are loaded
        }
    }, [riskMessages]);


    const fetchRiskMessages = async () => {
        try {
            const request = {
                scopes: protectedResources.apiFetchRiskMessages.scopes,
                account: accounts[0]
            };


            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;


            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };


            const fetchResponse = await fetch(protectedResources.apiFetchRiskMessages.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setRiskMessages(data.risk_items);


            if (data.risk_items.length > 0 && data.risk_items.some(item => !item.resolved)) {
                setHasRiskMessages(true);


                // Check for any risk messages where auto_show is True and resolved is False
                const hasAutoShowUnresolvedRiskMessages = data.risk_items.some(item =>
                    (item.auto_show || item.force_resolve) && !item.resolved
                );


                if (hasAutoShowUnresolvedRiskMessages) {
                    setIsShowingRiskMessages(true);
                }


                // Existing logic (optional to keep or remove based on your needs)
                /*
                const hasCriticalCareOrSepsisAlert = data.risk_items.some(item =>
                    (item.risk_category === "CRITICAL_CARE" || item.risk_category === "SEPSIS") && !item.resolved
                );
    
                const hasNIHSS = data.risk_items.some(item => item.risk_name.toUpperCase() === "NIHSS" && !item.resolved);
    
                if (hasCriticalCareOrSepsisAlert || hasNIHSS) {
                    setIsShowingRiskMessages(true);
                    setIsShowingAuditChartMessages(false);
                    setMDMCodingNotificationVisible(false);
                }
                */
            } else {
                setHasRiskMessages(false);
            }
        } catch (error) {
            console.error('Error fetching audit chart messages:', error);
        }
    };



    const fetchRiskScores = async () => {
        try {
            const request = {
                scopes: protectedResources.apiFetchRiskScores.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };

            const fetchResponse = await fetch(protectedResources.apiFetchRiskScores.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setRiskScores(data.risk_scores);
        } catch (error) {
            console.error('Error fetching risk scores:', error);
        }
    };




    const renderRiskMessagesContent = () => {
        if (!Array.isArray(riskMessages) || riskMessages.length === 0) return null;


        // Filter out resolved messages
        const filteredMessages = riskMessages.filter(message => !message.resolved);

        if (filteredMessages.length === 0) {
            setHasRiskMessages(false);
            setIsShowingRiskMessages(false);
            return null;
        }


        // Define priority order based on the specified criteria
        const getPriority = (message) => {
            if (message.force_resolve === true) {
                return 1;
            } else if (message.risk_category === "CRITICAL_CARE" || message.risk_category === "SEPSIS") {
                return 2;
            } else if (message.auto_show === true) {
                return 3;
            } else {
                return 4;
            }
        };


        // Sort messages based on priority
        const filteredAndSortedMessages = filteredMessages.sort((a, b) => {
            const aPriority = getPriority(a);
            const bPriority = getPriority(b);


            if (aPriority !== bPriority) {
                return aPriority - bPriority;
            }


            // Secondary sorting criteria (optional)
            // For example, sort by risk_name alphabetically
            return a.risk_name.localeCompare(b.risk_name);
        });


        const currentMessage = filteredAndSortedMessages[currentRiskMessageIndex];


        const nextMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === filteredAndSortedMessages.length - 1 ? 0 : prevIndex + 1
            );
        };


        const prevMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === 0 ? filteredAndSortedMessages.length - 1 : prevIndex - 1
            );
        };


        const getHeaderTitle = (riskCategory) => {
            switch (riskCategory) {
                case "CRITICAL_CARE":
                    return "Critical Care Alert";
                case "SEPSIS":
                    return "Sepsis Alert";
                default:
                    return null;
            }
        };


        const handleActionClick = () => {
            if (currentMessage.linked_macro_id) {
                applyMacroToDocument(currentMessage.risk_item_id, currentMessage.linked_macro_id);
            } else if (currentMessage.linked_risk_score_id) {
                setCurrentRiskScoreId(currentMessage.linked_risk_score_id);
                setRiskScoreNotificationVisible(true);
                setIsShowingRiskMessages(false);
                setIsShowingAuditChartMessages(false);
                setMDMCodingNotificationVisible(false);
                resolveRiskItem(currentMessage.risk_item_id);
            } else {
                // Resolve the message if there's no action to perform
                resolveRiskItem(currentMessage.risk_item_id);
            }
        };


        const renderActionButton = () => {
            if (resolvingRiskItem === currentMessage.risk_item_id) {
                return (
                    <svg className="animate-spin h-5 w-5 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                );
            }
            if (resolvedRiskItem === currentMessage.risk_item_id) {
                return (
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-500">
                        <FontAwesomeIcon icon={faCheck} className="h-6 w-6 text-white" />
                    </div>
                );
            }


            let buttonText;
            if (currentMessage.linked_macro_id) {
                buttonText = currentMessage.risk_category === "CRITICAL_CARE"
                    ? "Apply Critical Care Macro"
                    : currentMessage.risk_category === "SEPSIS"
                        ? "Apply Sepsis Macro"
                        : "Apply Macro";
            } else if (currentMessage.linked_risk_score_id) {
                buttonText = currentMessage.risk_name.toUpperCase() === "HEART"
                    ? "Calculate HEART Score"
                    : currentMessage.risk_name.toUpperCase() === "NIHSS"
                        ? "Calculate NIHSS"
                        : "Calculate Risk Score";
            } else {
                buttonText = "Resolve";
            }


            return (
                <div className="bg-indigo-900 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200">
                    {buttonText}
                </div>
            );
        };


        return (
            <div className="relative w-full h-full max-w-md mx-auto flex flex-col justify-center items-center p-4">
                {filteredAndSortedMessages.length > 1 && (
                    <div className="flex items-center justify-between w-full absolute inset-x-0 top-1/2 transform -translate-y-1/2">
                        <button onClick={prevMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute left-0">
                            <FontAwesomeIcon icon={faChevronLeft} className="h-8 w-8" />
                        </button>
                        <button onClick={nextMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute right-0">
                            <FontAwesomeIcon icon={faChevronRight} className="h-8 w-8" />
                        </button>
                    </div>
                )}

                {/* Header for Force Resolve Messages */}
                {currentMessage.force_resolve && (
                    <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded">
                        Required
                    </div>
                )}

                {/* Risk Category Title */}
                {getHeaderTitle(currentMessage.risk_category) && (
                    <h2 className="text-xl font-bold text-red-500 mb-2">
                        {getHeaderTitle(currentMessage.risk_category)}
                    </h2>
                )}

                {/* Message Content */}
                <p className="text-lg text-center mb-4 px-4">{currentMessage.message}</p>

                {/* Action Button */}
                <button
                    onClick={handleActionClick}
                    className="mb-2 px-4 py-2 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 relative"
                    disabled={resolvingRiskItem === currentMessage.risk_item_id || resolvedRiskItem === currentMessage.risk_item_id}
                >
                    {renderActionButton()}
                </button>
            </div>
        );
    };




    const fetchAuditChartMessages = async () => {
        try {
            const request = {
                scopes: protectedResources.apiFetchAuditChartMessages.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiFetchAuditChartMessages.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setAuditChartMessages(data.messages);
        } catch (error) {
            console.error('Error fetching audit chart messages:', error);
        }
    };

    const renderAuditChartMessagesContent = () => {
        if (!Array.isArray(auditChartMessages) || auditChartMessages.length === 0) return null;

        const currentMessage = auditChartMessages[currentAuditChartMessageIndex];

        const nextMessage = () => {
            setCurrentAuditChartMessageIndex((prevIndex) =>
                prevIndex === auditChartMessages.length - 1 ? 0 : prevIndex + 1
            );
        };

        const prevMessage = () => {
            setCurrentAuditChartMessageIndex((prevIndex) =>
                prevIndex === 0 ? auditChartMessages.length - 1 : prevIndex - 1
            );
        };

        return (
            <div className="relative w-full h-full max-w-md mx-auto flex flex-col justify-center items-center p-6">
                <p className="text-lg text-center mb-6 px-2 pt-2">
                    {currentMessage.message}
                </p>
                <div className="flex items-center justify-between w-full px-4">
                    <button
                        onClick={prevMessage}
                        className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="h-8 w-8" />
                    </button>
                    <span className="text-sm text-gray-500">
                        {currentAuditChartMessageIndex + 1} / {auditChartMessages.length}
                    </span>
                    <button
                        onClick={nextMessage}
                        className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faChevronRight} className="h-8 w-8" />
                    </button>
                </div>
            </div>
        );
    };


    const handleRiskScoreChange = (field, value) => {
        setRiskScore((prevScores) => {
            // Check if the field is being unselected (the new value is the same as the current value)
            const isUnselecting = prevScores[field] === value;

            return {
                ...prevScores,
                // If unselecting, set the field's value to null, otherwise, update it with the new value
                [field]: isUnselecting ? null : value,
            };
        });
    };

    const insertSelectedMacro = async (macro) => {
        const macroSection = macro.section;
        const sectionMapping = {
            "hpi": hpi,
            "ros": ros,
            "physical_exam": exam,
            "reevaluation": reeval,
            "mdm": mdm,
            "procedures": procedures
        }
        const sectionToUpdate = sectionMapping[macroSection];
        const updatedSection = sectionToUpdate ? sectionToUpdate + "\n\n" + macro.content : macro.content;
        const setterMapping = {
            "hpi": setHPI,
            "ros": setROS,
            "physical_exam": setExam,
            "reevaluation": setReeval,
            "mdm": setMDM,
            "procedures": setProcedures
        };
        // Update the section with the new macro content
        setterMapping[macroSection](updatedSection);
        // Save the updated section to the database
        saveDocument(macroSection, updatedSection);

        // Update macro_manually_inserted flag in backend
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            // Prepare the API call
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: chart.document_id,
                    field_to_update: 'macro_manually_inserted',
                    new_value: true
                })
            };

            // Make the API call to update the macro_manually_inserted flag
            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

        } catch (error) {
            console.error('Error updating macro_manually_inserted flag:', error);
            // Handle any errors here
        }
    };


    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const copyAllToClipboardPlain = () => {
        const unresolvedForceResolveItems = riskMessages.filter(
            (item) => item.force_resolve === true && item.resolved === false
        );

        if (unresolvedForceResolveItems.length > 0) {
            // Show modal
            setShowForceResolveModal(true);
            return; // Do not proceed with copying
        }

        let allText = `HISTORY OF PRESENT ILLNESS: \n${hpi}`;

        // Conditionally include the ROS section
        if (!actorInfo.transfer_ros_to_hpi) {
            allText += `\n\nREVIEW OF SYSTEMS: \n${ros}`;
        }


        allText += `\n\nPHYSICAL EXAM: \n${exam}`;

        // Conditionally include the Evaluations section
        if (!actorInfo.hide_reevaluation_section) {
            allText += `\n\nEVALUATIONS: \n${reeval}`;
        }

        if (isProceduresEnabled && procedures) {
            allText += `\n\nPROCEDURES: \n${procedures}`;
        }

        if (mdm) {
            allText += `\n\nMEDICAL DECISION-MAKING: \n${mdm}`;
        }

        navigator.clipboard.writeText(allText).then(() => {
            setCopiedAll(true);
            updateDocument(chart.document_id, 'copied_all', true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    function convertHtmlToPlainText(html) {
        if (!html) return '';

        try {
            return htmlToText(html, {
                wordwrap: false,
                selectors: [
                    // Use 'heading' formatter for headings  
                    { selector: 'h1', format: 'heading' },
                    { selector: 'h2', format: 'heading' },
                    { selector: 'h3', format: 'heading' },
                    { selector: 'h4', format: 'heading' },
                    { selector: 'h5', format: 'heading' },
                    { selector: 'h6', format: 'heading' },

                    // Use 'lineBreak' for <br> tags  
                    { selector: 'br', format: 'lineBreak' },

                    // Use 'unorderedList' and 'orderedList' for lists  
                    { selector: 'ul', format: 'unorderedList' },
                    { selector: 'ol', format: 'orderedList' },

                    // Use 'anchor' formatter for links  
                    { selector: 'a', format: 'anchor' },

                    // Use a custom formatter for bold elements  
                    { selector: 'strong', format: 'uppercaseInline' },
                    { selector: 'b', format: 'uppercaseInline' },
                ],
                formatters: {
                    uppercaseInline: function (elem, walk, builder, formatOptions) {
                        // Create a temporary builder to collect text  
                        let textContent = '';

                        const tempBuilder = {
                            addInline(text) {
                                textContent += text;
                            },
                            addLineBreak() {
                                textContent += '\n';
                            },
                            openBlock(blockOptions) { },
                            closeBlock() { },
                            options: builder.options,
                        };

                        // Process child nodes if they exist  
                        if (elem.children && elem.children.length > 0) {
                            walk(elem.children, tempBuilder);
                        } else if (elem.text) {
                            // If no children, but text exists directly  
                            textContent += elem.text;
                        } else if (elem.data) {
                            // For text nodes  
                            textContent += elem.data;
                        } else if (elem.content) {
                            // Fallback to content property  
                            textContent += elem.content;
                        }

                        // Add the uppercase text to the main builder  
                        builder.addInline(textContent.toUpperCase());
                    },
                },
            });
        } catch (error) {
            console.warn('Conversion failed:', error);

            // Fallback to simple text stripping  
            return html
                .replace(/<br\s*\/?>/gi, '\n')
                .replace(/<\/p>/gi, '\n\n')
                .replace(/<li>/gi, '\n• ')
                .replace(/<strong>|<b>/gi, '') // Remove bold tags
                .replace(/<\/strong>|<\/b>/gi, '')
                .replace(/<[^>]+>/g, '') // Remove any remaining HTML tags
                .trim();
        }
    }



    const copyAllToClipboardHtml = () => {

        const unresolvedForceResolveItems = riskMessages.filter(
            (item) => item.force_resolve === true && item.resolved === false
        );

        if (unresolvedForceResolveItems.length > 0) {
            // Show modal
            setShowForceResolveModal(true);
            return; // Do not proceed with copying
        }


        // Prepare HTML content
        let htmlContent = `<h4>HISTORY OF PRESENT ILLNESS:</h4><div>${hpi.replace(/\n/g, '<br>')}</div><br>`;

        if (!actorInfo.transfer_ros_to_hpi) {
            htmlContent += `<h4>REVIEW OF SYSTEMS:</h4><div>${ros.replace(/\n/g, '<br>')}</div><br>`;
        }

        htmlContent += `<h4>PHYSICAL EXAM:</h4><div>${exam.replace(/\n/g, '<br>')}</div><br>`;

        if (!actorInfo.hide_reevaluation_section) {
            htmlContent += `<h4>EVALUATIONS:</h4><div>${reeval.replace(/\n/g, '<br>')}</div><br>`;
        }

        if (isProceduresEnabled && procedures) {
            htmlContent += `<h4>PROCEDURES:</h4><div>${procedures.replace(/\n/g, '<br>')}</div><br>`;
        }

        if (mdm) {
            htmlContent += `<h4>MEDICAL DECISION-MAKING:</h4><div>${mdm.replace(/\n/g, '<br>')}</div>`;
        }

        // Prepare plain text content
        let plainTextContent = 'HISTORY OF PRESENT ILLNESS:\n';
        plainTextContent += hpi + '\n\n';

        if (!actorInfo.transfer_ros_to_hpi) {
            plainTextContent += 'REVIEW OF SYSTEMS:\n';
            plainTextContent += ros + '\n\n';
        }

        plainTextContent += 'PHYSICAL EXAM:\n';
        plainTextContent += exam + '\n\n';

        if (!actorInfo.hide_reevaluation_section) {
            plainTextContent += 'EVALUATIONS:\n';
            plainTextContent += reeval + '\n\n';
        }

        if (isProceduresEnabled && procedures) {
            plainTextContent += 'PROCEDURES:\n';
            plainTextContent += procedures + '\n\n';
        }

        if (mdm) {
            plainTextContent += 'MEDICAL DECISION-MAKING:\n';
            // Convert mdm HTML to plain text
            const mdmPlainText = convertHtmlToPlainText(mdm);
            plainTextContent += mdmPlainText + '\n';
        }

        // Replace \n with \r\n for Windows compatibility
        plainTextContent = plainTextContent.replace(/\n/g, '\r\n');

        setCopiedAll(true);
        updateDocument(chart.document_id, 'copied_all', true);

        if (navigator.clipboard && window.isSecureContext) {
            // Use the Async Clipboard API for both HTML and plain text content
            const htmlType = 'text/html';
            const textType = 'text/plain';

            const htmlBlob = new Blob([htmlContent], { type: htmlType });
            const textBlob = new Blob([plainTextContent], { type: textType });

            const data = new ClipboardItem({
                [htmlType]: htmlBlob,
                [textType]: textBlob,
            });

            navigator.clipboard.write([data]).then(
                () => {
                    console.log('Copied to clipboard successfully!');
                },
                (err) => {
                    console.error('Could not copy text: ', err);
                    // Fallback to legacy method
                    fallbackCopyToClipboard(plainTextContent);
                }
            );
        } else {
            // Fallback for insecure contexts or unsupported browsers
            fallbackCopyToClipboard(plainTextContent);
        }

        setTimeout(() => {
            setCopiedAll(false);
        }, 3000); // Reset copy state after 3 seconds
    };

    const fallbackCopyToClipboard = (content) => {
        const textarea = document.createElement('textarea');
        textarea.value = content; // Use plain text content
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page
        textarea.style.left = '-9999px';
        textarea.style.top = '0';

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                console.log('Fallback: Copied to clipboard successfully!');
            } else {
                console.error('Fallback: Could not copy text');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textarea);
    };


    const updateSection = (sectionName, sectionContent) => {
        switch (sectionName) {
            case 'hpi':
                setHPI(sectionContent);
                setEditedDocument(prev => ({ ...prev, hpi: sectionContent }));
                break;
            case 'ros':
                setROS(sectionContent);
                setEditedDocument(prev => ({ ...prev, ros: sectionContent }));
                break;
            case 'physical_exam':
                setExam(sectionContent);
                setEditedDocument(prev => ({ ...prev, physical_exam: sectionContent }));
                break;
            case 'reevaluation':
                setReeval(sectionContent);
                setEditedDocument(prev => ({ ...prev, reevaluation: sectionContent }));
                break;
            case 'procedures':
                setProcedures(sectionContent);
                setEditedDocument(prev => ({ ...prev, procedures: sectionContent }));
                break;
            case 'mdm':
                setMDM(sectionContent);
                setEditedDocument(prev => ({ ...prev, mdm: sectionContent }));
                break;
        }
    };

    const updateHPI = (newHPI) => {
        setHPI(newHPI);
        setEditedDocument(prev => ({ ...prev, hpi: newHPI }));
    };

    const updateROS = (newROS) => {
        setROS(newROS);
        setEditedDocument(prev => ({ ...prev, ros: newROS }));
    };

    const updateExam = (newExam) => {
        setExam(newExam);
        setEditedDocument(prev => ({ ...prev, physical_exam: newExam }));
    };

    const updateReeval = (newReeval) => {
        setReeval(newReeval);
        setEditedDocument(prev => ({ ...prev, reevaluation: newReeval }));
    };

    const updateProcedures = (newProcedures) => {
        setProcedures(newProcedures);
        setEditedDocument(prev => ({ ...prev, procedures: newProcedures }));
    };

    const updateMDM = (newMDM) => {
        setMDM(newMDM);
        setEditedDocument(prev => ({ ...prev, mdm: newMDM }));
    };

    const updateProcedureDescription = (newProcedureDescription) => {
        setProcedureDescription(newProcedureDescription);
        setEditedDocument(prev => ({ ...prev, procedure_description: newProcedureDescription }));
    };

    const updatePatientName = (newPatientName) => {
        setPatientName(newPatientName);
        setEditedDocument(prev => ({ ...prev, patient_name: newPatientName }));
    };

    const saveDocument = async (fieldToSave, newValue) => {
        let hasChanges = false;

        if (newValue === undefined) {
            switch (fieldToSave) {
                case 'hpi':
                    hasChanges = chart.hpi !== hpi;
                    newValue = hpi;
                    break;
                case 'ros':
                    hasChanges = chart.ros !== ros;
                    newValue = ros;
                    break;
                case 'physical_exam':
                    hasChanges = chart.physical_exam !== exam;
                    newValue = exam;
                    break;
                case 'reeval':
                    hasChanges = chart.reevaluation !== reeval;
                    newValue = reeval;
                    break;
                case 'procedures':
                    hasChanges = chart.procedures !== procedures;
                    newValue = procedures;
                    break;
                case 'mdm':
                    hasChanges = chart.mdm !== mdm;
                    newValue = mdm;
                    break;
                case 'procedure_description':
                    hasChanges = chart.procedure_description !== procedureDescription;
                    newValue = procedureDescription;
                    break;
                case 'patient_name':
                    hasChanges = chart.patient_name !== patientName;
                    newValue = patientName;
                    break;
                case 'note_feedback':
                    hasChanges = chart.note_feedback !== noteFeedback;
                    newValue = noteFeedback;
                    break;
                default:
                    console.log('Invalid field to save');
                    return;
            }
        } else {
            // Directly check changes against the document's existing value
            hasChanges = chart[fieldToSave] !== newValue;
        }

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id, "field_to_update": fieldToSave, "new_value": newValue })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updated_document) {
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            setErrorMessage("An error occurred while updating the document."); // Customize this message based on the error if needed
            setShowErrorNotification(true);
            setTimeout(() => setShowErrorNotification(false), 5000); // Hide the notification after 5 seconds
        }

    };

    const parseMDMCoding = (mdmCoding) => {
        // Initialize default values
        let copa = -1;
        let complexityOfData = -1;
        let riskOfComplications = -1;

        // Split the mdmCoding string by commas to get individual parts
        const parts = mdmCoding.split(', ');

        // Parse each part to extract the integer values
        parts.forEach(part => {
            const [key, value] = part.split(': ');
            const intValue = parseInt(value.trim());
            if (key.trim() === 'copa') {
                copa = intValue;
            } else if (key.trim() === 'complexity_of_data') {
                complexityOfData = intValue;
            } else if (key.trim() === 'risk_of_complications') {
                riskOfComplications = intValue;
            }
        });

        setCopa(copa);
        setComplexityOfData(complexityOfData);
        setRiskOfComplications(riskOfComplications);
    };

    // Replace the approveDocument function with this new toggleArchiveStatus function
    const toggleArchiveStatus = async () => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const newStatus = chart.document_status === "FINALIZED" ? "ENCOUNTER_COMPLETE" : "FINALIZED";

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "document_id": chart.document_id,
                    "field_to_update": "document_status",
                    "new_value": newStatus
                })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

            if (fetchResponse.ok) {
                // Update the local state to reflect the change
                setDocumentStatus(newStatus);

                // If we're archiving (setting to FINALIZED), navigate back to ER dashboard
                if (newStatus === "FINALIZED") {
                    navigate('/er-dashboard', { state: { providedDate: providedDate } });
                } else {
                    // If we're unarchiving, show a success message (optional)
                    console.log('Document unarchived successfully');
                    // You could also set a state to show a success message in the UI
                }
            } else {
                // Handle error
                console.error('Failed to update document status');
                // You might want to show an error message here
            }

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };

    const resolveRiskItem = async (riskItemId) => {
        setResolvingRiskItem(riskItemId);
        try {
            const request = {
                scopes: protectedResources.apiResolveRiskItem.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "risk_item_id": riskItemId })
            };

            await fetch(protectedResources.apiResolveRiskItem.endpoint, requestOptions);
            // Update messages and check if all are resolved
            setRiskMessages(prevMessages => {
                const updatedMessages = prevMessages.filter(item => item.risk_item_id !== riskItemId);
                // If no unresolved messages remain, reset the state
                if (!updatedMessages.some(msg => !msg.resolved)) {
                    setHasRiskMessages(false);
                    setIsShowingRiskMessages(false);
                }
                return updatedMessages;
            });
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        } finally {
            setResolvingRiskItem(null);
        }
    };


    const applyMacroToDocument = async (riskItemId, macroId) => {
        setResolvingRiskItem(riskItemId);
        try {
            const request = {
                scopes: protectedResources.apiApplyMacroToDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id, "macro_id": macroId, "risk_item_id": riskItemId })
            };

            const apiResponse = await fetch(protectedResources.apiApplyMacroToDocument.endpoint, requestOptions);
            const data = await apiResponse.json();
            const updatedSectionName = data.updated_section_name;
            const updatedSectionContent = data.updated_section_content;
            updateSection(updatedSectionName, updatedSectionContent);
            setResolvedRiskItem(riskItemId);
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        } finally {
            setResolvingRiskItem(null);
        }
    };

    const auditChart = async () => {
        setLoadingMessage("Auditing Chart...");
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiAuditChart.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiAuditChart.endpoint, requestOptions);
            await fetchAuditChartMessages();

            setHasAuditChartMessages(true);
            setIsShowingAuditChartMessages(true);
            setMDMCodingNotificationVisible(false);
            setIsShowingRiskMessages(false);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const generateMDM = async () => {
        setLoadingMessage("Generating MDM...");
        setMDMStatus("IN_PROGRESS");
        try {

            const request = {
                scopes: protectedResources.apiCalculateMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCalculateMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            setMDM(data.mdm_content);
            parseMDMCoding(data.mdm_coding);

            // Consider fetching differente stuff
            setMDMStatus("GENERATED");
            setIsRemoved(false);
            setMDMCodingNotificationVisible(true);

            // await fetchDocument();
            await fetchAuditChartMessages();
            await fetchRiskMessages();


        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error calculating MDM:', error);
            setMDMStatus("ERROR");
            // Handle any errors here
        }
    };

    const codeMDM = async () => {
        setIsLoading(true);
        try {

            const request = {
                scopes: protectedResources.apiCodeMDM.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCodeMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            parseMDMCoding(data.mdm_coding);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error coding MDM:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const generateCPTCodes = async () => {
        setIsGeneratingCPTCodes(true);
        try {
            const request = {
                scopes: protectedResources.apiGenerateCPTCodes.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiGenerateCPTCodes.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.cpt_codes) {
                setParsedCPTProceduralCodes(
                    data.cpt_codes.procedural_codes.map(code => ({
                        id: uuidv4(),
                        text: `${code.code}: ${code.description}`
                    }))
                );

                setParsedCPTEMCodes(
                    data.cpt_codes.em_codes.map(code => ({
                        id: uuidv4(),
                        text: `${code.code}: ${code.description}`
                    }))
                );


                setIsCPTCodesVisible(true);
                setIsCPTFeedbackVisible(true);
                setIsCPTFeedbackSubmitted(false);
                setCPTFeedback('');
            } else {
                console.error('CPT codes not found or invalid:', data.cpt_codes);
                setErrorMessage('Failed to generate CPT codes. Please check the input.');
                setShowErrorNotification(true);
            }

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error generating CPT codes:', error);
            setErrorMessage('Failed to generate CPT codes');
            setShowErrorNotification(true);
        }
        setIsGeneratingCPTCodes(false);
    };

    const removeCPTProceduralCode = (codeId) => {
        setParsedCPTProceduralCodes(prev => prev.filter(code => code.id !== codeId));
    };
    const removeCPTEMCode = (codeId) => {
        setParsedCPTEMCodes(prev => prev.filter(code => code.id !== codeId));
    };

    const fetchDocument = useCallback(async () => {
        setLoadingMessage("Fetching Chart...");
        setIsLoading(true);
        if (accounts.length > 0) {
            try {
                const documentRequest = {
                    scopes: protectedResources.apiGetDocument.scopes,
                    account: accounts[0]
                };
                const macrosRequest = {
                    scopes: protectedResources.apiGetMacros.scopes,
                    account: accounts[0]
                };

                // Acquire tokens for both requests
                const [documentToken, macrosToken] = await Promise.all([
                    instance.acquireTokenSilent(documentRequest),
                    instance.acquireTokenSilent(macrosRequest)
                ]);

                // Fetch document and macros in parallel
                const documentFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${documentToken.accessToken}`
                    },
                    body: JSON.stringify({ document_id: chart.document_id })
                };

                const macrosFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${macrosToken.accessToken}`
                    }
                };


                const [documentResponse, macrosResponse] = await Promise.all([
                    fetch(protectedResources.apiGetDocument.endpoint, documentFetchOptions),
                    fetch(protectedResources.apiGetMacros.endpoint, macrosFetchOptions)
                ]);

                const documentData = await documentResponse.json();
                const macrosData = await macrosResponse.json();

                if (documentData.document) {
                    console.log("PROCEDURES", documentData.document.procedures);
                    setHPI(documentData.document.hpi);
                    setROS(documentData.document.ros);
                    setExam(documentData.document.physical_exam);
                    setReeval(documentData.document.reevaluation);
                    setProcedures(documentData.document.procedures);
                    setMDM(documentData.document.mdm);
                    setPatientName(documentData.document.patient_name);
                    setDocumentStatus(documentData.document.document_status);
                    setProcedureDescription(documentData.document.procedure_description);

                    // Add these lines to update patient experience criteria
                    setAcknowledgedPatient(documentData.document.acknowledged_patient);
                    setIntroducedThemselves(documentData.document.introduced_themselves);
                    setProvidedDuration(documentData.document.provided_duration);
                    setProvidedExplanation(documentData.document.provided_explanation);
                    setManagedUpCareTeam(documentData.document.managed_up_care_team);



                    // Set CPT codes if they exist
                    if (documentData.document.code_suggestions) {
                        const cptCodes = JSON.parse(documentData.document.code_suggestions);

                        // Set EM codes
                        if (cptCodes.em_codes && Array.isArray(cptCodes.em_codes)) {
                            setParsedCPTEMCodes(
                                cptCodes.em_codes.map(code => ({
                                    id: uuidv4(),
                                    text: `${code.code}: ${code.description}`
                                }))
                            );
                        }

                        // Set procedural codes
                        if (cptCodes.procedural_codes && Array.isArray(cptCodes.procedural_codes)) {
                            setParsedCPTProceduralCodes(
                                cptCodes.procedural_codes.map(code => ({
                                    id: uuidv4(),
                                    text: `${code.code}: ${code.description}`
                                }))
                            );
                        }
                    }

                    if (documentData.document.mdm_coding) {
                        parseMDMCoding(documentData.document.mdm_coding);
                    }
                    setMDMStatus(documentData.document.mdm_status);
                } else {
                    setHPI("");
                    setROS("");
                    setExam("");
                    setReeval("");
                    setMDM('');
                    setPatientName("");
                    setProcedureDescription("");
                    setParsedCPTEMCodes([]);
                    setParsedCPTProceduralCodes([]);
                }

                if (macrosData.macros && typeof macrosData.macros === 'object') {
                    const newMacros = Object.values(macrosData.macros).map(macro => ({
                        macro_id: macro.macro_id,
                        title: macro.title,
                        command: macro.command,
                        content: macro.content,
                        section: macro.section,
                        is_shareable_within_org: macro.is_shareable_within_org,
                        created_at: macro.created_at,
                        updated_at: macro.updated_at
                    }));
                    setMacroList(newMacros);
                } else {
                    console.error('Unexpected macros data structure:', macrosData);
                    setMacroList([]);
                }
                setIsLoading(false);

                await fetchAuditChartMessages();
                await fetchRiskMessages();
                await fetchRiskScores();

            } catch (error) {
                setIsLoading(false);
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        }
    }, [instance, accounts, chart, handleTokenExpiration]);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        switch (type) {
            case 'hpi':
                setCopiedHPI(true);
                updateDocument(chart.document_id, 'copied_hpi', true);
                setTimeout(() => setCopiedHPI(false), 3000);
                break;
            case 'ros':
                setCopiedROS(true);
                updateDocument(chart.document_id, 'copied_ros', true);
                setTimeout(() => setCopiedROS(false), 3000);
                break;
            case 'physical_exam':
                setCopiedExam(true);
                updateDocument(chart.document_id, 'copied_physical_exam', true);
                setTimeout(() => setCopiedExam(false), 3000);
                break;
            case 'reeval':
                setCopiedReeval(true);
                updateDocument(chart.document_id, 'copied_reevaluation', true);
                setTimeout(() => setCopiedReeval(false), 3000);
                break;
            case 'procedures':
                setCopiedProcedures(true);
                updateDocument(chart.document_id, 'copied_procedures', true);
                setTimeout(() => setCopiedProcedures(false), 3000);
                break;
            case 'mdm':
                setCopiedMDM(true);
                updateDocument(chart.document_id, 'copied_mdm', true);
                setTimeout(() => setCopiedMDM(false), 3000);
                break;
            default:
                console.error('Invalid copy type:', type);
                break;
        }
    };

    const copyMDMToClipboard = (content) => {
        setCopiedMDM(true);
        updateDocument(chart.document_id, 'copied_mdm', true);
        setTimeout(() => setCopiedMDM(false), 3000);

        // Prepare HTML content
        const htmlContent = `<div>${content}</div>`;

        // Convert HTML content to plain text
        const mdmPlainText = convertHtmlToPlainText(content);

        // Replace \n with \r\n for Windows compatibility
        const plainTextContent = mdmPlainText.replace(/\n/g, '\r\n');

        if (navigator.clipboard && window.isSecureContext) {
            // Use the Async Clipboard API for both HTML and plain text content
            const htmlType = 'text/html';
            const textType = 'text/plain';

            const htmlBlob = new Blob([htmlContent], { type: htmlType });
            const textBlob = new Blob([plainTextContent], { type: textType });

            const data = new ClipboardItem({
                [htmlType]: htmlBlob,
                [textType]: textBlob,
            });

            navigator.clipboard.write([data]).then(
                () => {
                    console.log('Copied to clipboard successfully!');
                },
                (err) => {
                    console.error('Could not copy text: ', err);
                    // Fallback to legacy method
                    fallbackCopyToClipboard(plainTextContent);
                }
            );
        } else {
            // Fallback for insecure contexts or unsupported browsers
            fallbackCopyToClipboard(plainTextContent);
        }
    };


    const updateDocument = async (documentId, fieldToUpdate, newValue) => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateDocument.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: documentId,
                    field_to_update: fieldToUpdate,
                    new_value: newValue
                })
            };

            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            console.log('Updated', fieldToUpdate, 'to', newValue, 'for document', documentId);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250); // Delay of 250 milliseconds
    }, [fetchDocument, inProgress]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (riskScoreDropdownRef.current && !riskScoreDropdownRef.current.contains(event.target)) {
                setShowRiskScoreDropdown(false);
            }
            if (macroDropdownRef.current && !macroDropdownRef.current.contains(event.target)) {
                setShowMacroDropdown(false);
            }
        };

        // Attach the listener if either dropdown is visible
        if (showRiskScoreDropdown || showMacroDropdown) {
            window.addEventListener('click', handleClickOutside);
        }

        // Cleanup the listener
        return () => window.removeEventListener('click', handleClickOutside);
    }, [showRiskScoreDropdown, showMacroDropdown]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Get the button element and dropdown menu
            const button = document.querySelector('[title="More Actions"]');
            const menu = document.querySelector('[role="menu"]');

            // Only close if the click is outside both the menu and the button
            if (showActionsMenu &&
                !button?.contains(event.target) &&
                !menu?.contains(event.target)) {
                setShowActionsMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showActionsMenu]);


    const goBack = () => {
        navigate('/er-dashboard', { state: { providedDate: providedDate } });
    };

    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };

    useEffect(() => {
        if (saveTriggered) {
            // Now inside this effect, you know reeval has been updated
            saveDocument('reeval');
            setSaveTriggered(false); // Reset trigger to avoid unintended saves
        }
    }, [reeval, saveTriggered]); // Reacts to changes in reeval and the save trigger

    const filteredMacros = Object.values(macro_list).filter(macro =>
        macro.title.toLowerCase().includes(macroSearchQuery.toLowerCase())
    );
    const getMacrosForSection = (section) => {
        return macro_list.filter((macro) => macro.section === section);
    };

    const sectionMapping = {
        hpi: 'HPI',
        ros: 'ROS',
        physical_exam: 'Physical Exam',
        reevaluation: 'Evaluations',
        procedures: 'Procedures'
    };

    const [showHpiMacros, setShowHpiMacros] = useState(false);

    const toggleHpiMacros = () => {
        setShowHpiMacros(!showHpiMacros);
    };

    const [showExamMacros, setShowExamMacros] = useState(false);

    const toggleExamMacros = () => {
        setShowExamMacros(!showExamMacros);
    };

    const [showRosMacros, setShowRosMacros] = useState(false);

    const toggleRosMacros = () => {
        setShowRosMacros(!showRosMacros);
    };

    const [showEvaluationsMacros, setShowEvaluationsMacros] = useState(false);

    const toggleEvaluationsMacros = () => {
        setShowEvaluationsMacros(!showEvaluationsMacros);
    };

    const [showMDMMacros, setShowMDMMacros] = useState(false);

    const toggleMDMMacros = () => {
        setShowMDMMacros(!showMDMMacros);
    };

    const submitFeedback = async () => {
        try {

            setIsFeedbackSubmitted(true);
            // Update document with note feedback  
            await updateDocument(chart.document_id, 'note_feedback', noteFeedback);

            setIsFeedbackSubmitted(true);

            setHasProvidedRating(true);

            // Hide the feedback component after a delay  
            setTimeout(() => {
                setIsFeedbackVisible(false);
            }, 2000);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            // Optionally handle error state  
        }
    };

    const submitCPTFeedback = async () => {
        try {

            setIsCPTFeedbackSubmitted(true);
            // Update document with note feedback  
            await updateDocument(chart.document_id, 'note_feedback', CPTFeedback);

            setTimeout(() => {
                setIsCPTFeedbackVisible(false);
            }, 2000);

        } catch (error) {
            console.error('Error submitting CPT feedback:', error);
            // Optionally handle error state  
        }
    };

    const handleRatingUpdate = async (rating) => {
        try {

            setNoteRating(rating);
            setHasProvidedRating(true);
            // Update the note rating in the backend  
            await updateDocument(chart.document_id, 'note_rating', rating);



            // If you want to automatically close the feedback component after rating  
            // setIsFeedbackVisible(false);  

            // Optionally, you can proceed to ask for additional feedback  
        } catch (error) {
            console.error('Error updating note rating:', error);
            // Handle error if needed  
        }
    };

    const median = (values) => {
        const sorted = [...values].sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    };


    const toggleBracketedText = () => {
        if (!isRemoved) {
            const bracketedPattern = /^[^\S\r\n]*.*?\[\*\*\*.*?\*\*\*\].*$(\r?\n|\r)?/gm;
            const cleanedMDM = mdm.replace(bracketedPattern, '').trim();
            setOriginalMDM(mdm); // Save the current state before changing
            setCleanedMDM(cleanedMDM);
            setMDM(cleanedMDM);
            setIsRemoved(true); // Update flag to indicate the text is removed
        } else if (mdm === cleanedMDM) {
            setMDM(originalMDM);
            setIsRemoved(false);
        } else {
            alert("MDM has been edited since bracketremoval; undo is not available.");
        }
    };

    const handleCreateMacro = () => {
        navigate('/settings/macros');
    };

    useEffect(() => {
        const handleCopy = (e) => {
            const selection = window.getSelection();
            if (!selection || selection.isCollapsed) return;

            const range = selection.getRangeAt(0);
            const container = document.createElement('div');
            container.appendChild(range.cloneContents());
            const html = container.innerHTML;

            if (html) {
                e.clipboardData.setData('text/html', html);
                e.clipboardData.setData('text/plain', selection.toString());
                e.preventDefault();
            }
        };

        document.addEventListener('copy', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, [])

    const RecordingButton = ({ section, label }) => {
        const isDisabled = currentRecordingSection && currentRecordingSection !== section;
        const isSectionRecording = isRecording[section];

        const toggleRecording = () => {
            if (isSectionRecording) {
                stopRecording(section);
            } else {
                startRecording(section);
            }
        };

        const buttonClasses = `rounded-full p-2 focus:outline-none relative ${isRecording[section]
            ? 'bg-red-500'
            : isDisabled
                ? 'bg-gray-200 cursor-not-allowed'
                : ' hover:bg-gray-200'
            }`;

        const iconClasses = `text-indigo-900 ${isRecording[section]
            ? 'animate-pulse'
            : isDisabled
                ? 'opacity-50'
                : ''
            }`;

        const buttonTitle = isRecording[section]
            ? 'Stop Recording'
            : isDisabled
                ? `Cannot record ${label} while ${currentRecordingSection.replace('_', ' ').toUpperCase()} is recording`
                : 'Start Recording';

        return (
            <button
                className={buttonClasses}
                onClick={() => toggleRecording(section)}
                title={buttonTitle}
                disabled={isDisabled}
            >

                <span className="flex items-center">
                    <FontAwesomeIcon
                        icon={faMicrophone}
                        className={iconClasses}
                    />
                    <span className={`ml-2 ${isDisabled ? 'text-gray-500' : 'text-indigo-900'}`}>
                        Smart Edit
                    </span>
                </span>
            </button>
        );
    };
    const handleRefresh = () => {
        const iconElement = document.getElementById('refresh-icon');
        if (iconElement) {
            iconElement.classList.add('spin-once');
            setTimeout(() => {
                iconElement.classList.remove('spin-once');
            }, 500); // Duration of the spin animation
        }
        fetchDocument();
    };

    const handleModalVisibility = (modalToOpen) => {
        // Close all modals first
        setIsShowingRiskMessages(false);
        setMDMCodingNotificationVisible(false);
        setIsCPTCodesVisible(false);
        setIsShowingAuditChartMessages(false);

        // Then open the requested modal
        switch (modalToOpen) {
            case 'risk':
                setIsShowingRiskMessages(true);
                break;
            case 'mdm':
                setMDMCodingNotificationVisible(true);
                break;
            case 'cpt':
                setIsCPTCodesVisible(true);
                break;
            case 'audit':
                setIsShowingAuditChartMessages(true);
                break;
        }
    };

    return (
        <div className="flex flex-1 overflow-hidden min-h-0 pl-6 pr-6 pb-6">

            <div className="flex flex-1">
                {/* Main Content */}
                <div className="flex-1 flex flex-col min-h-0">
                    {/* Document Details */}
                    {chart && (
                        <div className="pt-4 pr-4 pl-4 pb-2 border-b border-gray-300 flex items-center justify-between">
                            {/* Left Side: Go Back Button, Patient Name, Procedure Description, and Encounter Date */}
                            <div className="flex items-center space-x-4">
                                {/* Go Back Button */}
                                <button
                                    onClick={goBack}
                                    className="flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-1" />
                                    Back to Dashboard
                                </button>

                                {/* Patient Name */}
                                <div
                                    className="cursor-pointer"
                                    onDoubleClick={() => setIsEditingPatientName(true)}
                                >
                                    {isEditingPatientName ? (
                                        <input
                                            type="text"
                                            className="w-48 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            value={editedDocument.patient_name}
                                            onChange={(e) => updatePatientName(e.target.value)}
                                            onBlur={() => {
                                                setIsEditingPatientName(false);
                                                saveDocument('patient_name');
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    saveDocument('patient_name');
                                                    setIsEditingPatientName(false);
                                                }
                                            }}
                                            autoFocus
                                            aria-label="Edit Patient Name"
                                        />
                                    ) : (
                                        <p className="text-2xl font-semibold text-gray-800">{patientName}</p>
                                    )}
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Procedure Description */}
                                <div
                                    className="cursor-pointer"
                                    onDoubleClick={() => setIsEditingProcedureDescription(true)}
                                >
                                    {isEditingProcedureDescription ? (
                                        <input
                                            type="text"
                                            className="w-64 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-md"
                                            value={editedDocument.procedure_description}
                                            onChange={(e) => updateProcedureDescription(e.target.value)}
                                            onBlur={() => {
                                                setIsEditingProcedureDescription(false);
                                                saveDocument('procedure_description');
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    saveDocument('procedure_description');
                                                    setIsEditingProcedureDescription(false);
                                                }
                                            }}
                                            autoFocus
                                            aria-label="Edit Procedure Description"
                                        />
                                    ) : (
                                        <p className="text-lg text-gray-700">{procedureDescription}</p>
                                    )}
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Encounter Date */}
                                <div className="text-gray-600">
                                    <p className="text-md">
                                        {formatDate(chart.created_at)} at {formatTime(chart.created_at)}
                                    </p>
                                </div>
                            </div>

                            {/* Right Side: Action Buttons */}
                            <div className="flex items-center space-x-2">
                                {/* Copy All Button */}
                                {!(documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' || documentStatus === 'IN_PROGRESS') && (
                                    <button
                                        onClick={isCustomMDMExempt ? copyAllToClipboardPlain : copyAllToClipboardHtml}
                                        className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                        title={copiedAll ? "Copied!" : "Copy All"}
                                    >
                                        <FontAwesomeIcon icon={copiedAll ? faCheck : faCopy} className="h-5 w-5" />
                                        <span>{copiedAll ? "Copied!" : "Copy All"}</span>
                                    </button>
                                )}

                                {/* Refresh Button */}
                                <button
                                    onClick={fetchDocument}
                                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                    title="Refresh"
                                >
                                    <FontAwesomeIcon icon={faSync} className="h-5 w-5" />
                                    <span>Refresh</span>
                                </button>

                                {/* Risk Score Button */}
                                {!(documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' || documentStatus === 'IN_PROGRESS') && (
                                    <div className="relative">
                                        <button
                                            onClick={() => setShowRiskScoreDropdown(!showRiskScoreDropdown)}
                                            className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                            title="Calculate Risk Score"
                                        >
                                            <FontAwesomeIcon icon={faChartLine} className="h-5 w-5" />
                                            <span>Risk Score</span>
                                        </button>
                                        {showRiskScoreDropdown && (
                                            <div
                                                className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="risk-score-dropdown"
                                            >
                                                <div className="py-1" role="none">
                                                    {riskScores.map((score) => (
                                                        <button
                                                            key={score.risk_score_id}
                                                            type="button"
                                                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100 hover:text-indigo-900"
                                                            role="menuitem"
                                                            onClick={() => {
                                                                setCurrentRiskScoreId(score.risk_score_id);
                                                                setIsShowingRiskMessages(false);
                                                                setIsShowingAuditChartMessages(false);
                                                                setMDMCodingNotificationVisible(false);
                                                                setRiskScoreNotificationVisible(true);
                                                                setShowRiskScoreDropdown(false);
                                                            }}
                                                        >
                                                            {score.score_name}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Audit Chart Button */}
                                {/* {isBetaPosthog && !hasAuditChartMessages && !(documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' || documentStatus === 'IN_PROGRESS') && (
                                    <button
                                        onClick={auditChart}
                                        className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:shadow-xl transition-shadow duration-200"
                                        title="Audit Chart"
                                    >
                                        <FontAwesomeIcon icon={faSearch} className="h-5 w-5" />
                                        <span>Audit Chart</span>
                                    </button>
                                )} */}

                                {/* Supplemental Documents Button */}
                                {isSupplementalDocsEnabled && (
                                    <button
                                        onClick={() => setIsSupplementalDocsModalOpen(true)}
                                        className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                        title="Supplemental Documents"
                                    >
                                        <DocumentDuplicateIcon className="h-5 w-5" />
                                        <span>Supplemental Docs</span>
                                    </button>
                                )}

                                {/* Replace the Archive and Delete buttons with this Menu button */}
                                <div className="relative">
                                    <button
                                        onClick={() => setShowActionsMenu(!showActionsMenu)}
                                        className="flex items-center bg-indigo-900 text-white rounded-lg px-2 py-2 hover:bg-indigo-800 transition-shadow duration-200 h-[40px]" // Adjusted padding and added fixed height
                                        title="More Actions"
                                    >
                                        <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                            className="h-5 w-5"
                                        />
                                    </button>

                                    {/* Dropdown Menu */}
                                    {showActionsMenu && (
                                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                                            <div className="py-1" role="menu">
                                                <button
                                                    onClick={() => {
                                                        toggleArchiveStatus();
                                                        setShowActionsMenu(false);
                                                    }}
                                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                                                    role="menuitem"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={documentStatus === "FINALIZED" ? faUndo : faArchive}
                                                        className="h-4 w-4"
                                                    />
                                                    <span>{documentStatus === "FINALIZED" ? "Unarchive" : "Archive"}</span>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsDeleteDialogOpen(true);
                                                        setShowActionsMenu(false);
                                                    }}
                                                    className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center space-x-2"
                                                    role="menuitem"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className="h-4 w-4" />
                                                    <span>Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Delete Confirmation Dialog */}
                                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                                    <DialogTitle>
                                        <div className="flex items-center space-x-2 text-red-600">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6" />
                                            <span className="text-xl font-bold">Delete Document</span>
                                        </div>
                                    </DialogTitle>
                                    <DialogBody>
                                        <DialogDescription>
                                            <div className="space-y-4">
                                                <div className="text-lg font-semibold text-gray-900">
                                                    Are you sure you want to permanently delete this document?
                                                </div>
                                                <div className="text-sm text-gray-600">
                                                    This action cannot be undone.
                                                </div>
                                            </div>
                                        </DialogDescription>
                                    </DialogBody>
                                    <DialogActions>
                                        <Button
                                            onClick={() => setIsDeleteDialogOpen(false)}
                                            disabled={isDeleting}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={handleDeleteConfirm}
                                            className="bg-red-600 hover:bg-red-700 disabled:opacity-50"
                                            disabled={isDeleting}
                                        >
                                            {isDeleting ? (
                                                <div className="flex items-center space-x-2">
                                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    <span>Deleting...</span>
                                                </div>
                                            ) : (
                                                'Delete Document'
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                    )}



                    {/* Check Document Status */}
                    {documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' ? (
                        <div className="flex-1 flex items-center justify-center">
                            <p className="text-xl text-gray-600">Begin your encounter from your mobile app.</p>
                        </div>
                    ) : documentStatus === 'IN_PROGRESS' ? (
                        // Case: IN_PROGRESS but all sections are empty (Whole note is in progress)
                        <div className="flex-1 flex flex-col items-center justify-center space-y-4">
                            <p className="text-xl text-gray-600 text-center">
                                This chart is currently in progress and not available yet.
                            </p>
                            <button
                                onClick={handleRefresh}
                                className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 shadow-lg hover:shadow-xl"
                                title="Refresh"
                            >
                                <FontAwesomeIcon id="refresh-icon" icon={faSync} className="h-5 w-5" />
                                <span>Refresh</span>
                            </button>
                        </div>
                    ) : (
                        // Default case: Display the note sections
                        <>

                            {/* Clinical Note Section */}
                            {isLoading ? (
                                <div className="loading-overlay">
                                    <div className="spinner"></div>
                                    <div className="loading-text">{loadingMessage}</div>
                                </div>
                            ) : (
                                <div className="flex-1 overflow-y-auto pr-72">
                                    <div className="space-y-2 p-5">
                                        {/* History of Present Illness Section */}
                                        <div className="flex flex-col">
                                            <div className="flex items-center">
                                                <div className="font-bold text-slate-700 text-md">History of Present Illness</div>
                                                <div className="flex items-center space-x-2 pl-4">
                                                    <button
                                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                        onClick={() => saveDocument('hpi')}
                                                    >
                                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                    </button>
                                                    {copiedHPI ? (
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => copyToClipboard(hpi, 'hpi')}
                                                        >
                                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => copyToClipboard(hpi, 'hpi')}
                                                        >
                                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                        </button>
                                                    )}
                                                    {/* Macro Dropdown Button */}
                                                    <div className="relative" ref={hpiMacroDropdownRef}>
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() =>
                                                                setShowHpiMacroDropdown(!showHpiMacroDropdown)
                                                            }
                                                        >
                                                            Macros{' '}
                                                            <FontAwesomeIcon
                                                                icon={showHpiMacroDropdown ? faChevronUp : faChevronDown}
                                                                className="ml-2"
                                                            />
                                                        </button>

                                                        {/* Macro Dropdown */}
                                                        {showHpiMacroDropdown && (
                                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                <div className="py-1">
                                                                    {getMacrosForSection('hpi').map((macro) => (
                                                                        <button
                                                                            key={macro.macro_id}
                                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                            onClick={() => {
                                                                                insertSelectedMacro(macro, 'hpi');
                                                                                setShowHpiMacroDropdown(false);
                                                                            }}
                                                                        >
                                                                            {macro.title}
                                                                        </button>
                                                                    ))}
                                                                    {getMacrosForSection('hpi').length === 0 && (
                                                                        <div className="text-center">
                                                                            <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                            <div className="mt-6">
                                                                                <button
                                                                                    type="button"
                                                                                    className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                    onClick={handleCreateMacro}
                                                                                >
                                                                                    + New Macro
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <RecordingButton section="hpi" label="HPI" />
                                                </div>
                                            </div>
                                            {sectionLoadingStates.hpi ? (
                                                <div className="flex justify-center items-center h-full">
                                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                </div>
                                            ) : (
                                                <TextareaAutosize
                                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                    value={hpi || ''}
                                                    onChange={(e) => updateHPI(e.target.value)}
                                                    onBlur={() => saveDocument('hpi', hpi)}
                                                    rows={10}>
                                                </TextareaAutosize>
                                            )}
                                        </div>

                                        {/* Review of Systems Section */}
                                        {!actorInfo.transfer_ros_to_hpi && (
                                            <div className="flex flex-col border-t border-gray-300 mt-2">
                                                <div className="flex items-center mt-2">
                                                    <div className="font-bold text-slate-700 text-md">Review of Systems</div>
                                                    <div className="flex items-center space-x-2 pl-4">
                                                        <button
                                                            className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => saveDocument('ros')}
                                                        >
                                                            Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                        </button>
                                                        {copiedROS ? (
                                                            <button
                                                                className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(ros, 'ros')}
                                                            >
                                                                Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(ros, 'ros')}
                                                            >
                                                                Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                            </button>
                                                        )}
                                                        {/* Macro Dropdown Button */}
                                                        <div className="relative" ref={rosMacroDropdownRef}>
                                                            <button
                                                                className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() =>
                                                                    setShowRosMacroDropdown(!showRosMacroDropdown)
                                                                }
                                                            >
                                                                Macros{' '}
                                                                <FontAwesomeIcon
                                                                    icon={showRosMacroDropdown ? faChevronUp : faChevronDown}
                                                                    className="ml-2"
                                                                />
                                                            </button>

                                                            {/* Macro Dropdown */}
                                                            {showRosMacroDropdown && (
                                                                <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                    <div className="py-1">
                                                                        {getMacrosForSection('ros').map((macro) => (
                                                                            <button
                                                                                key={macro.macro_id}
                                                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                onClick={() => {
                                                                                    insertSelectedMacro(macro, 'ros');
                                                                                    setShowRosMacroDropdown(false);
                                                                                }}
                                                                            >
                                                                                {macro.title}
                                                                            </button>
                                                                        ))}
                                                                        {getMacrosForSection('ros').length === 0 && (
                                                                            <div className="text-center">
                                                                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                <div className="mt-6">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                        onClick={handleCreateMacro}
                                                                                    >
                                                                                        + New Macro
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <RecordingButton section="ros" label="ROS" />
                                                    </div>
                                                </div>
                                                {sectionLoadingStates.ros ? (
                                                    <div className="flex justify-center items-center h-full">
                                                        <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                    </div>
                                                ) : (
                                                    <TextareaAutosize
                                                        className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                        value={ros || ''}
                                                        onChange={(e) => updateROS(e.target.value)}
                                                        onBlur={() => saveDocument('ros', ros)}
                                                        rows={10}>
                                                    </TextareaAutosize>
                                                )}
                                            </div>
                                        )}

                                        {/* Physical Exam Section */}
                                        <div className="flex flex-col border-t border-gray-300 mt-2">
                                            <div className="flex items-center mt-2">
                                                <div className="font-bold text-slate-700 text-md">Physical Exam</div>
                                                <div className="flex items-center space-x-2 pl-4">
                                                    <button
                                                        className="rounded  text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                        onClick={() => saveDocument('physical_exam')}
                                                    >
                                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                    </button>
                                                    {copiedExam ? (
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => copyToClipboard(exam, 'physical_exam')}
                                                        >
                                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => copyToClipboard(exam, 'physical_exam')}
                                                        >
                                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                        </button>
                                                    )}
                                                    {/* Macro Dropdown Button */}
                                                    <div className="relative" ref={examMacroDropdownRef}>
                                                        <button
                                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() =>
                                                                setShowExamMacroDropdown(!showExamMacroDropdown)
                                                            }
                                                        >
                                                            Macros{' '}
                                                            <FontAwesomeIcon
                                                                icon={showExamMacroDropdown ? faChevronUp : faChevronDown}
                                                                className="ml-2"
                                                            />
                                                        </button>

                                                        {/* Macro Dropdown */}
                                                        {showExamMacroDropdown && (
                                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                <div className="py-1">
                                                                    {getMacrosForSection('physical_exam').map(
                                                                        (macro) => (
                                                                            <button
                                                                                key={macro.macro_id}
                                                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                onClick={() => {
                                                                                    insertSelectedMacro(
                                                                                        macro,
                                                                                        'physical_exam'
                                                                                    );
                                                                                    setShowExamMacroDropdown(false);
                                                                                }}
                                                                            >
                                                                                {macro.title}
                                                                            </button>
                                                                        )
                                                                    )}
                                                                    {getMacrosForSection('physical_exam').length ===
                                                                        0 && (
                                                                            <div className="text-center">
                                                                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                <div className="mt-6">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                        onClick={handleCreateMacro}
                                                                                    >
                                                                                        + New Macro
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <RecordingButton section="physical_exam" label="Physical Exam" />
                                                </div>
                                            </div>
                                            {sectionLoadingStates.physical_exam ? (
                                                <div className="flex justify-center items-center h-full">
                                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                </div>
                                            ) : (
                                                <TextareaAutosize
                                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                    value={exam || ''}
                                                    onChange={(e) => updateExam(e.target.value)}
                                                    onBlur={() => saveDocument('physical_exam', exam)}
                                                    rows={10}>
                                                </TextareaAutosize>
                                            )}
                                        </div>

                                        {/* Evaluations Section */}
                                        {!actorInfo.hide_reevaluation_section && (
                                            <div className="flex flex-col border-t border-gray-300 mt-2">
                                                <div className="flex items-center mt-2">
                                                    <div className="font-bold text-slate-700 text-md">Evaluations</div>
                                                    <div className="flex items-center space-x-2 pl-4">
                                                        <button
                                                            className="rounded  text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => saveDocument('reeval')}
                                                        >
                                                            Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                        </button>
                                                        {copiedReeval ? (
                                                            <button
                                                                className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(reeval, 'reeval')}
                                                            >
                                                                Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(reeval, 'reeval')}
                                                            >
                                                                Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                            </button>
                                                        )}
                                                        {/* Macro Dropdown Button */}
                                                        <div className="relative" ref={reevalMacroDropdownRef}>
                                                            <button
                                                                className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() =>
                                                                    setShowReevalMacroDropdown(!showReevalMacroDropdown)
                                                                }
                                                            >
                                                                Macros{' '}
                                                                <FontAwesomeIcon
                                                                    icon={showReevalMacroDropdown ? faChevronUp : faChevronDown}
                                                                    className="ml-2"
                                                                />
                                                            </button>

                                                            {/* Macro Dropdown */}
                                                            {showReevalMacroDropdown && (
                                                                <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                    <div className="py-1">
                                                                        {getMacrosForSection('reevaluation').map(
                                                                            (macro) => (
                                                                                <button
                                                                                    key={macro.macro_id}
                                                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                    onClick={() => {
                                                                                        insertSelectedMacro(
                                                                                            macro,
                                                                                            'reeval'
                                                                                        );
                                                                                        setShowReevalMacroDropdown(false);
                                                                                    }}
                                                                                >
                                                                                    {macro.title}
                                                                                </button>
                                                                            )
                                                                        )}
                                                                        {getMacrosForSection('reevaluation').length ===
                                                                            0 && (
                                                                                <div className="text-center">
                                                                                    <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                    <div className="mt-6">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                            onClick={handleCreateMacro}
                                                                                        >
                                                                                            + New Macro
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <RecordingButton section="reevaluation" label="Evaluations" />

                                                    </div>
                                                </div>
                                                {sectionLoadingStates.reevaluation ? (
                                                    <div className="flex justify-center items-center h-full">
                                                        <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                    </div>
                                                ) : (
                                                    isMIPSModeEnabled ? (
                                                        <CodeMirrorEditor
                                                            textContent={reeval}
                                                            updateTextContent={updateReeval}
                                                            saveDocument={saveDocument}
                                                        />
                                                    ) : (
                                                        <TextareaAutosize
                                                            className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                            value={reeval || ''}
                                                            onChange={(e) => updateReeval(e.target.value)}
                                                            onBlur={() => saveDocument('reeval', reeval)}
                                                            rows={10}
                                                        />
                                                    )
                                                    // <PlainTextEditor textContent={reeval} updateTextContent={updateReeval} saveDocument={saveDocument} />

                                                )}
                                            </div>
                                        )}

                                        {/* Procedures Section */}
                                        {isProceduresEnabled && (
                                            <div className="flex flex-col border-t border-gray-300 mt-2">
                                                <div className="flex items-center mt-2">
                                                    <div className="font-bold text-slate-700 text-md">Procedures</div>
                                                    <div className="flex items-center space-x-2 pl-4">
                                                        <button
                                                            className="rounded  text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                            onClick={() => saveDocument('procedures')}
                                                        >
                                                            Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                        </button>
                                                        {copiedProcedures ? (
                                                            <button
                                                                className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(procedures, 'procedures')}
                                                            >
                                                                Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => copyToClipboard(procedures, 'procedures')}
                                                            >
                                                                Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                            </button>
                                                        )}
                                                        {/* Macro Dropdown Button */}
                                                        <div className="relative" ref={proceduresMacroDropdownRef}>
                                                            <button
                                                                className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() =>
                                                                    setShowProceduresMacroDropdown(!showProceduresMacroDropdown)
                                                                }
                                                            >
                                                                Macros{' '}
                                                                <FontAwesomeIcon
                                                                    icon={showProceduresMacroDropdown ? faChevronUp : faChevronDown}
                                                                    className="ml-2"
                                                                />
                                                            </button>

                                                            {/* Macro Dropdown */}
                                                            {showProceduresMacroDropdown && (
                                                                <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                    <div className="py-1">
                                                                        {getMacrosForSection('procedures').map(
                                                                            (macro) => (
                                                                                <button
                                                                                    key={macro.macro_id}
                                                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                    onClick={() => {
                                                                                        insertSelectedMacro(
                                                                                            macro,
                                                                                            'procedures'
                                                                                        );
                                                                                        setShowProceduresMacroDropdown(false);
                                                                                    }}
                                                                                >
                                                                                    {macro.title}
                                                                                </button>
                                                                            )
                                                                        )}
                                                                        {getMacrosForSection('procedures').length ===
                                                                            0 && (
                                                                                <div className="text-center">
                                                                                    <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                    <div className="mt-6">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                            onClick={handleCreateMacro}
                                                                                        >
                                                                                            + New Macro
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <RecordingButton section="procedures" label="Procedures" />

                                                    </div>
                                                </div>
                                                {sectionLoadingStates.procedures ? (
                                                    <div className="flex justify-center items-center h-full">
                                                        <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                    </div>
                                                ) : (
                                                    isMIPSModeEnabled ? (
                                                        <CodeMirrorEditor
                                                            textContent={procedures}
                                                            updateTextContent={updateProcedures}
                                                            saveDocument={saveDocument}
                                                        />
                                                    ) : (
                                                        <TextareaAutosize
                                                            className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                            value={procedures || ''}
                                                            onChange={(e) => updateProcedures(e.target.value)}
                                                            onBlur={() => saveDocument('procedures', procedures)}
                                                            rows={10}
                                                        />
                                                    )
                                                    // <PlainTextEditor textContent={reeval} updateTextContent={updateReeval} saveDocument={saveDocument} />

                                                )}
                                            </div>
                                        )}


                                        {/* Medical Decision-Making Documentation (MDM) Section */}
                                        <div className="flex flex-col border-t border-gray-300 mt-2">
                                            <div className="flex items-center mt-2 justify-start space-x-2">
                                                <div className="font-bold text-slate-700 text-md">Medical Decision-Making</div>
                                                <div className="flex items-center space-x-2 pl-4">
                                                    {mdmStatus !== "IN_PROGRESS" && (
                                                        <button
                                                            onClick={generateMDM}
                                                            className={`flex items-center space-x-2 rounded-lg px-3 py-2 cursor-pointer ${mdm === null || mdm === ''
                                                                ? "bg-indigo-900 hover:bg-indigo-800 text-white"
                                                                : "border border-[#DB104E] bg-transparent text-[#DB104E] hover:bg-[#DB104E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#DB104E] transition duration-150 ease-in-out"
                                                                }`}
                                                            title={mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}
                                                        >
                                                            <FontAwesomeIcon icon={mdm === null || mdm === '' ? faCalculator : faSync} className="h-5 w-5" />
                                                            <span>{mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}</span>
                                                        </button>
                                                    )}
                                                    {mdmStatus === "IN_PROGRESS" && (
                                                        <div className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2">
                                                            <FontAwesomeIcon icon={faSpinner} spin className="h-5 w-5" />
                                                            <span>Generating MDM...</span>
                                                        </div>
                                                    )}

                                                    {mdm && (
                                                        <>

                                                            {isCustomMDMExempt && (
                                                                <button
                                                                    className={`rounded-md text-indigo-900 px-3 text-sm hover:bg-gray-200 focus:outline-none ${isRemoved && mdm !== cleanedMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                                                    onClick={toggleBracketedText}
                                                                    disabled={isRemoved && mdm !== cleanedMDM}
                                                                >
                                                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                                                </button>
                                                            )}
                                                            <button
                                                                className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => saveDocument('mdm')}
                                                            >
                                                                Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                            </button>
                                                            {copiedMDM ? (
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() => {
                                                                        if (!isCustomMDMExempt) {
                                                                            copyMDMToClipboard(mdm, 'mdm');
                                                                        } else {
                                                                            copyToClipboard(mdm, 'mdm');
                                                                        }
                                                                    }}
                                                                >
                                                                    Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() => {
                                                                        if (!isCustomMDMExempt) {
                                                                            copyMDMToClipboard(mdm, 'mdm');
                                                                        } else {
                                                                            copyToClipboard(mdm, 'mdm');
                                                                        }
                                                                    }}
                                                                >
                                                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                                </button>
                                                            )}

                                                            {/* Macro Dropdown Button */}
                                                            <div className="relative" ref={mdmMacrosDropdownRef}>
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() =>
                                                                        setShowMDMMacrosDropdown(!showMDMMacrosDropdown)
                                                                    }
                                                                >
                                                                    Macros{' '}
                                                                    <FontAwesomeIcon
                                                                        icon={showMDMMacrosDropdown ? faChevronUp : faChevronDown}
                                                                        className="ml-2"
                                                                    />
                                                                </button>


                                                                {/* Macro Dropdown */}
                                                                {showMDMMacrosDropdown && (
                                                                    <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                        <div className="py-1">
                                                                            {getMacrosForSection('mdm').map(
                                                                                (macro) => (
                                                                                    <button
                                                                                        key={macro.macro_id}
                                                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                        onClick={() => {
                                                                                            insertSelectedMacro(
                                                                                                macro,
                                                                                                'mdm'
                                                                                            );
                                                                                            setShowMDMMacrosDropdown(false);
                                                                                        }}
                                                                                    >
                                                                                        {macro.title}
                                                                                    </button>
                                                                                )
                                                                            )}
                                                                            {getMacrosForSection('mdm').length ===
                                                                                0 && (
                                                                                    <div className="text-center">
                                                                                        <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                        <div className="mt-6">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                                onClick={handleCreateMacro}
                                                                                            >
                                                                                                + New Macro
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    <RecordingButton section="mdm" label="MDM" />
                                                </div>
                                            </div>
                                            {sectionLoadingStates.mdm ? (
                                                <div className="flex justify-center items-center h-full">
                                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                </div>
                                            ) : mdmStatus === "IN_PROGRESS" ? (
                                                <div className="w-full rounded p-2 text-slate-400 italic">
                                                    Generating MDM...
                                                </div>
                                            ) : mdm ? (
                                                !isCustomMDMExempt ? (
                                                    <TipTapEditor mdm={mdm} updateMDM={updateMDM} saveDocument={saveDocument} />
                                                ) : (
                                                    <TextareaAutosize
                                                        className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                        value={mdm || ''}
                                                        onChange={(e) => updateMDM(e.target.value)}
                                                        onBlur={() => saveDocument('mdm', mdm)}
                                                        rows={10}
                                                    />
                                                )
                                            ) : (
                                                <div className="w-full rounded p-2 text-slate-400 italic">
                                                    MDM not yet generated. Click 'Generate MDM' to create.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* NOTIFICATION SECTIONS */}


                {/* Notification Card for Risk Score */}
                {showNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-green-800">Successfully saved</p>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                            onClick={() => setShowNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showErrorNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">Error</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <p>{errorMessage}</p>
                                    </div>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                            onClick={() => setShowErrorNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* {isCarepointPosthog && hasRiskMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingAuditChartMessages && ( */}
                {hasRiskMessages && (
                    <div className="fixed top-48 right-4 z-[80]">
                        {isShowingRiskMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingAuditChartMessages && !isCPTCodesVisible ? (
                            <div className="h-[40vh] md:h-[350px] w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                                <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-3 px-4">
                                    <div className="font-bold text-xl flex items-center">
                                        <FontAwesomeIcon icon={faShield} className="mr-2" />
                                        Cleo Risk Analyst
                                    </div>
                                    <button
                                        onClick={() => setIsShowingRiskMessages(false)}
                                        className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                                    >
                                        <XMarkIcon className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className="flex-grow overflow-y-auto p-4 space-y-4 text-xl leading-relaxed">
                                    {renderRiskMessagesContent()}
                                </div>
                            </div>
                        ) : (
                            <button
                                className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                                onClick={() => handleModalVisibility('risk')}
                                title="Risk Messages"
                            >
                                <ShieldCheckIcon className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                                <span className="text-white text-[8px] mt-1">Risk</span>
                            </button>
                        )}
                    </div>
                )}



                {/* {isBetaPosthog && hasAuditChartMessages && !mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingRiskMessages && (
                    <div className="fixed top-80 right-4 z-50">
                        {isShowingAuditChartMessages && auditChartMessages.length > 0 ? (
                            <div className="h-[40vh] md:h-[350px] w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white transition-all duration-300 ease-in-out">
                                <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-3 px-4">
                                    <div className="font-bold text-xl flex items-center">
                                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                        AI Chart Audit
                                    </div>
                                    <button
                                        onClick={() => setIsShowingAuditChartMessages(false)}
                                        className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                                    >
                                        <XMarkIcon className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className="flex-grow overflow-y-auto p-4 space-y-4 text-xl leading-relaxed">
                                    {renderAuditChartMessagesContent()}
                                </div>
                            </div>
                        ) : (
                            <button
                                onClick={() => {
                                    setIsShowingAuditChartMessages(true);
                                    setIsShowingRiskMessages(false);
                                    setMDMCodingNotificationVisible(false);
                                }}
                                className="h-12 w-12 bg-indigo-900 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl"
                                title="Open AI Chart Audit"
                            >
                                <FontAwesomeIcon icon={faSearch} className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                            </button>
                        )}
                    </div>
                )} */}

                {/* Notification Card for MDM Coding */}
                {
                    mdmStatus === "GENERATED" && (
                        <div className="fixed top-72 right-4 z-[70]">
                            {mdmCodingNotificationVisible && !riskScoreNotificationVisible && !isShowingAuditChartMessages && !isShowingRiskMessages && !isCPTCodesVisible ? (
                                <div className="h-[60vh] md:h-[375px] w-full md:w-[200px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                                    <div className="flex justify-between items-center bg-indigo-900 text-white py-2 pl-4 pr-2">
                                        <div className="font-bold text-sm text-center flex-grow cursor-pointer" onClick={() => setMDMCodingNotificationVisible(true)}>
                                            Cleo MDM Assistant
                                        </div>
                                        <button onClick={() => setMDMCodingNotificationVisible(false)} className="p-1 rounded-full bg-white text-indigo-900 hover:text-indigo-900">
                                            <XMarkIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                    <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(60vh-4rem)]">
                                        <div className="mt-2 p-2 rounded-lg shadow">
                                            <ProgressBar value={copa} title="Complexity of Problems Addressed" />
                                            <ProgressBar value={complexityOfData} title="Complexity of Data" />
                                            <ProgressBar value={riskOfComplications} title="Risk of Complications" />
                                        </div>
                                        <div className="mt-4 p-2 rounded-lg shadow">
                                            <div className="text-sm font-semibold text-gray-700">Coding Estimate</div>
                                            <div className="text-2xl font-bold text-indigo-900">
                                                {Math.floor(median([copa, complexityOfData, riskOfComplications]))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                                    onClick={() => { handleModalVisibility('mdm') }}
                                    title="MDM Assistant"
                                >
                                    <CalculatorIcon className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                                    <span className="text-white text-[8px] mt-1">MDM Score</span>
                                </button>
                            )}
                        </div>
                    )
                }

                {/* CPT Codes Button/Panel */}
                {isCPTCodesEnabled && (
                    <div className="fixed top-96 right-4 z-[60]">
                        {isCPTCodesVisible ? (
                            <div className={`absolute top-0 right-0 z-60 w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white ${isCPTCodesVisible ? '' : 'hidden'}`}>
                                <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-1 px-4">
                                    <div className="font-bold text-xl flex items-center">
                                        <FontAwesomeIcon icon={faListCheck} className="mr-2" />
                                        CPT Codes
                                        {!isGeneratingCPTCodes && (
                                            <button
                                                onClick={generateCPTCodes}
                                                className="ml-4 text-sm px-2 py-1 bg-white/20 hover:bg-white/30 rounded-md flex items-center transition-all duration-200 text-white"
                                                title="Regenerate CPT Codes"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faRotate}
                                                    className="mr-1 text-white"
                                                />
                                                <span className="text-white">Regenerate</span>
                                            </button>
                                        )}
                                    </div>
                                    <button
                                        onClick={() => setIsCPTCodesVisible(false)}
                                        className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                                    >
                                        <FontAwesomeIcon icon={faXmark} className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className="flex-grow overflow-y-auto p-2">
                                    {isGeneratingCPTCodes ? (
                                        <div className="flex justify-center items-center h-full">
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8 mb-2" />
                                                <p className="text-gray-600">Analyzing documentation...</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="space-y-2">
                                            {/* E/M Codes Section - Always render */}
                                            <h3 className="font-bold text-lg text-gray-800 flex justify-between items-center">
                                                E/M Codes:
                                            </h3>
                                            {parsedCPTEMCodes.length > 0 ? (
                                                parsedCPTEMCodes.map(code => (
                                                    <div
                                                        key={code.id}
                                                        className="flex items-center bg-gray-50 rounded-lg p-2 shadow-sm hover:shadow-md transition-shadow duration-200"
                                                    >
                                                        <div className="flex-grow">
                                                            <div className="flex items-center space-x-2">
                                                                <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-md text-sm font-medium">
                                                                    {code.text.split(':')[0]}
                                                                </span>
                                                                <span className="text-gray-700">
                                                                    {code.text.split(':')[1]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => removeCPTEMCode(code.id)}
                                                            className="ml-2 text-gray-400 hover:text-red-500 transition-colors duration-200"
                                                            title="Remove code"
                                                        >
                                                            <XMarkIcon className="h-5 w-5" />
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center text-gray-500 py-4">
                                                    Generate MDM to extract E/M codes.
                                                </div>
                                            )}

                                            {/* Divider for CPT Codes */}
                                            <div className="border-t border-gray-300"></div>

                                            {/* CPT Codes Section */}
                                            <h3 className="font-bold text-lg text-gray-800 flex justify-between items-center">
                                                Procedural Codes:
                                            </h3>
                                            {parsedCPTProceduralCodes.length > 0 ? (
                                                parsedCPTProceduralCodes.map(code => (
                                                    <div
                                                        key={code.id}
                                                        className="flex items-center bg-gray-50 rounded-lg p-2 shadow-sm hover:shadow-md transition-shadow duration-200"
                                                    >
                                                        <div className="flex-grow">
                                                            <div className="flex items-center space-x-2">
                                                                <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-md text-sm font-medium">
                                                                    {code.text.split(':')[0]}
                                                                </span>
                                                                <span className="text-gray-700">
                                                                    {code.text.split(':')[1]}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => removeCPTProceduralCode(code.id)}
                                                            className="ml-2 text-gray-400 hover:text-red-500 transition-colors duration-200"
                                                            title="Remove code"
                                                        >
                                                            <XMarkIcon className="h-5 w-5" />
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center text-gray-500 py-4">
                                                    No procedural codes generated.
                                                </div>
                                            )}

                                            {/* Feedback Section */}
                                            {isCPTFeedbackVisible && (
                                                <div className="mt-6 border-t border-gray-300 pt-4">
                                                    <h3 className="font-bold text-lg text-gray-800 mb-2">
                                                        Feedback
                                                    </h3>
                                                    {isCPTFeedbackSubmitted ? (
                                                        <div className="flex items-center justify-center space-x-2 py-4 bg-green-50 rounded-lg">
                                                            <FontAwesomeIcon
                                                                icon={faCheckCircle}
                                                                className="h-5 w-5 text-green-500"
                                                            />
                                                            <span className="text-green-700 font-medium">
                                                                Thank you for your feedback!
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="space-y-3">
                                                            <textarea
                                                                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                value={CPTFeedback}
                                                                onChange={(e) => setCPTFeedback(e.target.value)}
                                                                rows={3}
                                                                placeholder="How can we improve our CPT code suggestions?"
                                                            />
                                                            <div className="flex justify-end space-x-2">
                                                                <button
                                                                    onClick={submitCPTFeedback}
                                                                    className="bg-indigo-900 text-white py-1 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                                                                >
                                                                    Submit Feedback
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <button
                                onClick={() => {
                                    if (parsedCPTProceduralCodes.length > 0 || parsedCPTEMCodes.length > 0) {
                                        // If we already have codes, just show the modal
                                        handleModalVisibility('cpt');
                                    } else {
                                        // If no codes exist, generate them
                                        generateCPTCodes();
                                    }
                                }}
                                className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                                title="View CPT Codes"
                                disabled={isGeneratingCPTCodes}
                            >
                                {isGeneratingCPTCodes ? (
                                    <FontAwesomeIcon icon={faSpinner} spin className="h-6 w-6 text-white" />
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faListCheck} className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                                        <span className="text-white text-[8px] mt-1">CPT Codes</span>
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                )}

                {(isPatientExperienceEnabled || isPatientExperienceEnabledWebApp) && (
                    <div className="fixed bottom-96 right-12 z-40 group">
                        <div className="relative">
                            {/* Label */}
                            <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 text-center">
                                <div className="text-xs font-medium text-gray-600 whitespace-nowrap">
                                    Patient Experience
                                </div>
                                <div className="text-xs font-medium text-gray-600 whitespace-nowrap">
                                    Feedback
                                </div>
                            </div>

                            {/* Progress Circle */}
                            <div
                                className="h-20 w-20 p-1 bg-white rounded-full shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-200"
                                onClick={() => setIsPatientExperienceModalOpen(true)}
                            >
                                {hasNullCriteria ? (
                                    <CircularProgressbar
                                        value={100}
                                        text="..."
                                        styles={buildStyles({
                                            textSize: '28px',
                                            pathColor: '#6b7280',
                                            textColor: '#6b7280',
                                            trailColor: '#e5e7eb',
                                            strokeLinecap: 'round',
                                            pathTransitionDuration: 0.5,
                                            strokeWidth: 12,
                                        })}
                                    />
                                ) : (
                                    <CircularProgressbar
                                        value={completionPercentage}
                                        text={`${completedCriteria}/5`}
                                        styles={buildStyles({
                                            textSize: '28px',
                                            pathColor: completedCriteria <= 1 ? '#ef4444' :
                                                completedCriteria <= 3 ? '#f97316' :
                                                    completedCriteria <= 5 ? '#22c55e' :
                                                        '#6b7280',
                                            textColor: completedCriteria <= 1 ? '#ef4444' :
                                                completedCriteria <= 3 ? '#f97316' :
                                                    completedCriteria <= 5 ? '#22c55e' :
                                                        '#6b7280',
                                            trailColor: '#e5e7eb',
                                            strokeLinecap: 'round',
                                            pathTransitionDuration: 0.5,
                                            strokeWidth: 12,
                                        })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}



                {/* Note Feedback Component */}
                {isFeedbackVisible && (
                    <div className="fixed bottom-48 right-10 z-40">
                        {!hasProvidedRating ? (
                            <div className="w-full md:w-20 border border-gray-300 rounded-xl overflow-hidden flex flex-col items-center bg-white py-2">
                                <div className="text-xs font-bold text-gray-700 mb-2 text-center">
                                    How did Cleo do?
                                </div>

                                <button
                                    onClick={() => handleRatingUpdate('good')}
                                    className="mb-2 focus:outline-none"
                                    aria-label="Rate Good"
                                >
                                    <FontAwesomeIcon icon={faThumbsUp} className="h-6 w-6 text-green-500" />
                                </button>

                                <button
                                    onClick={() => handleRatingUpdate('medium')}
                                    className="mb-2 focus:outline-none"
                                    aria-label="Rate Neutral"
                                >
                                    <FontAwesomeIcon icon={faMeh} className="h-6 w-6 text-yellow-500" />
                                </button>

                                <button
                                    onClick={() => handleRatingUpdate('bad')}
                                    className="focus:outline-none"
                                    aria-label="Rate Poor"
                                >
                                    <FontAwesomeIcon icon={faThumbsDown} className="h-6 w-6 text-red-500" />
                                </button>
                            </div>
                        ) : (
                            !isFeedbackSubmitted ? (
                                <div className="w-full md:w-64 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white p-4">
                                    <p className="text-base font-semibold text-gray-700">
                                        Would you like to provide additional feedback?
                                    </p>
                                    <textarea
                                        className="w-full border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={noteFeedback}
                                        onChange={(e) => setNoteFeedback(e.target.value)}
                                        rows={4}
                                        placeholder="Optional feedback..."
                                    />
                                    <div className="flex justify-end space-x-2 mt-2">
                                        <button
                                            onClick={() => {
                                                // Reset states  
                                                // setHasProvidedRating(false);

                                                setIsFeedbackSubmitted(true);
                                                setNoteFeedback('');
                                                setTimeout(() => {
                                                    setIsFeedbackVisible(false);
                                                }, 3000);
                                            }}
                                            className="text-gray-600 hover:text-gray-800 focus:outline-none"
                                        >
                                            Skip
                                        </button>
                                        <button
                                            onClick={submitFeedback}
                                            className="bg-indigo-900 text-white py-1 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full md:w-32 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col items-center bg-white py-4">
                                    <p className="text-sm text-green-600 font-semibold text-center">
                                        Thank you for the feedback!
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                )}

                {
                    riskScoreNotificationVisible && !mdmCodingNotificationVisible && (
                        <RenderRiskScoreNotification riskScoreId={currentRiskScoreId} />
                    )
                }

                {showForceResolveModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto">
                            <h2 className="text-xl font-semibold mb-4 text-red-600">
                                Required Risk Items Unresolved
                            </h2>
                            <p className="mb-6">
                                Ensure all required risk items are resolved before completing your chart.
                            </p>
                            <button
                                onClick={() => setShowForceResolveModal(false)}
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}

                {isPatientExperienceModalOpen && (
                    <PatientExperienceModal
                        isOpen={isPatientExperienceModalOpen}
                        onClose={() => setIsPatientExperienceModalOpen(false)}
                        criteriaData={{
                            acknowledgedPatient,
                            introducedThemselves,
                            providedDuration,
                            providedExplanation,
                            managedUpCareTeam
                        }}
                    />
                )}

                <SupplementalDocumentsModal
                    isOpen={isSupplementalDocsModalOpen}
                    onClose={() => setIsSupplementalDocsModalOpen(false)}
                    documentId={chart.document_id}
                    onSuccess={(message) => {
                        // Add any additional logic you want to execute when documents are successfully uploaded
                        console.log('Documents uploaded successfully:', message);
                        // Optionally, you can set a success message in the UI
                    }}
                />
            </div>
        </div >
    );
}

export default PatientInfoCarepoint;
