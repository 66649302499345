import { callApiWithToken } from './apiUtils';
import { protectedResources } from '../authConfig';

export const supplementalDocumentsApi = {
    getSupplementalDocuments: async (instance, accounts, documentId) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiGetSupplementalDocuments.endpoint,
            'POST',
            { document_id: documentId },
            protectedResources.apiGetSupplementalDocuments.scopes
        );
    },

    generateSupplementalDocument: async (instance, accounts, documentId, templateId) => {
        // Get local timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiGenerateSupplementalDocument.endpoint,
            'POST',
            {
                document_id: documentId,
                template_id: templateId,
                timezone: timezone  // e.g. "America/New_York"
            },
            protectedResources.apiGenerateSupplementalDocument.scopes
        );
    },

    updateSupplementalDocument: async (instance, accounts, supplementalDocumentId, content) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiUpdateSupplementalDocument.endpoint,
            'POST',
            {
                supplemental_document_id: supplementalDocumentId,
                content: content
            },
            protectedResources.apiUpdateSupplementalDocument.scopes
        );
    },

    deleteSupplementalDocument: async (instance, accounts, supplementalDocumentId) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiDeleteSupplementalDocument.endpoint,
            'POST',
            { supplemental_document_id: supplementalDocumentId },
            protectedResources.apiDeleteSupplementalDocument.scopes
        );
    }
}; 