import React, { useState, useEffect } from 'react';
import {
    Card,
    Grid,
    Typography,
    Box,
    Paper,
    IconButton,
    Tooltip,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Chip,
    Avatar,
    LinearProgress,
    Tabs,
    Tab,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import DashboardFilters from './DashboardFilters';
import { getPerformanceColor } from '../utils/facilityData';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import ProviderSpotlight from './ProviderSpotlight';
import GroupsIcon from '@mui/icons-material/Groups';
import MDMBreakdown from './MDMBreakdown';
import { mdmBreakdown, mdmColors, emCodes, emCodeColors, mdmMetrics } from '../config/mdmConfig';
import EMView from './mdm/EMView';
import MDMCriteriaView from './MDMCriteriaView';

// Mock providers data
const mockProviders = [
    {
        id: 1,
        name: 'Dr. Sarah Johnson',
        metrics: {
            historian: 98,
            labs: 95,
            externalNotes: 92,
            imaging: 88,
            interpretation: 94,
            consults: 89,
            sdoh: 96,
        }
    },
    {
        id: 2,
        name: 'Dr. Michael Chen',
        metrics: {
            historian: 94,
            labs: 92,
            externalNotes: 88,
            imaging: 90,
            interpretation: 91,
            consults: 87,
            sdoh: 93,
        }
    },
    // Add more mock providers as needed
];

const MDMDashboard = () => {
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedFacility, setSelectedFacility] = useState('all');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [selectedView, setSelectedView] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState(null);
    const [performanceValues, setPerformanceValues] = useState({});
    const [spotlightOpen, setSpotlightOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState(null);
    const [selectedSubCriteria, setSelectedSubCriteria] = useState(null);
    const [selectedMDMCategory, setSelectedMDMCategory] = useState(null);
    const [selectedMDMLevel, setSelectedMDMLevel] = useState(null);

    // Generate new performance values when filters change
    useEffect(() => {
        const newValues = Object.keys(mdmMetrics).reduce((acc, key) => ({
            ...acc,
            [key]: Math.floor(Math.random() * (95 - 50) + 50)
        }), {});
        setPerformanceValues(newValues);
    }, [selectedRegion, selectedFacility, selectedTimePeriod]);

    const handleMetricClick = (event, metricKey) => {
        setAnchorEl(event.currentTarget);
        setSelectedMetric(metricKey);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setSelectedMetric(null);
    };

    // Generate realistic trend data for E/M codes
    const generateTrendData = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        const baseValues = {
            '99281': 2,     // Base % for level 1
            '99282': 3,     // Base % for level 2
            '99283': 15,    // Base % for level 3
            '99284': 45,    // Base % for level 4
            '99285': 30,    // Base % for level 5
            '99291': 4,     // Base % for critical care
            '99292': 1,     // Base % for additional critical care
        };

        return months.map(month => {
            const monthData = { month };
            Object.entries(baseValues).forEach(([code, baseValue]) => {
                // Add random variation of ±15% of the base value
                const variation = baseValue * 0.15;
                monthData[code] = Math.max(0, Math.min(100,
                    baseValue + (Math.random() * variation * 2 - variation)
                )).toFixed(1);
            });
            return monthData;
        });
    };

    const generatePerformanceData = () => {
        return Object.entries(mdmMetrics).map(([key, metric]) => ({
            metric: metric.name,
            value: performanceValues[key],
            target: metric.target,
        }));
    };

    const handleMDMSpotlightOpen = (category, level) => {
        setSelectedMDMCategory(category);
        setSelectedMDMLevel(level);
        setSpotlightOpen(true);
    };

    // Generate realistic E/M distribution data
    const generateEMDistribution = () => ({
        '99281': 2,     // 2% of visits
        '99282': 3,     // 3% of visits
        '99283': 15,    // 15% of visits
        '99284': 45,    // 45% of visits
        '99285': 30,    // 30% of visits
        '99291': 4,     // 4% of visits
        '99292': 1,     // 1% of visits
    });

    const EMDistributionCard = () => {
        const data = Object.entries(generateEMDistribution()).map(([code, value]) => ({
            code,
            value,
            name: emCodes[code],
        }));

        return (
            <Paper sx={{ p: 3, height: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h6">
                        E/M Code Distribution
                    </Typography>
                    <Tooltip title="Distribution of E/M codes across all encounters">
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            nameKey="code"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            label={({ code, value }) => `${code}: ${value}%`}
                        >
                            {data.map((entry) => (
                                <Cell key={entry.code} fill={emCodeColors[entry.code]} />
                            ))}
                        </Pie>
                        <RechartsTooltip
                            content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    const data = payload[0].payload;
                                    return (
                                        <Box sx={{ 
                                            bgcolor: '#fff', 
                                            p: 1.5, 
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            borderRadius: 1,
                                        }}>
                                            <Typography variant="subtitle2">
                                                {data.code}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {data.name}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mt: 0.5 }}>
                                                {data.value}% of encounters
                                            </Typography>
                                        </Box>
                                    );
                                }
                                return null;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </Paper>
        );
    };

    const KeyMetricsCard = () => {
        const miniTrendData = Array(7).fill().map((_, i) => ({
            day: i,
            level: 4.2 + (Math.random() * 0.3 - 0.15), // Variance of ±0.15
            revenue: 280 + Math.random() * 40
        }));

        return (
            <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>
                    Key Performance Indicators
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(66, 153, 225, 0.1)', 
                            borderRadius: 2,
                            height: '100%'
                        }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Average E/M Level
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                                <Typography variant="h4" color="primary">
                                    4.2
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    color="success.main"
                                    sx={{ mb: 1 }}
                                >
                                    +0.1
                                </Typography>
                            </Box>
                            <ResponsiveContainer width="100%" height={50}>
                                <LineChart data={miniTrendData}>
                                    <Line
                                        type="monotone"
                                        dataKey="level"
                                        stroke="#4299E1"
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(229, 62, 62, 0.1)', 
                            borderRadius: 2,
                            height: '100%'
                        }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Critical Care Rate
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                                <Typography variant="h4" color="error">
                                    5%
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    color="success.main"
                                    sx={{ mb: 1 }}
                                >
                                    +1.2%
                                </Typography>
                            </Box>
                            <ResponsiveContainer width="100%" height={50}>
                                <LineChart data={miniTrendData}>
                                    <Line
                                        type="monotone"
                                        dataKey="revenue"
                                        stroke="#E53E3E"
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(45, 55, 72, 0.05)', 
                            borderRadius: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Projected Monthly Revenue
                                </Typography>
                                <Typography variant="h5">
                                    $9.0M
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'flex-end'
                            }}>
                                <Typography variant="body2" color="success.main">
                                    +$750K
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    vs. Last Month
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const handleViewChange = (event, newValue) => {
        setSelectedView(newValue);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Medical Decision Making Analytics
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs 
                    value={selectedView} 
                    onChange={handleViewChange}
                    sx={{
                        '& .MuiTab-root': {
                            fontSize: '1rem',
                            fontWeight: 500,
                            textTransform: 'none',
                            minHeight: 48,
                        },
                        '& .Mui-selected': {
                            color: 'primary.main',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'primary.main',
                            height: 3,
                        },
                    }}
                >
                    <Tab label="E/M Analytics" />
                    <Tab label="MDM Analytics" />
                </Tabs>
            </Box>

            {selectedView === 0 && (
                <>
                    <DashboardFilters
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        selectedFacility={selectedFacility}
                        setSelectedFacility={setSelectedFacility}
                        selectedTimePeriod={selectedTimePeriod}
                        setSelectedTimePeriod={setSelectedTimePeriod}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <EMDistributionCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <KeyMetricsCard />
                        </Grid>
                        <Grid item xs={12}>
                            <MDMBreakdown onSpotlightOpen={handleMDMSpotlightOpen} />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ 
                                p: 3,
                                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1))',
                                backdropFilter: 'blur(8px)',
                            }}>
                                <Typography variant="h6" gutterBottom>
                                    E/M Code Distribution Trends
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <LineChart 
                                            data={generateTrendData()}
                                            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                        >
                                            <CartesianGrid 
                                                strokeDasharray="3 3" 
                                                vertical={false}
                                                stroke="rgba(0,0,0,0.1)"
                                            />
                                            <XAxis 
                                                dataKey="month" 
                                                axisLine={false}
                                                tickLine={false}
                                                tick={{ fill: '#666', fontSize: 12 }}
                                            />
                                            <YAxis 
                                                domain={[0, 50]}
                                                axisLine={false}
                                                tickLine={false}
                                                tick={{ fill: '#666', fontSize: 12 }}
                                                width={40}
                                            />
                                            <Tooltip
                                                content={({ active, payload, label }) => {
                                                    if (active && payload && payload.length) {
                                                        return (
                                                            <Box sx={{
                                                                bgcolor: '#fff',
                                                                p: 2,
                                                                border: '1px solid',
                                                                borderColor: 'divider',
                                                                borderRadius: 1,
                                                                boxShadow: 2,
                                                            }}>
                                                                <Typography variant="subtitle2" gutterBottom>
                                                                    {label}
                                                                </Typography>
                                                                {payload.map((entry) => (
                                                                    <Box key={entry.dataKey} sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 1,
                                                                        my: 0.5,
                                                                    }}>
                                                                        <Box sx={{
                                                                            width: 8,
                                                                            height: 8,
                                                                            borderRadius: '50%',
                                                                            bgcolor: emCodeColors[entry.dataKey],
                                                                        }} />
                                                                        <Typography variant="body2">
                                                                            {entry.dataKey}: {entry.value}%
                                                                        </Typography>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        );
                                                    }
                                                    return null;
                                                }}
                                            />
                                            <Legend 
                                                verticalAlign="top"
                                                height={36}
                                                content={({ payload }) => (
                                                    <Box sx={{ 
                                                        display: 'flex', 
                                                        justifyContent: 'center', 
                                                        gap: 2,
                                                        flexWrap: 'wrap',
                                                        mb: 2
                                                    }}>
                                                        {payload.map((entry) => (
                                                            <Box key={entry.value} sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1,
                                                            }}>
                                                                <Box sx={{
                                                                    width: 8,
                                                                    height: 8,
                                                                    borderRadius: '50%',
                                                                    bgcolor: entry.color,
                                                                }} />
                                                                <Typography variant="body2" sx={{ 
                                                                    color: 'text.secondary',
                                                                    fontSize: '0.875rem',
                                                                }}>
                                                                    {entry.value}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                            />
                                            {Object.keys(emCodes).map(code => (
                                                <Line
                                                    key={code}
                                                    type="monotone"
                                                    dataKey={code}
                                                    name={code}
                                                    stroke={emCodeColors[code]}
                                                    strokeWidth={2}
                                                    dot={false}
                                                    activeDot={{ 
                                                        r: 4, 
                                                        strokeWidth: 2,
                                                        stroke: '#fff'
                                                    }}
                                                />
                                            ))}
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}
            {selectedView === 1 && <MDMCriteriaView />}

            {/* Provider Spotlight Modal */}
            <ProviderSpotlight
                open={spotlightOpen}
                onClose={() => {
                    setSpotlightOpen(false);
                    setSelectedMDMCategory(null);
                    setSelectedMDMLevel(null);
                }}
                criteriaType={selectedCriteria?.type || selectedMDMCategory}
                criteriaName={selectedCriteria?.name || selectedMDMLevel}
                subCriteriaName={selectedSubCriteria}
            />
        </Box>
    );
};

export default MDMDashboard; 