import React, { useState, useMemo } from 'react';
import {
    Box,
    Grid,
    Typography,
    Paper,
    Modal,
    IconButton,
    LinearProgress,
} from '@mui/material';
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    Legend,
} from 'recharts';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PerformanceMetricsList from './PerformanceMetricsList';
import TrendsChart from './TrendsChart';
import { mdmMetrics } from '../../config/mdmConfig';
import { alpha } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import ProviderSpotlight from '../ProviderSpotlight';

const dataCriteria = [
    {
        id: 'historian',
        label: 'Historians',
        description: 'Documentation of information sources and collateral history gathering',
        subCriteria: [
            { name: 'EMS', percentage: 30, description: 'Pre-hospital care information and handoff details' },
            { name: 'Mother', percentage: 25, description: 'Maternal input on patient history and symptoms' },
            { name: 'Brother', percentage: 15, description: 'Sibling perspective on patient condition' },
        ]
    },
    {
        id: 'labs',
        label: 'Labs Ordered',
        description: 'Laboratory studies ordered and reviewed during the encounter',
        subCriteria: [
            { name: 'CBC', percentage: 35, description: 'Complete Blood Count analysis and interpretation' },
            { name: 'BMP', percentage: 30, description: 'Basic Metabolic Panel results review' },
            { name: 'Troponin', percentage: 25, description: 'Cardiac marker trending and analysis' },
        ]
    },
    {
        id: 'imaging',
        label: 'Imaging Ordered',
        description: 'Diagnostic imaging studies ordered and reviewed',
        subCriteria: [
            { name: 'Chest X-Ray', percentage: 40, description: 'Chest radiograph interpretation and findings' },
            { name: 'CT Head', percentage: 35, description: 'Head CT scan review and analysis' },
            { name: 'Ultrasound', percentage: 25, description: 'Ultrasound study evaluation' },
        ]
    },
    {
        id: 'interpretation',
        label: 'Interpretations',
        description: 'Independent interpretation of diagnostic studies',
        subCriteria: [
            { name: 'EKG', percentage: 45, description: 'Electrocardiogram interpretation and analysis' },
            { name: 'X-Ray', percentage: 35, description: 'Radiograph preliminary interpretation' },
            { name: 'CT', percentage: 20, description: 'CT scan initial review and findings' },
        ]
    },
    {
        id: 'consults',
        label: 'Consults',
        description: 'Specialist consultations requested and reviewed',
        subCriteria: [
            { name: 'Cardiology', percentage: 40, description: 'Cardiac specialist recommendations' },
            { name: 'Neurology', percentage: 35, description: 'Neurological consultation findings' },
            { name: 'Surgery', percentage: 25, description: 'Surgical evaluation and plan' },
        ]
    }
];

const DataComplexityView = () => {
    const [selectedMetrics, setSelectedMetrics] = useState(dataCriteria.map(c => c.id));
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState(null);
    const [spotlightOpen, setSpotlightOpen] = useState(false);
    const [spotlightCriteria, setSpotlightCriteria] = useState(null);
    const [spotlightSubCriteria, setSpotlightSubCriteria] = useState(null);
    const [frequencyModalOpen, setFrequencyModalOpen] = useState(false);

    // Generate consistent performance data
    const performanceData = useMemo(() => {
        return dataCriteria.reduce((acc, criteria) => ({
            ...acc,
            [criteria.id]: {
                value: Math.floor(Math.random() * 20 + (mdmMetrics[criteria.id].target - 10)),
                trend: Math.random() > 0.5 ? 'up' : 'down',
                trendValue: (Math.random() * 5).toFixed(1),
            }
        }), {});
    }, []);

    const handleMetricClick = (metric) => {
        const criteria = dataCriteria.find(c => c.id === metric);
        setSelectedCriteria(criteria);
        setFrequencyModalOpen(true);
    };

    const handleSpotlightOpen = (criteria) => {
        setSpotlightCriteria(criteria);
        setSpotlightSubCriteria(null);
        setSpotlightOpen(true);
    };

    const handleSubCriteriaSpotlightOpen = (criteria, subCriteria) => {
        setSpotlightCriteria(criteria);
        setSpotlightSubCriteria(subCriteria);
        setSpotlightOpen(true);
    };

    const generateChartData = () => {
        return dataCriteria.map(criteria => ({
            subject: criteria.label,
            value: performanceData[criteria.id].value,
            target: mdmMetrics[criteria.id].target,
        }));
    };

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            background: (theme) => `linear-gradient(to bottom right, ${alpha(theme.palette.primary.main, 0.02)}, ${alpha(theme.palette.primary.main, 0.06)})`,
                            borderRadius: 2,
                            height: '100%',
                        }}
                    >
                        <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500 }}>
                            Complexity of Data Criteria
                        </Typography>
                        <PerformanceMetricsList
                            metrics={dataCriteria}
                            selectedMetrics={selectedMetrics}
                            onMetricToggle={(metric) => {
                                if (selectedMetrics.includes(metric)) {
                                    if (selectedMetrics.length === 1) {
                                        setSelectedMetrics(dataCriteria.map(c => c.id));
                                    } else {
                                        setSelectedMetrics(selectedMetrics.filter(m => m !== metric));
                                    }
                                } else {
                                    setSelectedMetrics([...selectedMetrics, metric]);
                                }
                            }}
                            onMetricClick={handleMetricClick}
                            onSpotlightClick={(metric) => {
                                const criteria = dataCriteria.find(c => c.id === metric);
                                handleSpotlightOpen(criteria);
                            }}
                            metricsData={mdmMetrics}
                            performanceData={performanceData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1))',
                            borderRadius: 2,
                            minHeight: 500,
                        }}
                    >
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                            Performance Overview
                        </Typography>
                        <ResponsiveContainer width="100%" height={450}>
                            <RadarChart data={generateChartData()}>
                                <PolarGrid gridType="polygon" />
                                <PolarAngleAxis
                                    dataKey="subject"
                                    tick={{
                                        fill: '#666',
                                        fontSize: 12,
                                    }}
                                />
                                <PolarRadiusAxis
                                    angle={30}
                                    domain={[0, 70]}
                                    tick={{
                                        fill: '#666',
                                        fontSize: 12,
                                    }}
                                />
                                <Radar
                                    name="Performance"
                                    dataKey="value"
                                    stroke="#2563EB"
                                    fill="#2563EB"
                                    fillOpacity={0.5}
                                />
                                <Radar
                                    name="Target"
                                    dataKey="target"
                                    stroke="#82ca9d"
                                    fill="#82ca9d"
                                    fillOpacity={0.3}
                                />
                                <Legend />
                            </RadarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            background: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1))',
                            borderRadius: 2,
                        }}
                    >
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                            Trends Analysis
                        </Typography>
                        <TrendsChart
                            selectedMetrics={selectedMetrics}
                            metricsData={mdmMetrics}
                            dataCriteria={dataCriteria}
                            height={300}
                            showLegend
                        />
                    </Paper>
                </Grid>
            </Grid>

            {/* Details Modal */}
            <Modal
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                aria-labelledby="details-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: 600 },
                    maxHeight: '90vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    boxShadow: (theme) => `0 8px 32px ${alpha(theme.palette.primary.main, 0.18)}`,
                }}>
                    {/* Header Section */}
                    <Box sx={{
                        p: 3,
                        background: (theme) => `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.05)}, ${alpha(theme.palette.primary.main, 0.1)})`,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <Box>
                                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
                                    {selectedCriteria?.label}
                                </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    color="text.secondary" 
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 1,
                                        maxWidth: '90%'
                                    }}
                                >
                                    <InfoIcon fontSize="small" />
                                    {selectedCriteria?.description}
                                </Typography>
                            </Box>
                            <IconButton
                                onClick={() => setDetailsOpen(false)}
                                size="small"
                                sx={{
                                    bgcolor: 'background.paper',
                                    boxShadow: 1,
                                    '&:hover': { 
                                        bgcolor: 'background.default',
                                        transform: 'scale(1.1)'
                                    },
                                    transition: 'transform 0.2s ease'
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Content Section */}
                    <Box sx={{ p: 3 }}>
                        <Box sx={{
                            mb: 3,
                            p: 2.5,
                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05),
                            color: 'primary.main',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.5,
                        }}>
                            <AssessmentIcon />
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                This breakdown shows the most frequently documented sub-criteria within the {selectedCriteria?.label.toLowerCase()} category.
                            </Typography>
                        </Box>

                        {/* Sub-criteria List */}
                        <Box sx={{ mt: 2 }}>
                            {selectedCriteria?.subCriteria.map((sub, index) => (
                                <Box
                                    key={sub.name}
                                    sx={{
                                        mb: 2,
                                        p: 2.5,
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                        borderRadius: 2,
                                        border: '1px solid',
                                        borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateX(8px)',
                                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                                            boxShadow: (theme) => `0 4px 12px ${alpha(theme.palette.primary.main, 0.08)}`,
                                        },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle1" sx={{
                                                fontWeight: 500,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                mb: 0.5,
                                            }}>
                                                {sub.name}
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleSubCriteriaSpotlightOpen(selectedCriteria, sub)}
                                                    sx={{
                                                        ml: 1,
                                                        color: 'primary.main',
                                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                        '&:hover': {
                                                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
                                                            transform: 'scale(1.1)',
                                                        },
                                                        transition: 'all 0.2s ease'
                                                    }}
                                                >
                                                    <GroupsIcon fontSize="small" />
                                                </IconButton>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                {sub.description}
                                            </Typography>
                                            <Typography variant="caption" color="text.secondary" sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                gap: 0.5 
                                            }}>
                                                <InfoIcon sx={{ fontSize: 14 }} />
                                                Frequency of occurrence
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: 'right', ml: 2 }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: 'primary.main',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {sub.percentage}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: 1.5 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={sub.percentage}
                                            sx={{
                                                height: 6,
                                                borderRadius: 3,
                                                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                                '& .MuiLinearProgress-bar': {
                                                    borderRadius: 3,
                                                    background: (theme) => `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.8)})`,
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Modal>

            {/* Frequency Modal */}
            <Modal
                open={frequencyModalOpen}
                onClose={() => setFrequencyModalOpen(false)}
                aria-labelledby="frequency-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: 400 },
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    boxShadow: (theme) => `0 8px 32px ${alpha(theme.palette.primary.main, 0.18)}`,
                    p: 3,
                }}>
                    <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                            {selectedCriteria?.label} Frequencies
                        </Typography>
                        <IconButton
                            onClick={() => setFrequencyModalOpen(false)}
                            size="small"
                            sx={{
                                bgcolor: 'background.paper',
                                '&:hover': { 
                                    bgcolor: 'background.default',
                                    transform: 'scale(1.1)'
                                },
                                transition: 'transform 0.2s ease'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {selectedCriteria?.subCriteria.map((sub, index) => (
                        <Box
                            key={sub.name}
                            sx={{
                                mb: 2,
                                p: 2,
                                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    transform: 'translateX(4px)',
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                    {sub.name}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: 'primary.main',
                                        fontWeight: 600,
                                    }}
                                >
                                    {sub.percentage}%
                                </Typography>
                            </Box>
                            <LinearProgress
                                variant="determinate"
                                value={sub.percentage}
                                sx={{
                                    height: 4,
                                    borderRadius: 2,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                                    '& .MuiLinearProgress-bar': {
                                        background: (theme) => `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0.8)})`,
                                    }
                                }}
                            />
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                {sub.description}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Modal>

            {/* Provider Spotlight */}
            <ProviderSpotlight
                open={spotlightOpen}
                onClose={() => {
                    setSpotlightOpen(false);
                    setSpotlightCriteria(null);
                    setSpotlightSubCriteria(null);
                }}
                criteriaType={spotlightCriteria?.label}
                criteriaName={spotlightSubCriteria?.name}
                groupPerformance={spotlightCriteria ? performanceData[spotlightCriteria.id].value : null}
                groupTrend={spotlightCriteria ? performanceData[spotlightCriteria.id].trend : null}
            />
        </Box>
    );
};

export default DataComplexityView; 