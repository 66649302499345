export const mockProviders = [
    {
        id: 1,
        name: 'Chris Holmes',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 98,
            labs: 95,
            externalNotes: 92,
            imaging: 88,
            interpretation: 94,
            consults: 89,
            sdoh: 96,
        }
    },
    {
        id: 2,
        name: 'Dylan Luyten',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 94,
            labs: 92,
            externalNotes: 88,
            imaging: 90,
            interpretation: 91,
            consults: 87,
            sdoh: 93,
        }
    },
    {
        id: 3,
        name: 'Mark Radlauer',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 91,
            labs: 89,
            externalNotes: 87,
            imaging: 92,
            interpretation: 90,
            consults: 88,
            sdoh: 91,
        }
    },
    {
        id: 4,
        name: 'Thomas Church',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 87,
            labs: 88,
            externalNotes: 85,
            imaging: 89,
            interpretation: 86,
            consults: 84,
            sdoh: 88,
        }
    },
    {
        id: 5,
        name: 'Mark Hinton',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 93,
            labs: 91,
            externalNotes: 89,
            imaging: 92,
            interpretation: 90,
            consults: 88,
            sdoh: 92,
        }
    },
    {
        id: 6,
        name: 'Jerry Smedley',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 89,
            labs: 90,
            externalNotes: 86,
            imaging: 88,
            interpretation: 87,
            consults: 85,
            sdoh: 89,
        }
    },
    {
        id: 7,
        name: 'Jeremy Voros',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 95,
            labs: 93,
            externalNotes: 91,
            imaging: 94,
            interpretation: 92,
            consults: 90,
            sdoh: 94,
        }
    },
    {
        id: 8,
        name: 'Brent Levy',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 88,
            labs: 87,
            externalNotes: 84,
            imaging: 86,
            interpretation: 85,
            consults: 83,
            sdoh: 87,
        }
    },
    {
        id: 9,
        name: 'Mark Christensen',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 92,
            labs: 90,
            externalNotes: 88,
            imaging: 91,
            interpretation: 89,
            consults: 87,
            sdoh: 91,
        }
    },
    {
        id: 10,
        name: 'Ricky Dhaliwal',
        specialty: 'Emergency Physician',
        metrics: {
            historian: 90,
            labs: 89,
            externalNotes: 86,
            imaging: 88,
            interpretation: 87,
            consults: 85,
            sdoh: 89,
        }
    },
]; 