'use client'

import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import {
    ChartPieIcon
} from '@heroicons/react/20/solid'
import { BuildingOfficeIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { useMsal } from '@azure/msal-react'
import { protectedResources } from '../authConfig'

import { useAuth } from '../contexts/AuthProvider';

import UserManagementContent from '../pages/admin/UserManagementContent';
import OrganizationStructure from '../components/OrganizationStructure';
import PatientExperienceContent from './admin/PatientExperienceContent';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrganizationAdminDashboard() {
    const { state } = useLocation();
    const { actorInfo } = useAuth();
    const org_id = state?.org_id || actorInfo?.org_id; // allow for global admins to access any org
    const org_name = state?.org_name || ""
    const [isLoading, setIsLoading] = useState(true);
    const { instance, accounts } = useMsal();
    const [activeTab, setActiveTab] = useState('userManagement')
    const [aggOrgData, setAggOrgData] = useState(null);
    const [aggActorData, setAggActorData] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const navigate = useNavigate();

    const fetchAggOrgData = async () => {
        setIsLoading(true);
        if (accounts.length > 0) {
            const request = {
                scopes: protectedResources.apiFetchAggOrgData.scopes,
                account: accounts[0],
            };

            const tokenResponse = await instance.acquireTokenSilent(request);
            const token = tokenResponse.accessToken;

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ org_id: org_id }),
            };

            // Assuming protectedResources.apiFetchAggOrgData.endpoint is the base URL
            const endpoint = protectedResources.apiFetchAggOrgData.endpoint;

            if (org_id) {
                console.log(org_id);
            }

            const fetchResponse = await fetch(
                endpoint,
                requestOptions
            );
            const response = await fetchResponse.json();

            setAggOrgData(response.org_data);
            setAggActorData(response.actor_data);
            setOrgId(org_id);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAggOrgData();
    }, []);

    const tabs = [
        { name: 'Stats & Users', value: 'userManagement', icon: ChartPieIcon },
        { name: 'Patient Experience', value: 'patientExperience', icon: ClipboardDocumentListIcon },
        { name: 'Organization Structure', value: 'organizationStructure', icon: BuildingOfficeIcon },
    ]

    return (
        isLoading ? (
            <div className="flex flex-1 overflow-hidden min-h-0">
                <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
            </div>
        ) : (
            <div className="flex flex-1 overflow-hidden min-h-0">
                <aside
                    className={`flex-shrink-0 bg-white border-r border-gray-200 transition-all duration-300 ${isSidebarCollapsed ? 'w-16' : 'w-64'
                        }`}
                >
                    <div className="flex items-center px-4 h-16 border-b border-gray-200">
                        {!isSidebarCollapsed && (
                            <h1
                                className="text-lg font-semibold text-gray-900 transition-opacity duration-300"
                            >
                                {org_name || 'Organization Dashboard'}
                            </h1>
                        )}
                        <button
                            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                            className="ml-auto focus:outline-none"
                            aria-label={isSidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                        >
                            {isSidebarCollapsed ? (
                                <svg
                                    className="w-6 h-6 text-gray-700"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    {/* Icon for expand */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18M3 6h18M3 18h18" /> */}
                                </svg>
                            ) : (
                                <svg
                                    className="w-6 h-6 text-gray-700"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    {/* Icon for collapse */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /> */}
                                </svg>
                            )}
                        </button>
                    </div>
                    <nav
                        className={`overflow-y-auto ${isSidebarCollapsed ? 'px-2' : 'px-4'} py-6 h-full`}
                    >
                        <ul role="list" className="space-y-1">
                            {tabs.map((tab) => (
                                <li key={tab.value}>
                                    <button
                                        onClick={() => setActiveTab(tab.value)}
                                        className={classNames(
                                            activeTab === tab.value
                                                ? 'bg-gray-50 text-indigo-800'
                                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                            'group flex items-center w-full gap-x-3 rounded-md py-2 px-2 text-sm font-semibold leading-6',
                                            isSidebarCollapsed ? 'justify-center' : ''
                                        )}
                                        title={isSidebarCollapsed ? tab.name : ''}
                                    >
                                        <tab.icon
                                            className={classNames(
                                                activeTab === tab.value
                                                    ? 'text-indigo-800'
                                                    : 'text-gray-400 group-hover:text-indigo-800',
                                                'h-6 w-6 shrink-0'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {!isSidebarCollapsed && (
                                            <span className="transition-opacity duration-300">{tab.name}</span>
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {/* Back to Global Dashboard Button */}
                        <div className="mt-4">
                            <button
                                onClick={() => navigate('/global-admin-dashboard')}
                                className={classNames(
                                    'group flex items-center w-full gap-x-3 rounded-md py-2 px-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                    isSidebarCollapsed ? 'justify-center' : 'justify-start'
                                )}
                                title="Back to Global Dashboard"
                            >
                                <svg
                                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-800"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                >
                                    {/* Icon for 'Back' */}
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12h18M9 6l-6 6 6 6" />
                                </svg>
                                {!isSidebarCollapsed && <span className="text-left">Back to Global Dashboard</span>}
                            </button>
                        </div>
                    </nav>
                </aside>

                <main className="flex flex-grow justify-center transition-all duration-300">
                    {activeTab === 'userManagement' && <UserManagementContent orgStatistics={aggOrgData} actorStatistics={aggActorData} orgId={orgId} />}
                    {activeTab === 'organizationStructure' && <div className="h-full w-full"><OrganizationStructure orgId={orgId} /></div>}
                    {activeTab === 'patientExperience' && (
                        <PatientExperienceContent organizationData={aggOrgData} orgId={orgId} />
                    )}
                </main>
            </div>
        )
    );
}







