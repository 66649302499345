import React from 'react';
import {
    Box,
    List,
    ListItem,
    Typography,
    LinearProgress,
    IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import GroupsIcon from '@mui/icons-material/Groups';
import { getPerformanceColor } from '../../utils/facilityData';
import { alpha } from '@mui/material/styles';

const getProgressColor = (value, target) => {
    if (value >= target + 5) return 'primary.main';
    if (value >= target - 5) return 'primary.light';
    return 'error.main';
};

const PerformanceMetricsList = ({
    metrics,
    selectedMetrics,
    onMetricToggle,
    onMetricClick,
    onSpotlightClick,
    metricsData,
    performanceData,
}) => {
    return (
        <List sx={{ width: '100%' }}>
            {metrics.map((metric) => {
                const metricData = metricsData[metric.id];
                const performance = performanceData[metric.id];
                const isSelected = selectedMetrics.includes(metric.id);

                return (
                    <ListItem
                        key={metric.id}
                        sx={{
                            cursor: 'pointer',
                            borderRadius: 1,
                            mb: 1,
                            bgcolor: isSelected ? 'rgba(37, 99, 235, 0.04)' : 'transparent',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                                bgcolor: 'rgba(37, 99, 235, 0.08)',
                            },
                        }}
                        onClick={() => onMetricClick(metric.id)}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                                        {metric.label}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onSpotlightClick(metric.id);
                                        }}
                                        sx={{
                                            ml: 1,
                                            color: 'text.secondary',
                                            '&:hover': {
                                                bgcolor: alpha('#2563EB', 0.08),
                                                color: 'primary.main',
                                            },
                                        }}
                                    >
                                        <GroupsIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: getProgressColor(performance.value, metricData.target),
                                            fontWeight: 600,
                                        }}
                                    >
                                        {performance.value}%
                                    </Typography>
                                    {performance.trend === 'up' ? (
                                        <TrendingUpIcon
                                            sx={{
                                                fontSize: 16,
                                                color: 'primary.main',
                                            }}
                                        />
                                    ) : (
                                        <TrendingDownIcon
                                            sx={{
                                                fontSize: 16,
                                                color: 'error.main',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                            <LinearProgress
                                variant="determinate"
                                value={performance.value}
                                sx={{
                                    height: 4,
                                    borderRadius: 2,
                                    bgcolor: 'rgba(0, 0, 0, 0.04)',
                                    '& .MuiLinearProgress-bar': {
                                        bgcolor: getProgressColor(performance.value, metricData.target),
                                    },
                                }}
                            />
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'space-between',
                                mt: 0.5,
                            }}>
                                <Typography variant="caption" color="text.secondary">
                                    Target: {metricData.target}%
                                </Typography>
                                <Typography 
                                    variant="caption" 
                                    sx={{ 
                                        color: performance.trend === 'up' ? 'success.main' : 'error.main',
                                    }}
                                >
                                    {performance.trend === 'up' ? '+' : '-'}{performance.trendValue}%
                                </Typography>
                            </Box>
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default PerformanceMetricsList; 