import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Chip,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    LinearProgress,
    Collapse,
    Divider,
    Button,
} from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    RadarChart,
    Radar,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
} from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import DashboardFilters from './DashboardFilters';
import { getPerformanceColor, generateRealisticCompliance } from '../utils/facilityData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Switch from '@mui/material/Switch';

// Mock data for patient experience metrics
const patientExperienceMetrics = {
    'AIDET Framework': {
        acknowledge: { name: 'Acknowledged Patient', target: 95 },
        introduce: { name: 'Introduced Themselves', target: 98 },
        duration: { name: 'Provided Duration', target: 90 },
        explanation: { name: 'Provided Explanation', target: 92 },
        thankYou: { name: 'Thanked Patient', target: 95 },
    },
    'Patient Engagement': {
        openEndedQuestions: { name: 'Asked Open Ended Questions', target: 85 },
        patientQuestions: { name: 'Asked for Patient Questions', target: 88 },
        additionalHelp: { name: 'Asked How Else to Help', target: 82 },
        patientOpinion: { name: 'Asked Patient Opinion', target: 80 },
    },
    'Care Team Communication': {
        managedUp: { name: 'Managed Up Care Team', target: 85 },
        whiteboard: { name: 'Used Whiteboard', target: 75 },
        reflectiveListening: { name: 'Reflectively Listened', target: 90 },
    }
};

// Mock providers data
const mockProviders = [
    {
        id: 1,
        name: 'Dr. Sarah Johnson',
        specialty: 'Emergency Physician',
        division: 'Main ED',
        metrics: {
            aidetScore: 92,
            patientExperienceScore: 88,
            documentCount: 150,
            trend: 'up',
            detailedMetrics: {
                acknowledge: { current: 95, trend: [92, 93, 94, 95, 95, 95] },
                introduce: { current: 98, trend: [95, 96, 97, 98, 98, 98] },
                duration: { current: 88, trend: [85, 86, 87, 88, 88, 88] },
                explanation: { current: 94, trend: [90, 91, 92, 93, 94, 94] },
                thankYou: { current: 92, trend: [88, 89, 90, 91, 92, 92] },
                openEndedQuestions: { current: 85, trend: [80, 81, 82, 83, 84, 85] },
                patientQuestions: { current: 87, trend: [82, 83, 84, 85, 86, 87] },
                additionalHelp: { current: 86, trend: [81, 82, 83, 84, 85, 86] },
                managedUp: { current: 89, trend: [84, 85, 86, 87, 88, 89] },
                whiteboard: { current: 78, trend: [73, 74, 75, 76, 77, 78] },
                reflectiveListening: { current: 91, trend: [86, 87, 88, 89, 90, 91] },
            }
        }
    },
    {
        id: 2,
        name: 'Dr. Michael Chen',
        specialty: 'Emergency Physician',
        division: 'Pediatric ED',
        metrics: {
            aidetScore: 88,
            patientExperienceScore: 91,
            documentCount: 120,
            trend: 'up',
            detailedMetrics: {
                acknowledge: { current: 88, trend: [83, 84, 85, 86, 87, 88] },
                introduce: { current: 91, trend: [86, 87, 88, 89, 90, 91] },
                duration: { current: 85, trend: [80, 81, 82, 83, 84, 85] },
                explanation: { current: 89, trend: [84, 85, 86, 87, 88, 89] },
                thankYou: { current: 87, trend: [82, 83, 84, 85, 86, 87] },
                openEndedQuestions: { current: 82, trend: [77, 78, 79, 80, 81, 82] },
                patientQuestions: { current: 84, trend: [79, 80, 81, 82, 83, 84] },
                additionalHelp: { current: 83, trend: [78, 79, 80, 81, 82, 83] },
                managedUp: { current: 86, trend: [81, 82, 83, 84, 85, 86] },
                whiteboard: { current: 75, trend: [70, 71, 72, 73, 74, 75] },
                reflectiveListening: { current: 88, trend: [83, 84, 85, 86, 87, 88] },
            }
        }
    },
    {
        id: 3,
        name: 'Dr. Emily Rodriguez',
        specialty: 'Advanced Practice Provider',
        division: 'Main ED',
        metrics: {
            aidetScore: 85,
            patientExperienceScore: 84,
            documentCount: 180,
            trend: 'down',
            detailedMetrics: {
                acknowledge: { current: 84, trend: [86, 85, 85, 84, 84, 84] },
                introduce: { current: 86, trend: [88, 87, 87, 86, 86, 86] },
                duration: { current: 82, trend: [84, 83, 83, 82, 82, 82] },
                explanation: { current: 85, trend: [87, 86, 86, 85, 85, 85] },
                thankYou: { current: 83, trend: [85, 84, 84, 83, 83, 83] },
                openEndedQuestions: { current: 80, trend: [82, 81, 81, 80, 80, 80] },
                patientQuestions: { current: 81, trend: [83, 82, 82, 81, 81, 81] },
                additionalHelp: { current: 79, trend: [81, 80, 80, 79, 79, 79] },
                managedUp: { current: 82, trend: [84, 83, 83, 82, 82, 82] },
                whiteboard: { current: 72, trend: [74, 73, 73, 72, 72, 72] },
                reflectiveListening: { current: 84, trend: [86, 85, 85, 84, 84, 84] },
            }
        }
    },
    // Add more mock providers as needed
];

// Add this new component for the provider detail view
const ProviderDetailView = ({ provider }) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    // Add error handling for missing data
    if (!provider?.metrics?.detailedMetrics) {
        return (
            <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                <Typography color="error">
                    Detailed metrics not available for this provider
                </Typography>
            </Box>
        );
    }

    const generateTrendData = (metricTrend) => {
        if (!Array.isArray(metricTrend)) {
            return months.map(month => ({ month, value: 0 }));
        }
        return months.map((month, index) => ({
            month,
            value: metricTrend[index]
        }));
    };

    // Helper function to get readable metric name
    const getMetricName = (key) => {
        for (const category of Object.values(patientExperienceMetrics)) {
            if (category[key]) {
                return category[key].name;
            }
        }
        return key.split(/(?=[A-Z])/).join(' '); // Fallback to splitting camelCase
    };

    return (
        <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
            <Grid container spacing={3}>
                {/* Summary Section */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Performance Summary
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="subtitle2">Patients Seen</Typography>
                                <Typography variant="h4">{provider.metrics.documentCount}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="subtitle2">AIDET Score Trend</Typography>
                                <ResponsiveContainer width="100%" height={120}>
                                    <LineChart data={generateTrendData(provider.metrics.detailedMetrics.acknowledge.trend)}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="month"
                                            tick={{ fontSize: 12 }}
                                            tickFormatter={(_, index) => months[index]}
                                        />
                                        <YAxis
                                            domain={[0, 100]}
                                            tick={{ fontSize: 12 }}
                                            tickFormatter={(value) => `${value}%`}
                                        />
                                        <Tooltip
                                            formatter={(value) => [`${value}%`, 'AIDET Score']}
                                            labelFormatter={(_, data) => months[data[0].index]}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="value"
                                            stroke="#8884d8"
                                            strokeWidth={2}
                                            dot={{ r: 4 }}
                                            activeDot={{ r: 6 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Detailed Metrics */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Detailed Metrics
                    </Typography>
                    <Grid container spacing={2}>
                        {Object.entries(provider.metrics.detailedMetrics).map(([key, metric]) => (
                            <Grid item xs={12} md={6} lg={4} key={key}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="subtitle2">
                                        {getMetricName(key)}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: getPerformanceColor(metric.current) }}
                                        >
                                            {metric.current}%
                                        </Typography>
                                        <ResponsiveContainer width={100} height={50}>
                                            <LineChart data={generateTrendData(metric.trend)}>
                                                <Line
                                                    type="monotone"
                                                    dataKey="value"
                                                    stroke={getPerformanceColor(metric.current)}
                                                    dot={false}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

// Add this mock data for intervention rules
const interventionRules = [
    {
        id: 1,
        name: 'Low AIDET Score Alert',
        description: 'Pop-up reminder when AIDET score drops below threshold',
        threshold: 80,
        metric: 'aidetScore',
        active: true,
        triggerCount: 15,
        lastTriggered: '2024-02-15',
        affectedProviders: 3,
    },
    {
        id: 2,
        name: 'Patient Engagement Warning',
        description: 'Alert for consistently low patient engagement metrics',
        threshold: 75,
        metric: 'patientExperienceScore',
        active: false,
        triggerCount: 8,
        lastTriggered: '2024-02-10',
        affectedProviders: 2,
    },
];

const PatientExperienceDashboard = () => {
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedFacility, setSelectedFacility] = useState('all');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [selectedView, setSelectedView] = useState('overview');
    const [selectedProvider, setSelectedProvider] = useState(null);

    // Add this new state to manage selected metrics for all categories
    const [selectedMetrics, setSelectedMetrics] = useState(
        Object.entries(patientExperienceMetrics).reduce((acc, [category, metrics]) => ({
            ...acc,
            [category]: Object.keys(metrics)
        }), {})
    );

    // Add this handler function
    const handleMetricToggle = (category, newMetrics) => {
        if (newMetrics.length) { // Ensure at least one metric is selected
            setSelectedMetrics(prev => ({
                ...prev,
                [category]: newMetrics
            }));
        }
    };

    // Generate trend data for charts
    const generateTrendData = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        return months.map(month => ({
            month,
            aidet: generateRealisticCompliance(),
            satisfaction: generateRealisticCompliance(),
        }));
    };

    // Generate radar data for AIDET metrics
    const generateRadarData = (provider) => {
        return Object.entries(patientExperienceMetrics['AIDET Framework']).map(([key, metric]) => ({
            metric: metric.name,
            value: generateRealisticCompliance(),
            target: metric.target,
        }));
    };

    const generateMetricTrendData = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
        return months.map(month => {
            const dataPoint = { month };
            // Generate data for each metric
            Object.entries(patientExperienceMetrics).forEach(([category, metrics]) => {
                Object.entries(metrics).forEach(([key, metric]) => {
                    dataPoint[key] = generateRealisticCompliance();
                });
            });
            return dataPoint;
        });
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Patient Experience Dashboard
            </Typography>

            <DashboardFilters
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
            />

            <Tabs
                value={selectedView}
                onChange={(e, newValue) => setSelectedView(newValue)}
                sx={{ mb: 3 }}
            >
                <Tab value="overview" label="Overview" />
                <Tab value="providers" label="Provider Performance" />
                <Tab value="trends" label="Trends Analysis" />
                <Tab value="actions" label="Action Center" />
            </Tabs>

            {selectedView === 'overview' && (
                <Grid container spacing={3}>
                    {/* Overall Metrics Cards */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Overall AIDET Score
                            </Typography>
                            <Typography variant="h3" sx={{ color: getPerformanceColor(88) }}>
                                88%
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Based on {mockProviders.reduce((acc, p) => acc + p.metrics.documentCount, 0)} encounters
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Cleo Patient Satisfaction
                            </Typography>
                            <Typography variant="h3" sx={{ color: getPerformanceColor(90) }}>
                                90%
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Last 30 days trend: +2.5%
                            </Typography>
                        </Card>
                    </Grid>

                    {/* AIDET Framework Performance */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 2, height: '400px' }}>
                            <Typography variant="h6" gutterBottom>
                                AIDET Framework Performance
                            </Typography>
                            <ResponsiveContainer width="100%" height="90%">
                                <RadarChart data={generateRadarData()}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="metric" />
                                    <PolarRadiusAxis domain={[0, 100]} />
                                    <Radar
                                        name="Performance"
                                        dataKey="value"
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                        fillOpacity={0.6}
                                    />
                                    <Radar
                                        name="Target"
                                        dataKey="target"
                                        stroke="#82ca9d"
                                        fill="#82ca9d"
                                        fillOpacity={0.6}
                                    />
                                    <Legend />
                                </RadarChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Trend Analysis */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 2, height: '400px' }}>
                            <Typography variant="h6" gutterBottom>
                                Performance Trends
                            </Typography>
                            <ResponsiveContainer width="100%" height="90%">
                                <LineChart data={generateTrendData()}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis domain={[0, 100]} />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="aidet"
                                        stroke="#8884d8"
                                        name="AIDET Score"
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="satisfaction"
                                        stroke="#82ca9d"
                                        name="Patient Satisfaction"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>
                </Grid>
            )}

            {selectedView === 'providers' && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <List>
                                {mockProviders.map((provider) => (
                                    <React.Fragment key={provider.id}>
                                        <ListItem
                                            sx={{
                                                borderBottom: '1px solid',
                                                borderColor: 'divider',
                                                '&:hover': {
                                                    backgroundColor: 'action.hover',
                                                },
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={provider.name}
                                                secondary={`${provider.specialty} - ${provider.division}`}
                                            />
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <Box>
                                                    <Typography variant="subtitle2">AIDET Score</Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ color: getPerformanceColor(provider.metrics.aidetScore) }}
                                                    >
                                                        {provider.metrics.aidetScore}%
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="subtitle2">Patient Experience</Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ color: getPerformanceColor(provider.metrics.patientExperienceScore) }}
                                                    >
                                                        {provider.metrics.patientExperienceScore}%
                                                    </Typography>
                                                </Box>
                                                {provider.metrics.trend === 'up' ? (
                                                    <TrendingUpIcon color="success" />
                                                ) : (
                                                    <TrendingDownIcon color="error" />
                                                )}
                                                <IconButton
                                                    onClick={() => setSelectedProvider(
                                                        selectedProvider === provider.id ? null : provider.id
                                                    )}
                                                >
                                                    {selectedProvider === provider.id ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </ListItem>
                                        <Collapse in={selectedProvider === provider.id}>
                                            <ProviderDetailView provider={provider} />
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                </Grid>
            )}

            {selectedView === 'trends' && (
                <Grid container spacing={3}>
                    {Object.entries(patientExperienceMetrics).map(([category, metrics]) => (
                        <Grid item xs={12} key={category}>
                            <Card sx={{ p: 2 }}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="h6" gutterBottom>
                                        {category}
                                    </Typography>
                                    <ToggleButtonGroup
                                        value={selectedMetrics[category]}
                                        onChange={(event, newMetrics) => handleMetricToggle(category, newMetrics)}
                                        aria-label="metric selection"
                                        size="small"
                                        sx={{ flexWrap: 'wrap' }}
                                        multiple
                                    >
                                        {Object.entries(metrics).map(([key, metric]) => (
                                            <ToggleButton
                                                key={key}
                                                value={key}
                                                sx={{
                                                    mr: 1,
                                                    mb: 1,
                                                    '&.Mui-selected': {
                                                        backgroundColor: 'primary.main',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'primary.dark',
                                                        }
                                                    }
                                                }}
                                            >
                                                {metric.name}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Box>

                                <Box sx={{ height: 400, width: '100%' }}>
                                    <ResponsiveContainer>
                                        <LineChart data={generateMetricTrendData()}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="month"
                                                tick={{ fontSize: 12 }}
                                            />
                                            <YAxis
                                                domain={[0, 100]}
                                                tick={{ fontSize: 12 }}
                                                tickFormatter={(value) => `${value}%`}
                                            />
                                            <Tooltip
                                                formatter={(value, name) => {
                                                    const metricName = Object.values(metrics).find(
                                                        m => Object.keys(metrics).find(
                                                            k => k === name
                                                        )
                                                    )?.name || name;
                                                    return [`${value}%`, metricName];
                                                }}
                                            />
                                            <Legend
                                                formatter={(value) =>
                                                    metrics[value]?.name || value
                                                }
                                            />
                                            {selectedMetrics[category].map((metricKey, index) => (
                                                <Line
                                                    key={metricKey}
                                                    type="monotone"
                                                    dataKey={metricKey}
                                                    name={metricKey}
                                                    stroke={`hsl(${(index * 137.508) % 360}, 70%, 50%)`}
                                                    strokeWidth={2}
                                                    dot={{ r: 4 }}
                                                    activeDot={{ r: 6 }}
                                                />
                                            ))}
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Box>

                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Target Ranges:
                                    </Typography>
                                    {Object.entries(metrics)
                                        .filter(([key]) => selectedMetrics[category].includes(key))
                                        .map(([key, metric]) => (
                                            <Typography
                                                key={key}
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{ ml: 2 }}
                                            >
                                                • {metric.name}: {metric.target}%
                                            </Typography>
                                        ))
                                    }
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}

            {selectedView === 'actions' && (
                <Grid container spacing={3}>
                    {/* Summary Cards */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                <NotificationsIcon color="primary" />
                                <Typography variant="h6">Active Alerts</Typography>
                            </Box>
                            <Typography variant="h3" color="primary">
                                {interventionRules.filter(rule => rule.active).length}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Out of {interventionRules.length} total rules
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                <PersonIcon color="warning" />
                                <Typography variant="h6">Providers Affected</Typography>
                            </Box>
                            <Typography variant="h3" color="warning.main">
                                {interventionRules.reduce((sum, rule) => sum + (rule.active ? rule.affectedProviders : 0), 0)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Currently receiving alerts
                            </Typography>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                <PlayArrowIcon color="success" />
                                <Typography variant="h6">Total Triggers</Typography>
                            </Box>
                            <Typography variant="h3" color="success.main">
                                {interventionRules.reduce((sum, rule) => sum + rule.triggerCount, 0)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                In the last 30 days
                            </Typography>
                        </Card>
                    </Grid>

                    {/* Intervention Rules Table */}
                    <Grid item xs={12}>
                        <Card>
                            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6">Intervention Rules</Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<SettingsIcon />}
                                    onClick={() => {/* Add rule configuration handler */ }}
                                >
                                    Configure New Rule
                                </Button>
                            </Box>
                            <List>
                                {interventionRules.map((rule) => (
                                    <ListItem
                                        key={rule.id}
                                        sx={{
                                            borderBottom: '1px solid',
                                            borderColor: 'divider',
                                            flexDirection: { xs: 'column', md: 'row' },
                                            alignItems: { xs: 'flex-start', md: 'center' },
                                            gap: 2,
                                            py: 2,
                                        }}
                                    >
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {rule.name}
                                                <Chip
                                                    size="small"
                                                    label={rule.active ? 'Active' : 'Paused'}
                                                    color={rule.active ? 'success' : 'default'}
                                                />
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {rule.description}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Threshold: {rule.threshold}% | Affected Providers: {rule.affectedProviders}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Box sx={{ textAlign: 'right' }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Last Triggered
                                                </Typography>
                                                <Typography variant="body1">
                                                    {rule.lastTriggered}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: 'right' }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Trigger Count
                                                </Typography>
                                                <Typography variant="body1">
                                                    {rule.triggerCount}
                                                </Typography>
                                            </Box>
                                            <Switch
                                                checked={rule.active}
                                                onChange={() => {/* Add toggle handler */ }}
                                                color="primary"
                                            />
                                            <IconButton
                                                onClick={() => {/* Add edit handler */ }}
                                                size="small"
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default PatientExperienceDashboard; 