import { callApiWithToken } from './apiUtils';
import { protectedResources } from '../authConfig';

export const templatesApi = {
    getTemplates: async (instance, accounts) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiGetTemplates.endpoint,
            'POST',
            null,
            protectedResources.apiGetTemplates.scopes
        );
    },

    upsertTemplate: async (instance, accounts, templateData) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiUpsertTemplate.endpoint,
            'POST',
            templateData,
            protectedResources.apiUpsertTemplate.scopes
        );
    },

    deleteTemplate: async (instance, accounts, templateId) => {
        return callApiWithToken(
            instance,
            accounts,
            protectedResources.apiDeleteTemplate.endpoint,
            'POST',
            { template_id: templateId },
            protectedResources.apiDeleteTemplate.scopes
        );
    }
}; 