import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
} from '@mui/material';
import DashboardFilters from '../DashboardFilters';
import { EMDistributionCard, KeyMetricsCard, EMTrendsCard } from './em-components';
import MDMBreakdown from '../MDMBreakdown';
import ProviderSpotlight from '../ProviderSpotlight';

const EMView = () => {
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedFacility, setSelectedFacility] = useState('all');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [spotlightOpen, setSpotlightOpen] = useState(false);
    const [selectedMDMCategory, setSelectedMDMCategory] = useState(null);
    const [selectedMDMLevel, setSelectedMDMLevel] = useState(null);
    const [groupPerformance, setGroupPerformance] = useState(null);
    const [groupTrend, setGroupTrend] = useState(null);
    const [mdmBreakdown, setMdmBreakdown] = useState({});

    const handleMDMSpotlightOpen = (category, level, performance, trend) => {
        setSelectedMDMCategory(category);
        setSelectedMDMLevel(level);
        setGroupPerformance(performance);
        setGroupTrend(trend);
        setSpotlightOpen(true);
    };

    useEffect(() => {
        // Fetch mdmBreakdown data
        fetchMdmBreakdown();
    }, []);

    const fetchMdmBreakdown = async () => {
        try {
            const response = await fetch('/api/mdmBreakdown');
            const data = await response.json();
            setMdmBreakdown(data);
        } catch (error) {
            console.error('Error fetching mdmBreakdown:', error);
        }
    };

    return (
        <>
            <DashboardFilters
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <EMDistributionCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <KeyMetricsCard />
                </Grid>
                <Grid item xs={12}>
                    <MDMBreakdown onSpotlightOpen={handleMDMSpotlightOpen} />
                </Grid>
                <Grid item xs={12}>
                    <EMTrendsCard />
                </Grid>
            </Grid>

            <ProviderSpotlight
                open={spotlightOpen}
                onClose={() => {
                    setSpotlightOpen(false);
                    setSelectedMDMCategory(null);
                    setSelectedMDMLevel(null);
                    setGroupPerformance(null);
                    setGroupTrend(null);
                }}
                criteriaType={selectedMDMCategory}
                criteriaName={selectedMDMLevel}
                groupPerformance={groupPerformance}
                groupTrend={groupTrend}
            />
        </>
    );
};

export default EMView; 