import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    Avatar,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    ListItemAvatar,
    ListItemText,
    InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { alpha } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RemoveIcon from '@mui/icons-material/Remove';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import { mockProviders } from '../config/mockProviders';

const interventionMessages = {
    // Main criteria level messages
    historian: "Consider documenting additional historians if they were present during the patient encounter.",
    labs: "Consider documenting which labs you ordered if you haven't done so already.",
    imaging: "Consider documenting any imaging studies you ordered in your documentation.",
    interpretation: "Consider documenting your independent interpretation of studies before specialist reading.",
    consults: "Consider documenting the details of any specialist consultations you requested.",
    sdoh: "Consider screening and documenting social determinants of health during the encounter.",
    externalNotes: "Consider documenting your review of any external records in your note.",

    // Sub-criteria level - Historians
    "EMS": "Consider documenting the details from your EMS handoff conversation",
    "Mother": "Consider documenting any information provided by the patient's mother",
    "Brother": "Consider documenting any information provided by the patient's brother",
    "Daughter": "Consider documenting any information provided by the patient's daughter",
    "Caregiver": "Consider documenting any information provided by the patient's caregiver",

    // Sub-criteria level - Labs
    "CBC": "Consider documenting your review of the CBC results",
    "BMP": "Consider documenting any electrolyte abnormalities you identified",
    "Troponin": "Consider documenting the trending of cardiac markers",
    "Lactate": "Consider documenting your interpretation of the lactate level",
    "Blood Culture": "Consider documenting blood culture orders and results",

    // Sub-criteria level - Imaging
    "Chest X-Ray": "Consider documenting your interpretation of the chest x-ray",
    "CT Head": "Consider documenting your review of the head CT",
    "CT Abd/Pelvis": "Consider documenting your review of the abdominal CT",
    "Ultrasound": "Consider documenting your review of the ultrasound study",

    // Sub-criteria level - Interpretations
    "EKG": "Consider documenting your independent interpretation of the EKG",
    "X-Ray": "Consider documenting your preliminary read of imaging studies",
    "CT": "Consider documenting your initial impression of CT findings",

    // Sub-criteria level - Consults
    "Cardiology": "Consider documenting the details of your cardiology consultation",
    "Neurology": "Consider documenting the key points from neurology's recommendations",
    "Surgery": "Consider documenting the surgical consultation findings",
    "Psychiatry": "Consider documenting the psychiatric consultation recommendations",

    // Sub-criteria level - SDOH
    "Housing": "Consider documenting any housing insecurity identified",
    "Transportation": "Consider documenting any transportation barriers discussed",
    "Food Security": "Consider documenting any food insecurity concerns raised",
    "Support System": "Consider documenting the patient's support system status",

    // MDM Complexity messages
    minimalComplexity: "Consider documenting elements that support minimal complexity medical decision making.",
    lowComplexity: "Consider documenting elements that support low complexity medical decision making.",
    moderateComplexity: "Consider documenting elements that support moderate complexity medical decision making.",
    highComplexity: "Consider documenting elements that support high complexity medical decision making.",

    // Problems Addressed messages
    selfLimited: "Consider documenting self-limited problems if they were addressed during the encounter.",
    lowSeverity: "Consider documenting low severity problems if they were addressed during the encounter.",
    moderateSeverity: "Consider documenting moderate severity problems if they were addressed during the encounter.",
    highSeverity: "Consider documenting high severity problems if they were addressed during the encounter.",
};

const criteriaToMetricKey = {
    // High level MDM categories
    'problems': 'Complexity of Problems Addressed',
    'data': 'Complexity of Data',
    'risk': 'Risk of Complications',
    
    // Problems sub-criteria
    'problems_minimal': 'Minimal Complexity Problems',
    'problems_low': 'Low Complexity Problems',
    'problems_moderate': 'Moderate Complexity Problems',
    'problems_high': 'High Complexity Problems',
    
    // Data sub-criteria
    'data_minimal': 'Minimal Data Review',
    'data_low': 'Limited Data Review',
    'data_moderate': 'Moderate Data Review',
    'data_extensive': 'Extensive Data Review',
    
    // Risk sub-criteria
    'risk_minimal': 'Minimal Risk',
    'risk_low': 'Low Risk',
    'risk_moderate': 'Moderate Risk',
    'risk_high': 'High Risk',

    // Data Criteria Types
    'Historians': 'historian',
    'Labs Ordered': 'labs',
    'Imaging Ordered': 'imaging',
    'Interpretations': 'interpretation',
    'Consults': 'consults',

    // New Risk Categories
    'Admission Decisions': 'admissionDecisions',
    'Drug Therapy Monitoring': 'drugTherapy',
    'High-Risk Procedures': 'highRiskProcedures',
    'Patient Risk Factors': 'patientRiskFactors',
    'Procedure Risk Factors': 'procedureRiskFactors',
    'Surgical Decision Making': 'surgicalDecisions',

    // Admission Subcriteria
    'directAdmit': 'Direct Admit',
    'icu': 'ICU',
    'observation': 'Observation',
    'transfer': 'Transfer',

    // Drug Therapy Subcriteria
    'anticoagulation': 'Anticoagulation',
    'chemotherapy': 'Chemotherapy',
    'immunosuppression': 'Immunosuppression',
    'antipsychotics': 'Antipsychotics',

    // High-Risk Procedures Subcriteria
    'arterialCath': 'Arterial Catheterization',
    'endotrachealTube': 'Endotracheal Tube',
    'biPAP': 'BiPAP',
    'centralLine': 'Central Line',

    // Patient Risk Factors Subcriteria
    'aids': 'AIDS',
    'alcoholism': 'Alcoholism',
    'chronicRenalFailure': 'Chronic Renal Failure',
    'cirrhosis': 'Cirrhosis',

    // Procedure Risk Factors Subcriteria
    'coagulopathy': 'Coagulopathy',
    'anatomicComplexity': 'Anatomic Complexity',
    'multipleComorbidities': 'Multiple Comorbidities',
    'emergentTiming': 'Emergent Timing',

    // Surgical Decision Making Subcriteria
    'elective': 'Elective Surgery',
    'emergency': 'Emergency Surgery',
    'complicationRisk': 'Complication Risk'
};

const ProviderSpotlight = ({
    open,
    onClose,
    criteriaType,
    criteriaName,
    subCriteriaName = null,
    groupPerformance = null,
    groupTrend = null
}) => {
    const [interventionOpen, setInterventionOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [providers, setProviders] = useState({
        topPerformers: [],
        lowPerformers: [],
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [adjustedProviders, setAdjustedProviders] = useState([]);

    const getInterventionMessage = () => {
        if (subCriteriaName && interventionMessages[subCriteriaName]) {
            return interventionMessages[subCriteriaName];
        }
        const metricKey = criteriaToMetricKey[criteriaType] || 'historian';
        return interventionMessages[metricKey] || "Consider reviewing documentation practices for this metric";
    };

    const handleInterventionToggle = (providerId, currentStatus) => {
        const updateProviderList = (list) =>
            list.map(provider =>
                provider.id === providerId
                    ? { ...provider, hasIntervention: !currentStatus }
                    : provider
            );

        setProviders({
            topPerformers: updateProviderList(providers.topPerformers),
            lowPerformers: updateProviderList(providers.lowPerformers),
        });
    };

    useEffect(() => {
        if (!groupPerformance) return;

        // Create a copy of mockProviders with metrics adjusted around the group average
        const newAdjustedProviders = mockProviders.map(provider => {
            // Generate a random variation between -15% and +15% of the group average
            const variation = (Math.random() * 30 - 15);
            const adjustedMetric = Math.min(100, Math.max(0, groupPerformance + variation));
            
            // Get the correct metric key based on the criteria type
            const metricKey = criteriaToMetricKey[criteriaType];
            
            // If no metric key found, use a default
            if (!metricKey) {
                console.warn(`No metric key found for criteria type: ${criteriaType}`);
                return provider;
            }

            return {
                ...provider,
                metrics: {
                    ...provider.metrics,
                    [metricKey]: Math.round(adjustedMetric),
                },
                trend: Math.random() > 0.5 ? 'up' : 'down',
                trendValue: (Math.random() * 3).toFixed(1)
            };
        });

        // Sort providers by their adjusted performance
        const sortedProviders = newAdjustedProviders.sort((a, b) => {
            const metricKey = criteriaToMetricKey[criteriaType];
            if (!metricKey) return 0;
            return b.metrics[metricKey] - a.metrics[metricKey];
        });

        setAdjustedProviders(sortedProviders);
    }, [criteriaType, groupPerformance]);

    useEffect(() => {
        if (searchQuery) {
            const filtered = adjustedProviders.filter(provider =>
                provider.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setProviders({
                topPerformers: filtered,
                lowPerformers: [],
            });
        } else {
            setProviders({
                topPerformers: adjustedProviders.slice(0, 3),
                lowPerformers: adjustedProviders.slice(-3),
            });
        }
    }, [searchQuery, adjustedProviders]);

    // Use the provided group metrics instead of calculating them
    const getGroupMetrics = () => ({
        average: groupPerformance || 0,
        trend: groupTrend || 'up',
    });

    const PerformerList = ({ performers, type }) => (
        <Box sx={{ mt: 2 }}>
            {performers.length > 0 && (
                <Typography
                    variant="h6"
                    sx={{
                        color: type === 'top' ? 'primary.main' : type === 'low' ? 'error.main' : 'text.primary',
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {type === 'top' ? 'Top Performers' : type === 'low' ? 'Needs Improvement' : 'Other Providers'}
                </Typography>
            )}
            <List>
                {performers.map((provider) => {
                    const metricKey = criteriaToMetricKey[criteriaType] || 'historian';
                    const score = provider.metrics[metricKey];

                    return (
                        <ListItem
                            key={provider.id}
                            sx={{
                                bgcolor: (theme) => type === 'middle' ? 'background.paper' : alpha(
                                    type === 'top' ? theme.palette.primary.main : theme.palette.error.main,
                                    0.05
                                ),
                                borderRadius: 2,
                                mb: 1,
                                boxShadow: 1,
                                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 2,
                                    bgcolor: (theme) => type === 'middle' ? 'background.paper' : alpha(
                                        type === 'top' ? theme.palette.primary.main : theme.palette.error.main,
                                        0.08
                                    ),
                                }
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: type === 'middle' ? 'grey.400' : type === 'top' ? 'primary.main' : 'error.main',
                                    mr: 2,
                                }}
                            >
                                <PersonIcon />
                            </Avatar>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1">
                                    {provider.name}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: type === 'top' ? 'primary.main' : 'error.main',
                                            fontWeight: 500
                                        }}
                                    >
                                        {score}%
                                    </Typography>
                                    {/* Determine trend based on provider's trend property */}
                                    {provider.trend === 'up' ? (
                                        <TrendingUpIcon fontSize="small" color={type === 'top' ? 'primary' : 'error'} />
                                    ) : (
                                        <TrendingDownIcon fontSize="small" color={type === 'top' ? 'primary' : 'error'} />
                                    )}
                                </Box>
                            </Box>
                            <IconButton
                                onClick={() => {
                                    setSelectedProvider(provider);
                                    if (!provider.hasIntervention) {
                                        setInterventionOpen(true);
                                    } else {
                                        handleInterventionToggle(provider.id, true);
                                    }
                                }}
                                sx={{
                                    color: type === 'top' ? 'primary.main' : 'error.main',
                                    bgcolor: (theme) => alpha(
                                        type === 'top' ? theme.palette.primary.main : theme.palette.error.main,
                                        0.1
                                    ),
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(
                                            type === 'top' ? theme.palette.primary.main : theme.palette.error.main,
                                            0.2
                                        ),
                                    },
                                }}
                            >
                                {provider.hasIntervention ? (
                                    <NotificationsActiveIcon />
                                ) : (
                                    <NotificationsOffIcon />
                                )}
                            </IconButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="provider-spotlight-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}>
                    <IconButton
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography variant="h5" component="h2" gutterBottom>
                        Provider Spotlight: {subCriteriaName || criteriaName}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 3,
                            p: 2,
                            borderRadius: 2,
                            background: (theme) => `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.05)}, ${alpha(theme.palette.primary.main, 0.1)})`,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 0.5 }}
                            >
                                Group Performance
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: 'primary.main',
                                        fontWeight: 600,
                                        lineHeight: 1,
                                    }}
                                >
                                    {Math.round(getGroupMetrics().average)}%
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: getGroupMetrics().trend === 'up' ? 'primary.main' : 'error.main',
                                        typography: 'body2',
                                    }}
                                >
                                    {getGroupMetrics().trend === 'up' ? (
                                        <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                                    ) : (
                                        <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />
                                    )}
                                    Group Average
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        Performance analysis for {subCriteriaName ? `${criteriaName} - ${subCriteriaName}` : criteriaName}
                    </Typography>

                    <Box sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            placeholder="Search providers..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: 2,
                                    bgcolor: 'background.paper',
                                }
                            }}
                        />
                    </Box>

                    <PerformerList performers={providers.topPerformers} type={searchQuery ? 'middle' : 'top'} />
                    {!searchQuery && providers.lowPerformers?.length > 0 && (
                        <>
                            <Divider sx={{ my: 3 }} />
                            <PerformerList performers={providers.lowPerformers} type="low" />
                        </>
                    )}
                </Box>
            </Modal>

            {/* Intervention Confirmation Dialog */}
            {selectedProvider && (
                <Dialog
                    open={interventionOpen}
                    onClose={() => {
                        setInterventionOpen(false);
                        setSelectedProvider(null);
                    }}
                    maxWidth="sm"
                    fullWidth
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                        '& .MuiDialog-paper': {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            m: 0,
                            borderRadius: 3,
                            boxShadow: (theme) => `0 12px 24px ${alpha(theme.palette.common.black, 0.12)}`,
                            background: '#fff',
                            maxWidth: 480,
                        }
                    }}
                >
                    <Box sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    sx={{
                                        width: 48,
                                        height: 48,
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                                    }}
                                >
                                    <NotificationsActiveIcon 
                                        sx={{ 
                                            fontSize: 24,
                                            color: 'primary.main'
                                        }} 
                                    />
                                </Avatar>
                                <Box>
                                    <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary', mb: 0.5 }}>
                                        Enable Intervention
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {selectedProvider.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <IconButton
                                onClick={() => {
                                    setInterventionOpen(false);
                                    setSelectedProvider(null);
                                }}
                                sx={{
                                    color: 'text.secondary',
                                    '&:hover': { bgcolor: (theme) => alpha(theme.palette.text.secondary, 0.04) }
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <Box sx={{ 
                            mb: 3,
                            p: 2,
                            borderRadius: 2,
                            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                            border: '1px solid',
                            borderColor: (theme) => alpha(theme.palette.primary.main, 0.12),
                        }}>
                            <Typography 
                                variant="subtitle2" 
                                sx={{ 
                                    color: 'primary.main',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 1
                                }}
                            >
                                <InfoIcon sx={{ fontSize: 20 }} />
                                Alert Message
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                {getInterventionMessage()}
                            </Typography>
                        </Box>

                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3, lineHeight: 1.6 }}>
                            This alert will be triggered when {selectedProvider.name} sees a patient where documenting this criteia may be justified but the information is not found in their Cleo chart.
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1.5 }}>
                            <Button
                                onClick={() => {
                                    setInterventionOpen(false);
                                    setSelectedProvider(null);
                                }}
                                variant="text"
                                sx={{
                                    color: 'text.primary',
                                    fontWeight: 500,
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.text.primary, 0.04),
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleInterventionToggle(selectedProvider.id, false);
                                    setInterventionOpen(false);
                                    setSelectedProvider(null);
                                }}
                                sx={{
                                    bgcolor: 'primary.main',
                                    color: 'primary.contrastText',
                                    fontWeight: 600,
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }
                                }}
                                startIcon={<NotificationsActiveIcon sx={{ fontSize: 20 }} />}
                            >
                                Enable Alert
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default ProviderSpotlight; 