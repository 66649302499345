import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    IconButton,
    Tooltip,
    Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    LineChart,
    Line,
} from 'recharts';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardFilters from './DashboardFilters';
import { getPerformanceColor, generateRealisticCompliance } from '../utils/facilityData';

// Mock data organized by categories
const measureCategories = {
    'Antibiotic Stewardship': [
        {
            id: '066',
            name: 'Appropriate Testing for Pharyngitis',
            target: 85,
            description: 'The percentage of episodes for patients 3 years and older with a diagnosis of pharyngitis that resulted in an antibiotic dispensing event and a group A streptococcus (strep) test'
        },
        {
            id: '093',
            name: 'AOE: Systemic Antimicrobial Therapy Avoidance',
            target: 90,
            description: 'Percentage of patients aged 2 years and older with a diagnosis of AOE who were not prescribed systemic antimicrobial therapy'
        },
        { id: '116', name: 'Avoidance of Antibiotic for Acute Bronchitis', target: 88 },
        { id: '331', name: 'Adult Sinusitis: Antibiotic Overuse', target: 92 },
        { id: '332', name: 'Adult Sinusitis: Appropriate Antibiotic Choice', target: 95 },
    ],
    'Emergency Care': [
        { id: '187', name: 'Stroke: Thrombolytic Therapy', target: 85 },
        { id: '254', name: 'Pregnancy Ultrasound for Abdominal Pain', target: 90 },
        { id: '415', name: 'CT Usage for Adult Minor Head Trauma', target: 88 },
        { id: '416', name: 'CT Usage for Pediatric Minor Head Trauma', target: 92 },
    ],
    'Screening & Prevention': [
        { id: '107', name: 'MDD: Suicide Risk Assessment', target: 95 },
        { id: '317', name: 'Blood Pressure Screening and Follow-Up', target: 90 },
    ],
};

// Mock facilities data
const facilities = [
    'All Facilities',
    'Main ED',
    'Pediatric ED',
    'Urgent Care West',
    'Urgent Care East',
];

// Mock time periods
const timePeriods = [
    { value: 'last30', label: 'Last 30 Days' },
    { value: 'last90', label: 'Last 90 Days' },
    { value: 'lastYear', label: 'Last Year' },
    { value: 'ytd', label: 'Year to Date' },
];

// Mock providers data
const providers = [
    { id: 1, name: 'Dr. Smith', facility: 'Main ED', npi: '1234567890' },
    { id: 2, name: 'Dr. Johnson', facility: 'Main ED', npi: '2345678901' },
    { id: 3, name: 'Dr. Williams', facility: 'Pediatric ED', npi: '3456789012' },
    { id: 4, name: 'Dr. Brown', facility: 'Pediatric ED', npi: '4567890123' },
    { id: 5, name: 'Dr. Davis', facility: 'Urgent Care West', npi: '5678901234' },
    { id: 6, name: 'Dr. Miller', facility: 'Urgent Care East', npi: '6789012345' },
];

// Add provider performance data generator
const generateProviderPerformanceData = (measure) => {
    return providers.map(provider => ({
        ...provider,
        performance: Math.floor(Math.random() * (100 - 60) + 60),
    })).sort((a, b) => b.performance - a.performance);
};

// Mock performance data generator
const generatePerformanceData = (measures, facilities) => {
    return facilities.map(facility => ({
        facility,
        ...measures.reduce((acc, measure) => ({
            ...acc,
            [measure.id]: Math.floor(Math.random() * (100 - 70) + 70),
        }), {}),
    }));
};

const MIPSDashboard = () => {
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedFacility, setSelectedFacility] = useState('all');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('last30');
    const [selectedProvider, setSelectedProvider] = useState('all');
    const [selectedCategory, setSelectedCategory] = useState('Antibiotic Stewardship');
    const [showProviderDetails, setShowProviderDetails] = useState(null);

    // Generate mock data for the selected category
    const performanceData = generatePerformanceData(
        measureCategories[selectedCategory],
        selectedFacility === 'all' ? facilities.slice(1) : [selectedFacility]
    );

    const getProviderPerformanceClass = (performance, target) => {
        if (performance >= target + 5) return 'bg-green-100 border-l-4 border-green-500';
        if (performance <= target - 10) return 'bg-red-100 border-l-4 border-red-500';
        return '';
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                MIPS Quality Measures Dashboard
            </Typography>

            {/* Enhanced Filters */}
            <DashboardFilters
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                selectedTimePeriod={selectedTimePeriod}
                setSelectedTimePeriod={setSelectedTimePeriod}
                additionalFilters={
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Provider</InputLabel>
                            <Select
                                value={selectedProvider}
                                onChange={(e) => setSelectedProvider(e.target.value)}
                                label="Provider"
                            >
                                <MenuItem value="all">All Providers</MenuItem>
                                {providers
                                    .filter(provider =>
                                        selectedFacility === 'all' ||
                                        provider.facility === selectedFacility
                                    )
                                    .map((provider) => (
                                        <MenuItem key={provider.id} value={provider.id}>
                                            {provider.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
            />

            {/* Category Tabs */}
            <Tabs
                value={selectedCategory}
                onChange={(e, newValue) => setSelectedCategory(newValue)}
                sx={{ mb: 3 }}
            >
                {Object.keys(measureCategories).map((category) => (
                    <Tab key={category} label={category} value={category} />
                ))}
            </Tabs>

            {/* Updated Performance Cards with Provider Details */}
            <Grid container spacing={3}>
                {measureCategories[selectedCategory].map((measure) => {
                    const performance = Math.floor(Math.random() * (100 - 70) + 70);
                    const providerPerformance = generateProviderPerformanceData(measure);
                    const topPerformers = providerPerformance.slice(0, 3);
                    const bottomPerformers = providerPerformance.slice(-3).reverse();

                    return (
                        <Grid item xs={12} md={6} lg={4} key={measure.id}>
                            <Card sx={{ p: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium', flex: 1 }}>
                                        Measure #{measure.id}
                                        <Tooltip
                                            title={measure.description}
                                            placement="top"
                                            sx={{ maxWidth: 300 }}
                                        >
                                            <IconButton size="small" sx={{ ml: 1 }}>
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Box>

                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                                    {measure.name}
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2, mb: 2 }}>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color: getPerformanceColor(performance)
                                        }}
                                    >
                                        {performance}%
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Target: {measure.target}%
                                    </Typography>
                                </Box>

                                {/* Provider Performance Details */}
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={() => setShowProviderDetails(showProviderDetails === measure.id ? null : measure.id)}
                                        endIcon={showProviderDetails === measure.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        sx={{ mb: 1 }}
                                    >
                                        {showProviderDetails === measure.id ? 'Hide' : 'Show'} Provider Details
                                    </Button>

                                    {showProviderDetails === measure.id && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                                Top Performers
                                            </Typography>
                                            {topPerformers.map((provider) => (
                                                <Box
                                                    key={provider.id}
                                                    className={getProviderPerformanceClass(provider.performance, measure.target)}
                                                    sx={{ p: 1, mb: 1, borderRadius: 1 }}
                                                >
                                                    <Typography variant="body2">
                                                        {provider.name} ({provider.facility}) - {provider.performance}%
                                                    </Typography>
                                                </Box>
                                            ))}

                                            <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                                                Needs Improvement
                                            </Typography>
                                            {bottomPerformers.map((provider) => (
                                                <Box
                                                    key={provider.id}
                                                    className={getProviderPerformanceClass(provider.performance, measure.target)}
                                                    sx={{ p: 1, mb: 1, borderRadius: 1 }}
                                                >
                                                    <Typography variant="body2">
                                                        {provider.name} ({provider.facility}) - {provider.performance}%
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>

            {/* Trend Analysis */}
            <Card sx={{ mt: 3, p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Trend Analysis - {selectedCategory}
                </Typography>
                <LineChart
                    width={1000}
                    height={400}
                    data={generateTrendData(measureCategories[selectedCategory])}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    {measureCategories[selectedCategory].map((measure) => (
                        <Line
                            key={measure.id}
                            type="monotone"
                            dataKey={measure.id}
                            name={`Measure #${measure.id}`}
                            stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                        />
                    ))}
                </LineChart>
            </Card>
        </Box>
    );
};

// Helper function to generate trend data
const generateTrendData = (measures) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
    return months.map(month => ({
        month,
        ...measures.reduce((acc, measure) => ({
            ...acc,
            [measure.id]: Math.floor(Math.random() * (100 - 70) + 70),
        }), {}),
    }));
};

export default MIPSDashboard; 