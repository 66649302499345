export const organizationStructure = {
    regions: [
        {
            id: 'west',
            name: 'West Region',
            facilities: [
                {
                    id: 'dallas-ed',
                    name: 'Dallas ED',
                },
                {
                    id: 'plano-ed',
                    name: 'Plano ED',
                }
            ]
        },
        {
            id: 'northeast',
            name: 'Northeast Region',
            facilities: [
                {
                    id: 'providence-ed',
                    name: 'Providence ED',
                },
                {
                    id: 'newhaven-ed',
                    name: 'New Haven ED',
                }
            ]
        }
    ]
};

export const COMPLIANCE_COLORS = {
    success: {
        mui: 'success.main',
        hex: '#4caf50',  // Green
        threshold: 80
    },
    warning: {
        mui: 'warning.main',
        hex: '#ff9800',  // Orange
        threshold: 70
    },
    error: {
        mui: 'error.main',
        hex: '#f44336'   // Red
    }
};

export const getPerformanceColor = (value, returnHex = false) => {
    if (value >= COMPLIANCE_COLORS.success.threshold) {
        return returnHex ? COMPLIANCE_COLORS.success.hex : COMPLIANCE_COLORS.success.mui;
    }
    if (value >= COMPLIANCE_COLORS.warning.threshold) {
        return returnHex ? COMPLIANCE_COLORS.warning.hex : COMPLIANCE_COLORS.warning.mui;
    }
    return returnHex ? COMPLIANCE_COLORS.error.hex : COMPLIANCE_COLORS.error.mui;
};

export const generateRealisticCompliance = () => {
    return Math.floor(Math.random() * (90 - 50) + 50);
}; 